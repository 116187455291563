import { useRef, useState, useContext } from "react";
import IconPlus from "../../../assets/svg/icon_plus.svg";
import { Toaster, toast } from 'sonner'
import { Context } from "../../../App";
import { saveWordOnBlackListWords } from "../../functions/FunctionsFilter";

export function EventMobile(props) {
    const {openPageIndexFilter, saveFilterChanged} = props;
    const { blacklistWords, setBlacklistWords, statusMode } = useContext(Context);


    const inputkeywordMarket = useRef(null);
    const [keywordsAdded, setKeywordsAdded] = useState(blacklistWords);

    function eraseTextInput() {
        if (inputkeywordMarket.current) {
            inputkeywordMarket.current.value = "";
        }
    }

    function saveKeyword() {
        let resultReturn = { "status": false };
        if (inputkeywordMarket.current) {
            let valueWrited = inputkeywordMarket.current.value.trim();


            if(keywordsAdded.length >= 100){
                toast.error('Limite de 100 palavras-chave atingido! Remova algumas para continuar.')
            }
            else if (valueWrited.length == 0) {
                toast.warning('Campo vazio! Digite pelo menos um caractere para continuar.')

            }
            else if (valueWrited.length > 100) {
                toast.error('Você extrapolou o limite de 100 caracteres! Apague ou reduza o texto para continuar.')
            }
            else if (keywordsAdded.includes(valueWrited) === true) {
                toast.info('Ops! Essa palavra-chave já foi adicionada. Escolha uma diferente.')
            }
            else {
                setKeywordsAdded([...keywordsAdded, valueWrited]);
                resultReturn["status"] = true;
            }
        }

        return resultReturn;
    }

    function startFunctionsButtonAdd() {
        let hasSaveKeyword = saveKeyword();
        if (hasSaveKeyword["status"] === true) {
            eraseTextInput();
        }
    }

    const saveAndApplyFilter = () => {
        saveWordOnBlackListWords(keywordsAdded, setBlacklistWords);
        saveFilterChanged({blacklistWordsSurebet: keywordsAdded});
    }
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            startFunctionsButtonAdd();
        }
    }

    const deleteKeywod = (keyword) => {
        setKeywordsAdded(keywordsAdded.filter(item => item !== keyword));
    }


    return (
        <>

            <Toaster closeButton />
            <div className={`filter-box-header border-bottom p-1 py-3 d-flex justify-content-start align-items-center ${statusMode ? "filter-bg-dark filter-border-color filter-text-gray" : ""}`} onClick={()=>{openPageIndexFilter();}}>
                <div className="ms-3 me-2" style={{ width: "min-content", color: "rgb(13,110,253)", cursor: "pointer" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" fill="currentColor" className="bi bi-arrow-left-square-fill filter-svg-back-page" viewBox="0 0 16 16">
                        <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z">
                        </path>
                    </svg>
                </div>
                <div className="filter-box-options">
                    <p className="p-0 m-0 fw-semibold text-center fs-5">Excluir eventos</p>
                </div>
            </div>

            <div className="d-flex mx-2 my-2">
                <div className="    w-100">
                    <input
                        ref={inputkeywordMarket}
                        type="text"
                        className="form-control filter-input-keyword-surebet-mobile"
                        placeholder="Insira aqui as palavras-chave para filtrar..."
                        onKeyDown={handleKeyDown}
                    />
                </div>

                <div className="ms-2">
                    <button type="submit" className="btn btn-danger mb-3 filter-button-set-keyword-surebet-mobile" onClick={() => { startFunctionsButtonAdd(); }}>

                        <img className="img-fluid" src={IconPlus} alt="" />
                    </button>
                </div>
            </div>

            <div id="allCheckBox" className="fs-6 w-100" style={{ height: "352px", overflow: "overlay" }}>
                <div className="d-flex filter-div-keywords-saved">
                    {
                        keywordsAdded.map((item, index) => {



                            return (
                                <div className="bg-primary filter-min-content text-light m-1 py-1 px-2 rounded-pill" key={index}>


                                    <div className="filter-white-space-btn-remove-keyword">
                                        <div className="filter-container-event-btn-close" onClick={() => { deleteKeywod(item); }}>

                                            <div className="filter-event-btn-close">
                                            </div>
                                        </div>
                                    </div>

                                    <span className="text-break">
                                        {item}
                                    </span>
                                </div>
                            );
                        })
                    }
                </div>

            </div>

            <div className={`d-flex justify-content-end align-item-center border-top ${statusMode ? "filter-bg-dark filter-border-color filter-text-gray" : ""}`}>
                <button className={`btn btn-primary mt-2 me-3 fs-6 ${statusMode ? "" : ""}`} onClick={() => {
                    saveAndApplyFilter();
                    openPageIndexFilter();
                }}>Aplicar e Filtrar</button>
            </div>

        </>
    );
}   