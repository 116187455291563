import "../assets/css/ConfigPage.css"; // Certifique-se de que o caminho está correto e que o arquivo CSS está configurado adequadamente
import React, { useEffect, useState } from "react";

import { format, differenceInDays, addDays } from "date-fns";
import "../assets/css/ConfigPage.css";
import { Form } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import {
  faSignOutAlt,
  faUser,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Importe o módulo Axios para realizar as requisições HTTP
import axios from "axios";
import { linkWhatsapp } from "../global/GlobalVariables";
import { Modal } from "react-bootstrap";

import { loadMercadoPago } from "@mercadopago/sdk-js";
import { Payment } from "@mercadopago/sdk-react";
import { initMercadoPago } from "@mercadopago/sdk-react";

import StatusUpdate from "./StatusUpdate";

initMercadoPago("APP_USR-01e92b7d-7a5c-4a66-97a9-1f94dad8025a", {
  // Add your public key credential
  locale: "pt-BR",
});

await loadMercadoPago();

const ConfigPage = (props) => {
  const {
    statusMode,
    setStatusMode,
    handleLogout,
    setIsLoggedIn,
    isLoggedIn,
    showLogoutButton,
    notificationVolume,
    setNotificationVolume,
    userData,
    user,
    valueMin,
    valueMax,
    betHome,
    sports,
    fullData,
    setFullData,
    timeEvent,
    itemsTrash,
    setItemsTrash,
    controllerLocalStorage,
    setValueMin,
    setValueMax,
    setBetHome,
    quantityBetHomeEnabled,
    setQuantityBetHomeEnabled,
    setSports,
    quantitySportsEnabled,
    setQuantitySportsEnabled,
    setTimeEvent,

    setPopupWindow,
    popupWindow,
    setPopupWindowBet,
    popuwindowsBet,
    setIdinitialization,
    setAmountinitialization,
    Idinitialization,
    onLogout,
    setUserData,
  } = props;

  // Supondo que user.data_pagamento é uma string no formato ISO8601
  const dataPagamento = new Date(user.data_pagamento);
  const [userDatas, setUsersData] = useState(user);
  // Formate a data no formato 'dd/mm/aaaa'
  const dataFormatada = format(dataPagamento, "dd/MM/yyyy");
  // Aqui você pode adicionar estados e funções conforme necessário
  const [show, setShow] = useState(false);
  const [showV, setShowV] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [dataExpiracao, setDataExpiracao] = useState("");
  const [phone, setPhone] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showPhoneConfirmation, setShowPhoneConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [activeTab, setActiveTab] = useState("status");
  // Estado para controlar o logout automático
  const [logoutAutomatico, setLogoutAutomatico] = useState(false);
  const [diasRestantes, setdiasRestantes] = useState(0);

  //Variaveis do PageDe COmpra

  const [showModal, setShowModal] = useState(false);
  const [statusPayment, setStatusPayment] = useState(false);
  const [UserID, setUserID] = useState(null);
  const [IDinitializations, setInitializations] = useState("");

  const [Priceinitialization, setPriceIdinitialization] = useState(1);

  const [DateMsg, setDateMsg] = useState({});
  //FIM VARIAVEIS PAGECOMPRA

  //FUNÇÕES PAGEDECOMPRA

  const handlePayment = async (planNames, price) => {
    console.log("planNames", planNames);
    let planName = String(planNames);
    // Dividir o texto em duas variáveis usando o espaço como separador
    const partes = planName.split(" ");
    console.log("partes", partes);
    // Primeira variável (tipo)
    const tipo = partes[0].trim(); // Remove espaços em branco no início e no final
    console.log("tipo", tipo);
    // Segunda variável (periodicidade)
    const periodicidade = partes[1].trim();
    console.log("periodicidade", tipo);
    let pricecustom = 0;

    if (tipo == "Light") {
      pricecustom = periodicidade.includes("Anual")
        ? 999
        : periodicidade.includes("Trimestral")
        ? 269
        : 99.9;
    } else if (tipo.includes("Ao Vivo")) {
      pricecustom = periodicidade.includes("Anual")
        ? 2987
        : periodicidade.includes("Trimestral")
        ? 809
        : 299;
    } else if (tipo.includes("Profissional")) {
      pricecustom = periodicidade.includes("Anual")
        ? 4979
        : periodicidade.includes("Trimestral")
        ? 1349
        : 499.0;
    } else if (tipo.includes("Pro")) {
      pricecustom = periodicidade.includes("Anual")
        ? 2987
        : periodicidade.includes("Trimestral")
        ? 809
        : 299;
    }
    
    const preferenceData = {
      title: `Arbritagem Bet ${tipo} ${periodicidade} `,
      description: `Sistema de Arbritagem Bet ${tipo} ${periodicidade} para arbritagem esportivas`,
      price: pricecustom,
      user: userData.email,
    };
    
    localStorage.setItem('n46307526', JSON.stringify(preferenceData.title.trim()));
    localStorage.setItem('d37274784660p526', JSON.stringify(preferenceData.description.trim()));
    
    setPriceIdinitialization(preferenceData.price);
    handleSetMsg(preferenceData);
    window.localStorage.setItem(
      "preferenceData",
      JSON.stringify(preferenceData)
    );
    
    try {
      const response = await axios.post("https://rd.vps.webdock.cloud:9123/carts", {
        ...preferenceData,
      });

      if (response.data.erro) {
        throw new Error(response.data.erro);
      } else {
        setAmountinitialization(pricecustom);
        window.localStorage.setItem(
          "Amountinitialization",
          JSON.stringify(pricecustom)
        );
        setIdinitialization(response.data.preferenceID);
        window.localStorage.setItem(
          "Idinitialization",
          JSON.stringify(response.data.preferenceID)
        );
        const dataresponse = response.data;
        
        await openPopUpPayament(dataresponse.preferenceID, pricecustom);
      }
    } catch (error) {
      console.log({ error });
    }
  };



  useEffect(()=>{
  
  
      loadUsers();
  
      setTimeout(()=>{loadUsers();}, 3000);
  
      const intervalCheckLogin = setInterval(()=>{
        loadUsers();
      }, 60000)
      return () => clearInterval(intervalCheckLogin);
  }, [])
  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      
      if (userDatas.status_da_conta == "Inativo") {
        const userdatasnew = {
          sessionID: "",
          id: "",
          nome: "",
          email: "",
          senha: "",
          plano: "",
          tipo_de_plano: "",
          status_da_conta: "",
          username: "",
          phone: "",
          cuponuser: null,
          data_cadastro: "",
          data_pagamento: null,
          data_expiracao: null,
        };

        handleChangePlan(userDatas.id, "Inativo");
        setIsLoggedIn(false);
        setUserData(null);
        setUsersData(null);
        window.localStorage.setItem("userData", JSON.stringify(userdatasnew));
        onLogout();
        return;
      }
    }, 1000);

    return () => clearInterval(fetchDataInterval);
  }, [userDatas]);


  const handleSetMsg = (DateMsg) => {
    setDateMsg(DateMsg);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const initialization = {
    amount: Priceinitialization,
    preferenceId: Idinitialization,
  };

  const customization = {
    paymentMethods: {
      ticket: "all",
      bankTransfer: "all",
      creditCard: "all",
      debitCard: "all",
      mercadoPago: "all",
    },
  };

  const onSubmit = async ({ selectedPaymentMethod, formData }) => {
    try {
      const IDsPreference = Idinitialization;

      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9123/process_payment",
        {
          ...formData,
        }
      );

      if (response.data.erro) {
        throw new Error(response.data.erro);
      } else {
        const { status, status_detail, id } = response.data;

        if (id != null) {
          const response = await axios.post(
            "https://rd.vps.webdock.cloud:9123/cadastrar_payment",
            {
              user: userData.email,
              idsIniciar: id,
            }
          );
          if (response.data.erro) {
            throw new Error(response.data.erro);
          } else {            
            // Chame a função fetchData para realizar a solicitação Axios

            fetchData();
          }
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const onError = async (error) => {
    // callback chamado para todos os casos de erro do Brick
    console.log(error);
  };
  const onReady = async () => {
    /*
      Callback chamado quando o Brick estiver pronto.
      Aqui você pode ocultar loadings do seu site, por exemplo.
    */
  };

  const fetchData = async (e) => {
    try {
      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9123/get_dabase_userid",
        {
          user: userData.email,
        }
      );

      if (response.data.erro) {
        throw new Error(response.data.erro);
      } else {
        const preferenceID = response.data.preferenceID;

        // Atualize o objeto initializations com o preferenceID

        handleIdSuccess(preferenceID);

        // Defina o statusPayment como true para mostrar o StatusScreen
        setStatusPayment(true);
        fechtStatus(preferenceID);
      }
    } catch (error) {
      console.error("Erro na solicitação Axios:", error);
    }
  };

  const handleIdSuccess = (UserID) => {
    setUserID(UserID);
  };

  const fechtStatus = async (UserID) => {
    try {
      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9123/get_payment",
        {
          id: UserID,
          datepayment: DateMsg,
        }
      );

      if (response.data.erro) {
        throw new Error(response.data.erro);
      }
    } catch (error) {
      console.error("Erro na solicitação Axios:", error);
    }
  };

  //FIM FUNÇÕES PAGEDO COMPRA

  //pop up
  async function openPopUpPayament(value, value2) {
    const url = `/payament/${value}/${value2}/${userData.email}`;
    const windowName = "popupWindowAll"; // Nome da janela pop-up

    // Verifique se a janela pop-up já existe
    if (window[windowName] && !window[windowName].closed) {
      window[windowName].location.href = url;
      return;
    } else {
      const windowFeatures = "width=800,height=750";
      window[windowName] = window.open(url, windowName, windowFeatures);
      return;
    }
  }
  const handleUpdatePassword = () => {
    onUpdatePassword(userDatas.id, newPassword);
    showPasswordChangeConfirmation(); // Mostrar mensagem de confirmação de senha
    handleClose();
  };
  const loadUsers = () => {
    axios
      .post("https://rd.vps.webdock.cloud:9512/login", {
        email: userDatas.email,
        senha: userDatas.senha,
      })
      .then((response) => {
        setUsersData(response.data);
      });
  };
  const handleUpdatePhone = () => {
    onUpdatePhone(userDatas.id, phone);
    showPhoneChangeConfirmation(); // Mostrar mensagem de confirmação de telefone
    handleClose();
  };

  const handleDeleteAccount = () => {
    if (window.confirm("Tem certeza de que deseja excluir sua conta?")) {
      onDeleteAccount(userDatas.id);
    }
  };
  const showPasswordChangeConfirmation = () => {
    setConfirmationMessage("Senha alterada com sucesso!");
    setShowPasswordConfirmation(true);
  };

  const showPhoneChangeConfirmation = () => {
    setConfirmationMessage("Telefone alterado com sucesso!");
    setShowPhoneConfirmation(true);
  };

  const onUpdatePassword = (userId, newPassword) => {
    // Faça uma requisição PUT para atualizar a senha no servidor
    axios
      .put(`"https://rd.vps.webdock.cloud:9512/users/${userId}/settings/password`, {
        senha: newPassword,
      })
      .then((response) => {
        if (response.status === 200) {
          // A senha foi atualizada com sucesso
          console.log("Senha atualizada com sucesso!");
          // Você pode adicionar aqui qualquer ação adicional, como mostrar uma mensagem de sucesso.
        } else {
          // Lidar com erros ou exibir mensagens de erro, se necessário
          console.error("Erro ao atualizar senha:", response.data.message);
        }
      })
      .catch((error) => {
        // Lidar com erros de rede ou exceções, se necessário
        console.error("Erro ao atualizar senha:", error);
      });
  };

  // Função para atualizar o telefone do usuário
  const onUpdatePhone = (userId, newPhone) => {
    // Faça uma requisição PUT para atualizar o telefone no servidor
    axios
      .put(`"https://rd.vps.webdock.cloud:9512/users/${userId}/settings/phone`, {
        phone: newPhone,
      })
      .then((response) => {
        if (response.status === 200) {
          // O telefone foi atualizado com sucesso
          console.log("Telefone atualizado com sucesso!");
          // Você pode adicionar aqui qualquer ação adicional, como mostrar uma mensagem de sucesso.
        } else {
          // Lidar com erros ou exibir mensagens de erro, se necessário
          console.error("Erro ao atualizar telefone:", response.data.message);
        }
      })
      .catch((error) => {
        // Lidar com erros de rede ou exceções, se necessário
        console.error("Erro ao atualizar telefone:", error);
      });
  };

  // Função para excluir a conta do usuário
  const onDeleteAccount = (userId) => {
    // Faça uma requisição DELETE para excluir a conta no servidor
    axios
      .delete(`"https://rd.vps.webdock.cloud:9512/users/${userId}`)
      .then((response) => {
        if (response.status === 200) {
          // A conta foi excluída com sucesso
          console.log("Conta excluída com sucesso!");
          // Você pode adicionar aqui qualquer ação adicional, como mostrar uma mensagem de sucesso.
        } else {
          // Lidar com erros ou exibir mensagens de erro, se necessário
          console.error("Erro ao excluir conta:", response.data.message);
        }
      })
      .catch((error) => {
        // Lidar com erros de rede ou exceções, se necessário
        console.error("Erro ao excluir conta:", error);
      });
  };
  const navigate = useNavigate();
  const handleVoltarClick = () => {
    console.log(" Efetuando Logout e enviando dados para o Servidor....[]");
    sessionLogout();
  };
  // Função para enviar os dados para o servidor
  const sessionLogout = async () => {
    try {
      // Envia uma solicitação POST para a rota de logout no servidor
      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9512/api/logout",
        {
          // Coloque o ID do usuário que deseja fazer logout
          // Aqui, supomos que você tenha a variável userData que contém o ID do usuário
          userID: userData.id,
        }
      );

      // Trate a resposta do servidor, se necessário
      // Por exemplo, você pode verificar a resposta para saber se o logout foi bem-sucedido
      if (response.status === 200) {
        console.log("Logout bem-sucedido");
        window.localStorage.setItem("login", JSON.stringify(false));

        setIsLoggedIn(false);

        navigate("/");
      } else {
        console.log("Logout falhou");
      }
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
    }
  };

  
  // Função para mudar a aba ativa
  const handleSetActiveTab = (tab) => {
    setActiveTab(tab);
  };

  // Função para extrair a duração do plano com base na descrição
  const extrairDuracaoDoPlano = (descricaoPlano) => {
    if (descricaoPlano.toLowerCase().includes("mensal")) {
      return 30; // Se a descrição incluir "mensal", assume uma duração de 30 dias
    } else if (descricaoPlano.toLowerCase().includes("trimestral")) {
      return 90; // Se a descrição incluir "trimestral", assume uma duração de 90 dias
    } else if (descricaoPlano.toLowerCase().includes("anual")) {
      return 365; // Se a descrição incluir "anual", assume uma duração de 365 dias
    } else {
      return 0; // Se não encontrar uma correspondência, assume 0 dias (plano indefinido)
    }
  };

  // Obtém a duração com base na descrição do plano do usuário
  const duracaoPlano = extrairDuracaoDoPlano(userDatas.tipo_de_plano);
  const dataExpiracaos = new Date(userDatas.data_expiracao);
  // Obtém a data atual
  const validarCategoria = (categoria) => {
    // Implementar a lógica de validação aqui
    // Por exemplo, verificar se a categoria contém palavras-chave específicas
    // e ajustá-la para se adequar ao padrão desejado, se necessário

    const opcoesPlanos = {
      mensallight: "Arbritagem Bet Light Mensal",
      mensalpro: "Arbritagem Bet Pro Mensal",
      mensalaovivo: "Arbritagem Bet Ao vivo Mensal",
      mensalprofissional: "Arbritagem Bet Profissional Mensal",
      trimestrallight: "Arbritagem Bet Light Trimestral",
      trimestralpro: "Arbritagem Bet Pro Trimestral",
      trimestralaovivo: "Arbritagem Bet Ao vivo Trimestral",
      trimestralprofissional: "Arbritagem Bet Profissional Trimestral",
      anuallight: "Arbritagem Bet Light Anual",
      anualpro: "Arbritagem Bet Pro Anual",
      anualaovivo: "Arbritagem Bet Ao vivo Anual",
      anualprofissional: "Arbritagem Bet Profissional Anual",
    };
    return opcoesPlanos[categoria] || "Inativo";
  };
  const loadData = async (url, setState) => {
    try {
      const response = await axios.get(url);
      setState(response.data);
    } catch (error) {
      console.error("Erro ao carregar dados:", error);
    }
  };
  const handleChangePlan = (userId, categoria) => {
    // Verificação e ajuste da categoria antes de enviar
    let categoriaValidada = validarCategoria(categoria);

    // Axios POST request
    axios
      .post("https://rd.vps.webdock.cloud:9512/alterplano", {
        userid: userId,
        newplans: categoriaValidada,
      })
      .then((response) => {
        loadData("https://rd.vps.webdock.cloud:9512/users", setUsersData); // Recarregar a lista de usuários após a atualização
      })
      .catch((error) => {
        console.error("Erro ao criar o cupom:", error);
      });
  };
  // Efeito para verificar se o usuário deve ser deslogado automaticamente
  useEffect(() => {
    if (userDatas.data_expiracao != null) {
      console.log("Entrou aqui precisando...");
      const dataExpiracao = addDays(new Date(userDatas.data_expiracao), 0);
      setDataExpiracao(dataExpiracao);
      const dataAtual = new Date();

      // Calcula os dias restantes com base na data de expiração e na data atual
      const diasRestantes = differenceInDays(dataExpiracao, dataAtual) + 1;
      setdiasRestantes(diasRestantes);
      console.log("Dias Restantes", diasRestantes);
      if (diasRestantes <= 0 || userDatas.status_da_conta == "Inativo") {
        handleChangePlan(userDatas.id, "Inativo");
        setIsLoggedIn(false);
        setUsersData(null);
        handleVoltarClick();
        return;
      }
    } else if (userDatas.data_expiracao == null) {
      console.log("Entrou aqui sem precisar...");
      // Calcula a data de expiração do plano
      const dataExpiracao = addDays(dataPagamento, duracaoPlano);
      setDataExpiracao(dataExpiracao);
      const dataAtual = new Date();

      // Calcula os dias restantes com base na data de expiração e na data atual
      const diasRestantes = differenceInDays(dataExpiracao, dataAtual) + 1;
      setdiasRestantes(diasRestantes);
      console.log("Dias Restantes", diasRestantes);
      if (diasRestantes <= 0 || userDatas.status_da_conta == "Inativo") {
        handleChangePlan(userDatas.id, "Inativo");
        setIsLoggedIn(false);
        setUsersData(null);
        handleVoltarClick();
        return;
      }
    }
    loadUsers();
  }, [diasRestantes]);

  return (
    <div className="config-wrapper">
      <div className="sidebar">
        <div className="titlesidebar">PAINEL DE USUARIO</div>
        <div className="bodysidebar-config">
          <div
            className="sidebar-item"
            onClick={() => handleSetActiveTab("status")}
          >
            Status do Plano
          </div>
          <div
            className="sidebar-item"
            onClick={() => handleSetActiveTab("dados")}
          >
            Dados do usuário
          </div>
          <div
            className="sidebar-item"
            onClick={() => handleSetActiveTab("downloads")}
          >
            Downloads
          </div>
          <div
            className="sidebar-item"
            onClick={() => handleSetActiveTab("suporte")}
          >
            Suporte
          </div>
          <div className="sidebar-item sair" onClick={handleVoltarClick}>
            Sair <FontAwesomeIcon icon={faSignOutAlt} />
          </div>
        </div>
      </div>
      <div className="main-content">
        {activeTab === "status" && (
          <div className="status-card body-carduser">
            <div className="status-header">
              <h3 className={` ${statusMode ? "text-light" : "textDark"}`}>
                Status do Plano
              </h3>
            </div>
            <div className="status-info">
              <div className="dadosconta">
                <div className="statusconta">
                  Plano {userDatas.status_da_conta} [
                  {userDatas.status_da_conta == "Ativo" ? (
                    <div class="spuuner-div spinner-grow text-success"></div>
                  ) : userDatas.status_da_conta == "FourHours" ? (
                    <div class="spuuner-div spinner-grow text-success"></div>
                  ) : (
                    <div class="spuuner-div spinner-grow text-danger"></div>
                  )}
                  ]
                </div>
                <div className="planoconta">
                  <p className={` ${statusMode ? "text-light" : "textDark"}`}>
                    Plano Atual:{" "}
                    <b>
                      {userDatas.tipo_de_plano.replace("Arbritagem Bet ", "")}
                    </b>
                  </p>
                </div>
                <div className="diasconta">
                  <p className={` ${statusMode ? "text-light" : "textDark"}`}>
                    Dias Restantes: {diasRestantes > 0 ? diasRestantes : 0}{" "}
                    {/* Evita números negativos */}
                  </p>
                  <p className={` ${statusMode ? "text-light" : "textDark"}`}>
                    Data Expiração:{" "}
                    {dataExpiracao
                      ? format(dataExpiracao, "dd/MM/yyyy")
                      : "N/A"}
                  </p>
                  <p className={` ${statusMode ? "text-light" : "textDark"}`}>
                    Data Pagamento:{" "}
                    {dataPagamento
                      ? format(dataPagamento, "dd/MM/yyyy")
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div className="status-actions">
              <button
                className="btn-renovar"
                onClick={() => {
                  handlePayment(
                    userDatas.tipo_de_plano.replace("Arbritagem Bet ", "")
                  );
                }}
              >
                Renovar plano
              </button>

              <NavLink to="/renovacao">
                <button className="btn-mudar">Mudar de plano</button>
              </NavLink>
            </div>
          </div>
        )}
        {activeTab === "dados" && (
          <div className="user-data-card body-carduser">
            <div className="status-header">
              <h3 className={` ${statusMode ? "text-light" : "textDark"}`}>
                Dados do Usuário
              </h3>
            </div>

            <Form className="forms-configpage">
              <Form.Group>
                <Form.Label
                  className={` ${statusMode ? "text-light" : "textDark"}`}
                >
                  Nova Senha
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Nova senha"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" onClick={handleUpdatePassword}>
                Atualizar Senha
              </Button>
            </Form>
            <hr />
            <Form className="forms-configpage">
              <Form.Group>
                <Form.Label
                  className={` ${statusMode ? "text-light" : "textDark"}`}
                >
                  Novo Telefone
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Novo telefone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>

              <Button
                className={` ${statusMode ? "text-light" : "textDark"}`}
                variant="primary"
                onClick={handleUpdatePhone}
              >
                Atualizar Telefone
              </Button>
            </Form>
            <hr />
            <div className="center-btn" style={{textAlign:"center"}}>
            <Button
              className={` ${statusMode ? "text-light" : "textDark"}`}
              variant="danger"
              onClick={handleDeleteAccount}
            >
              Excluir Conta
            </Button>
            </div>
          </div>
        )}
        {activeTab === "downloads" && (
          <div className="downloads-card body-carduser">
            <div className="status-header">
              <h3 className={` ${statusMode ? "text-light" : "textDark"}`}>
                Área de Downloads
              </h3>
            </div>

            {/* Conteúdo dos Downloads */}
          </div>
        )}
        {activeTab === "suporte" && (
          <div className="support-card body-carduser">
            <div className="status-header">
              <h3>Suporte</h3>
            </div>

            <p>Para obter suporte, entre em contato pelo WhatsApp.</p>
            <Button
              className={` ${statusMode ? "text-light" : "textDark"}`}
              variant="success"
              href={linkWhatsapp}
            >
              Contatar Suporte via WhatsApp
            </Button>
          </div>
        )}
        {activeTab === "sair" && (
          <div className="body-carduser">
            <div className="status-header">
              <h1 className={` ${statusMode ? "text-light" : "textDark"}`}>
                Desconectar
              </h1>
            </div>

            <hr />

            <li className="nav-link">
              <span className="navlink">
                <a className="linknavbar" onClick={handleVoltarClick}>
                  <b className={` ${statusMode ? "text-light" : "textDark"}`}>
                    SAIR <FontAwesomeIcon icon={faSignOutAlt} />
                  </b>
                </a>
              </span>
            </li>
          </div>
          // Ação para sair, como handleLogout ou redirecionamento
        )}
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes do Pagamento </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {statusPayment ? (
            <>
              <StatusUpdate preferenceID={UserID} />
            </>
          ) : (
            <>
              <Payment
                initialization={initialization}
                customization={customization}
                onSubmit={onSubmit}
                onReady={onReady}
                onError={onError}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConfigPage;
