import React, { useState, useEffect } from "react";
import { loadMercadoPago } from "@mercadopago/sdk-js";
import { Payment } from "@mercadopago/sdk-react";
import { initMercadoPago } from "@mercadopago/sdk-react";
import { useParams } from "react-router-dom";
import StatusUpdate from "./StatusUpdate";
import axios from "axios";
import PaymentError from "../pages/PaymentErros";

initMercadoPago("APP_USR-01e92b7d-7a5c-4a66-97a9-1f94dad8025a", {
  // Add your public key credential
  locale: "pt-BR",
});

await loadMercadoPago();


const PayamentMP = (props) => {
  const {
    statusMode,
    setStatusMode,
    handleLogout,
    setIsLoggedIn,
    isLoggedIn,
    showLogoutButton,
    notificationVolume,
    setNotificationVolume,
    userData,
    user,
    setIdinitialization,
    Idinitialization,
    Amountinitialization,
    DateMsg,
    setDateMsg
  } = props;

  const params = useParams();


  const [statusPayment, setStatusPayment] = useState(false);
  const [UserID, setUserID] = useState(null);
  const [initialization, setinItialization] = useState(null);
  const [planIsSelected, setPlanIsSelected] = useState(true);



  function moldeInitialization(n46307526) {
    return {
      amount: n46307526,
      preferenceId: Idinitialization,
    };


  }

  const customization = {
    paymentMethods: {
      ticket: "all",
      bankTransfer: "all",
      creditCard: "all",
      debitCard: "all",
      mercadoPago: "all",
    },
  };
  
  async function getValuePlan(DateMsgToModify) {

    let e6245 = DateMsgToModify['user'];
    let gettedN46307526 = localStorage.getItem('n46307526');

    if (gettedN46307526 !== null) {
      let convertedN46307526 = JSON.parse(gettedN46307526);
      
      const data = { "n46307526": convertedN46307526, 'e6245': user.email };
      const response = await fetch(`https://rd.vps.webdock.cloud:9456/get_plan_value`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        const data = await response.json();
        

        if (('status' in data) === true && data['status'] === true) {
          setinItialization(moldeInitialization(data['value_plan']));


          let namePlanJSON = localStorage.n46307526;
          let descriptionPlanJSON = localStorage.d37274784660p526;
          if (namePlanJSON !== undefined) {
            let namePlanDecoded = JSON.parse(namePlanJSON);
            let descriptionPlanDecoded = descriptionPlanJSON !== undefined ? JSON.parse(descriptionPlanJSON) : DateMsg['description'];
            DateMsgToModify['price'] = data['value_plan'];
            DateMsgToModify['title'] = namePlanDecoded;
            DateMsgToModify['description'] = descriptionPlanDecoded;
            setDateMsg(DateMsgToModify);
            setPlanIsSelected(true);
          }

        }

        return data;
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    }else{
      setPlanIsSelected(false);
    } 
  }

  useEffect(() => {
    getValuePlan(DateMsg)
  }, []);


  const onSubmit = async ({ selectedPaymentMethod, formData }) => {    
    if(user === null){
      window.location.href = "/";
    }

    try {
      const IDsPreference = Idinitialization;

      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9123/process_payment",
        {
          ...formData,
        }
      );

      if (response.data.erro) {
        throw new Error(response.data.erro);
      } else {

        const { status, status_detail, id } = response.data;


        if (id != null) {


          const response = await axios.post(
            "https://rd.vps.webdock.cloud:9123/cadastrar_payment",
            {
              user: user.email,
              idsIniciar: id,
            }
          );
          if (response.data.erro) {
            throw new Error(response.data.erro);
          } else {
            fetchData();
          }
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const onError = async (error) => {
    // callback chamado para todos os casos de erro do Brick
    console.log(error);
  };
  const onReady = async () => {
    /*
      Callback chamado quando o Brick estiver pronto.
      Aqui você pode ocultar loadings do seu site, por exemplo.
    */
  };

  const fetchData = async (e) => {
    try {
      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9123/get_dabase_userid",
        {
          user: user.email,
        }
      );

      if (response.data.erro) {
        throw new Error(response.data.erro);
      } else {

        const preferenceID = response.data.preferenceID;
        handleIdSuccess(preferenceID);
        window.localStorage.setItem("preferenceID", JSON.stringify(preferenceID));

        // Defina o statusPayment como true para mostrar o StatusScreen
        setStatusPayment(true);
        fechtStatus(preferenceID);
      }
    } catch (error) {
      console.error("Erro na solicitação Axios:", error);
    }
  };

  const handleIdSuccess = (UserID) => {
    setUserID(UserID);

  };

  const fechtStatus = async (UserID) => {



    
    try {
      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9123/get_payment",
        {
          id: UserID,
          datepayment: DateMsg,
        }
      );

      if (response.data.erro) {
        throw new Error(response.data.erro);
      } else {
        console.log("response");
      }
    } catch (error) {
      console.error("Erro na solicitação Axios:", error);
    }

  };

  return (
    <>
      {statusPayment ? (
        <>
          <StatusUpdate preferenceID={UserID} />
        </>
      ) : initialization !== null ? (
        <>
          <Payment
            initialization={initialization}
            customization={customization}
            onSubmit={onSubmit}
            onReady={onReady}
            onError={onError}
          />
        </>
      ) : !planIsSelected ?
        <PaymentError/>
        :
        ""
      }
    </>
  );
}

export default PayamentMP;