import { modelPopUpSettings, orderLoop, managerClonerBetHome } from "../../global/GlobalVariables";
import { managerFunctionCloneBetHome } from "../../global/GlobalFunctions";
import {
  keywordsTotal,
  keywordsWinner,
  keywordsPenalties,
  keywordsDrawNobet,
  keywordsCards,
  keywordsClassification,
  keywordsOddsOrEven,
  keywordsShotsOnGoal,
  keywordsShotsAndFinishes,
  keywordsImpediments,
  keywordsAssistance,
  keywordsDisarms,
  keywordsFouls,
  keywordsReplacements,
  keywordsBallStealing,
  keywordsBasketsOfThree,
  keywordsYesNo,
  keywordsCorrectResultScore,
  keywordsAces,
  keywordsLayAgainst,
  keywordsBothScore,
  keywordsAsianHandicap,
  keywordsEuropeanHandicap,
  marketsSortByTotal,
  keywordsHandcap
} from "../variables/VariablesGame";

import { bom_dia } from "../../global/GlobalVariables";
import CryptoJS from "crypto-js";
import Cookies from 'js-cookie';


function removerAcentos(texto) {
  return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function reOrderFirstItemArrayByValue(list, kyeList) {
  const index = list.findIndex(el => el === kyeList);

  if (index > -1) {

    const [removed] = list.splice(index, 1);

    list.unshift(removed);
  }

  return list
}

export function orderGameByTime(statusFilter, dataSurebet, setDataSurebetModifiedJSON, keySurebetSaved = '') {
  if (statusFilter === 'início') {

    let newDataSurebet = {};
    let keysObject = Object.keys(dataSurebet);
    let gcc = {};
    for (let i = 0; i < keysObject.length; i++) {

      let keyObject = keysObject[i];
      let valueObject = dataSurebet[keyObject]['main'];
      let listLargeTime = [];

      for (let i_orderLoop = 0; i_orderLoop < orderLoop.length; i_orderLoop++) {
        let order = orderLoop[i_orderLoop];

        if (order in valueObject) {


          listLargeTime.push(valueObject[order]['time_milliseconds']);

        }
      }

      let listLargeTimeOrdenated = listLargeTime.sort();
      listLargeTimeOrdenated.reverse()

      if (gcc[listLargeTimeOrdenated[0]] === undefined) {
        gcc[listLargeTimeOrdenated[0]] = [keyObject];
      } else {
        gcc[listLargeTimeOrdenated[0]].push(keyObject);
      }
    }


    let keysGcc = Object.keys(gcc);
    let keysGccOrdenated = keysGcc.sort();


    let allKeysOrdered = [];
    for (let i = 0; i < keysGccOrdenated.length; i++) {
      let keyGccOrdenated = keysGccOrdenated[i];

      for (let i_gccItem = 0; i_gccItem < gcc[keyGccOrdenated].length; i_gccItem++) {
        let item = gcc[keyGccOrdenated][i_gccItem];
        allKeysOrdered.push(item);
      }
    }

    let newListOrderedByTime = reOrderFirstItemArrayByValue(allKeysOrdered, keySurebetSaved);


    for (let i = 0; i < newListOrderedByTime.length; i++) {
      let keySurebet = newListOrderedByTime[i];
      newDataSurebet[keySurebet] = dataSurebet[keySurebet];
    }


    let newDataSurebetJSON = JSON.stringify(newDataSurebet);
    setDataSurebetModifiedJSON(newDataSurebetJSON);
  }
  else if (statusFilter === 'recentes') {

    let newDataSurebet = {};
    let keysObject = Object.keys(dataSurebet);
    let gcc = {};
    let keysNumber = [];
    for (let i = 0; i < keysObject.length; i++) {

      let keyObject = keysObject[i];
      let valueObject = dataSurebet[keyObject]['main'];
      let timeLower = valueObject['time'].toLowerCase();

      let g = timeLower.indexOf('h') !== -1
        ? (parseFloat(timeLower.replace('h', '')) * 60)
        : timeLower.indexOf('dia') !== -1 ?
          (parseFloat(timeLower.replace('dias', '').replace('dia', '')) * 1440)

          : parseFloat(timeLower.replace('min', ''));


      if (gcc[g] === undefined) {
        gcc[g] = [keyObject];
        keysNumber.push(g);
      } else {
        gcc[g].push(keyObject);
      }
    }

    let keysGccOrdenated = keysNumber.sort((a, b) => a - b);

    let allKeysOrdered = [];
    for (let i = 0; i < keysGccOrdenated.length; i++) {
      let keyGccOrdenated = keysGccOrdenated[i].toString();

      for (let i_gccItem = 0; i_gccItem < gcc[keyGccOrdenated].length; i_gccItem++) {
        let item = gcc[keyGccOrdenated][i_gccItem];
        allKeysOrdered.push(item);
      }
    }

    let newListOrderedByTimeArrived = reOrderFirstItemArrayByValue(allKeysOrdered, keySurebetSaved);


    for (let i = 0; i < newListOrderedByTimeArrived.length; i++) {
      let keySurebet = newListOrderedByTimeArrived[i];
      newDataSurebet[keySurebet] = dataSurebet[keySurebet];
    }

    let newDataSurebetJSON = JSON.stringify(newDataSurebet);
    setDataSurebetModifiedJSON(newDataSurebetJSON);
  }
  else {
    let keysSurebet = Object.keys(dataSurebet);
    let listProfitKey = [];
    let listKeysSurebetOrganizedByProfit = {};
    let dataSurebetSortedByProfit = {};

    for (let i = 0; i < keysSurebet.length; i++) {
      let keySurebet = keysSurebet[i];
      let profitSurebet = parseFloat(dataSurebet[keySurebet]["main"]["profit"].replace(",", ".").replace("%", ""));
      listProfitKey.push(profitSurebet);

      let keyProfitSurebet = profitSurebet.toString();

      if (listKeysSurebetOrganizedByProfit[keyProfitSurebet] === undefined) {
        listKeysSurebetOrganizedByProfit[keyProfitSurebet] = [keySurebet];
        continue
      }
      listKeysSurebetOrganizedByProfit[keyProfitSurebet].push(keySurebet);
    }

    listProfitKey.sort((a, b) => a - b);
    listProfitKey.reverse();


    let allKeysOrdered = [];
    for (let i = 0; i < listProfitKey.length; i++) {
      let keyProfitSurebet = listProfitKey[i].toString();

      for (let i_2 = 0; i_2 < listKeysSurebetOrganizedByProfit[keyProfitSurebet].length; i_2++) {
        let keySurebetOrganizedByProfit = listKeysSurebetOrganizedByProfit[keyProfitSurebet][i_2];
        allKeysOrdered.push(keySurebetOrganizedByProfit);
      }
    }

    let newListProfitKey = reOrderFirstItemArrayByValue(allKeysOrdered, keySurebetSaved);


    for (let i = 0; i < newListProfitKey.length; i++) {
      let keySurebet = newListProfitKey[i];
      dataSurebetSortedByProfit[keySurebet] = dataSurebet[keySurebet];
    }

    let newDataSurebetJSON = JSON.stringify(dataSurebetSortedByProfit);
    setDataSurebetModifiedJSON(newDataSurebetJSON);
  }
}


export function notifyBet(notificationVolume, timePlaySound) {
  let resultReturn = {
    "status": false,
    "value": 0
  }


  let timeInMilliseconds = new Date().getTime()

  if (timeInMilliseconds >= timePlaySound || timePlaySound === undefined) {

    const song = `${process.env.PUBLIC_URL}/apitodefutebol.mp3`;
    const audioElement = new Audio(song);

    const playNotificationSound = () => {
      // Defina o volume do áudio com base no estado notificationVolume
      audioElement.volume = notificationVolume;

      audioElement.play().catch((error) => {
        console.error("Erro ao reproduzir o som:", error);
      });
    };


    playNotificationSound();
    timeInMilliseconds += (6 * 1000)
    resultReturn["status"] = true;
    resultReturn["value"] = timeInMilliseconds;

  }
  return resultReturn;
}


export function openPopUpCalculator(value, data) {
  localStorage.removeItem('dataCalculator');
  if (typeof data !== 'string') {
    localStorage.setItem('dataCalculator', JSON.stringify(data))
  }
  else {
    localStorage.setItem('dataCalculator', data);
  }
  const url = `${window.location.origin}/calculator/${value}`;
  const windowName = "popupCalculator"; // Nome da janela pop-up

  if (window[windowName] && !window[windowName].closed) {

    window[windowName].onhashchange = function () {
      window[windowName].location.reload();
    };

    window[windowName] = window.open(url, windowName, 'popup');
    // ---> window[windowName].focus();
    return true;
  } else {
    const windowFeatures = "width=800,height=460";

    window[windowName] = window.open(url, windowName, windowFeatures);


    if (!window[windowName]) {

      alert(
        "Pop-up bloqueado! Por favor, permita pop-ups para este site nas configurações do seu navegador."
      );
    }
    // ---> window[windowName].focus();

    return true;
  }
}


export function openPopUpAllBet(value, value2, setSettingsPopUpsJSON, settingsPopUpsJSON) {
  const url = `${value}`;
  const url2 = `${value2}`;
  const windowName = "popupWindowAll"; // Nome da janela pop-up
  const windowName2 = "popupWindowAll2"; // Nome da janela pop-up

  let widthPopUpOne = 800;
  let heightPopUpOne = 460;
  let leftPopUpOne = 0;
  let topPopUpOne = 0;

  let widthPopUpTwo = 800;
  let heightPopUpTwo = 460;
  let leftPopUpTwo = 0;
  let topPopUpTwo = 0;


  try {

    let popUpSettings = JSON.parse(settingsPopUpsJSON);
    widthPopUpOne = popUpSettings[windowName]['width'];
    heightPopUpOne = popUpSettings[windowName]['height'];
    leftPopUpOne = popUpSettings[windowName]['left'];
    topPopUpOne = popUpSettings[windowName]['top'];

    widthPopUpTwo = popUpSettings[windowName2]['width'];
    heightPopUpTwo = popUpSettings[windowName2]['height'];
    leftPopUpTwo = popUpSettings[windowName2]['left'];
    topPopUpTwo = popUpSettings[windowName2]['top'];
  } catch {
    setSettingsPopUpsJSON(JSON.stringify(modelPopUpSettings));

    widthPopUpOne = 800;
    heightPopUpOne = 460;
    leftPopUpOne = 0;
    topPopUpOne = 0;
    widthPopUpTwo = 800;
    heightPopUpTwo = 460;
    leftPopUpTwo = 0;
    topPopUpTwo = 0;
  }

  // Verifique se a janela pop-up já existe
  if (
    window[windowName] &&
    !window[windowName].closed &&
    window[windowName2] &&
    !window[windowName2].closed
  ) {


    window[windowName] = window.open('about:blank', windowName, `popup`);
    window[windowName2] = window.open('about:blank', windowName2, `popup`);


    setTimeout(() => {



      try {
        widthPopUpOne = window[windowName].innerWidth;
        heightPopUpOne = window[windowName].innerHeight;
        leftPopUpOne = window[windowName].screenX;
        topPopUpOne = window[windowName].screenY;

        widthPopUpTwo = window[windowName2].innerWidth;
        heightPopUpTwo = window[windowName2].innerHeight;
        leftPopUpTwo = window[windowName2].screenX;
        topPopUpTwo = window[windowName2].screenY;
        let popUpSettings = { 'popupWindowAll': {}, 'popupWindowAll2': {} };
        //let popUpSettings = JSON.parse(localStorage.popUpSettings);

        popUpSettings[windowName]['width'] = widthPopUpOne;
        popUpSettings[windowName]['height'] = heightPopUpOne;
        popUpSettings[windowName]['left'] = leftPopUpOne;
        popUpSettings[windowName]['top'] = topPopUpOne;

        popUpSettings[windowName2]['width'] = widthPopUpTwo;
        popUpSettings[windowName2]['height'] = heightPopUpTwo;
        popUpSettings[windowName2]['left'] = leftPopUpTwo;
        popUpSettings[windowName2]['top'] = topPopUpTwo;

        setSettingsPopUpsJSON(JSON.stringify(popUpSettings));
      }
      catch {
        console.log('Erro');


      }
      window[windowName].close()
      window[windowName2].close()

      window[windowName] = window.open(url, windowName, `width=${widthPopUpOne},height=${heightPopUpOne}, top=${topPopUpOne},left=${leftPopUpOne}`);
      window[windowName2] = window.open(url2, windowName2, `width=${widthPopUpTwo},height=${heightPopUpTwo}, top=${topPopUpTwo},left=${leftPopUpTwo}`);

    }, 3000)

    return;
  } else {

    window[windowName] = window.open(url, windowName, `width=${widthPopUpOne},height=${heightPopUpOne}, top=${topPopUpOne},left=${leftPopUpOne}`);
    window[windowName2] = window.open(url2, windowName2, `width=${widthPopUpTwo},height=${heightPopUpTwo}, top=${topPopUpTwo},left=${leftPopUpTwo}`);
    if (!window[windowName2] || !window[windowName]) {
      alert(
        "Pop-up bloqueado! Por favor, permita pop-ups para este site nas configurações do seu navegador."
      );
    }
    // ---> window[windowName].focus();
    // ---> window[windowName2].focus();


    // Listen for messages from the popup window

    return;
  }
}


//Help Render page
export const handleScroll = (rowRef, setMinIndex, setMaxIndex, limitSurebetToShow) => {

  const scrollTop = window.scrollY;




  if (rowRef.current) {
    const rowHeight = rowRef.current.getBoundingClientRect().height;



    let myCount = scrollTop - 270;
    let resultToPut = parseInt(myCount / rowHeight);

    let mx = 30 + resultToPut;
    if (limitSurebetToShow.current >= mx) {

      setMinIndex(resultToPut);
      setMaxIndex(mx);
    } else {

      let newMindIndex = limitSurebetToShow.current - 30;
      let newMindIndexFiltered = newMindIndex < 0 ? 0 : newMindIndex;
      setMinIndex(newMindIndexFiltered);
      setMaxIndex(limitSurebetToShow.current);
    }
  }
};





export function detectortDiference(oldDataJsonConvertted) {
  const keysBet = Object.keys(oldDataJsonConvertted);
  let newListWarningTimeSurebet = [];
  for (const key of keysBet) {
    const dataBet = oldDataJsonConvertted[key]["main"];
    const allTime = [];

    for (const order of orderLoop) {
      if (order in dataBet) {
        allTime.push(Number(dataBet[order]["time_milliseconds"]));
      }
    }

    let statusLoop = true;

    for (let indexTime = 0; indexTime < allTime.length; indexTime++) {
      const time = allTime[indexTime];
      for (let indexTime2 = 0; indexTime2 < allTime.length; indexTime2++) {
        const time2 = allTime[indexTime2];
        const resultCalculate = time - time2;
        if (resultCalculate > 900000 || resultCalculate < -900000) {
          newListWarningTimeSurebet.push(key);
          statusLoop = false;
          break;
        }
      }
      if (!statusLoop) {
        break;
      }
    }
  }
  return newListWarningTimeSurebet;
}


function checkRequiredBetHome(strBetHome, listRequiredBetHome) {

  if (listRequiredBetHome.length === 0) {
    return true;
  }
  for (let i = 0; i < listRequiredBetHome.length; i++) {
    let requiredBetHome = listRequiredBetHome[i];

    if (strBetHome.includes(requiredBetHome) === true) {
      return true;
    }
  }

  return false;
}


export function cloneBetHome(nameBetHomeLower, game, betHomeEnabled, keyOrderBethome) {
  if (managerClonerBetHome['allBetHomeAbleToClone'].includes(nameBetHomeLower)) {

    for (let i = 0; i < managerClonerBetHome[nameBetHomeLower]['allBetHomeClone'].length; i++) {
      let nameBetHomeToClone = managerClonerBetHome[nameBetHomeLower]['allBetHomeClone'][i];

      if (betHomeEnabled.includes(nameBetHomeToClone) && !betHomeEnabled.includes(nameBetHomeLower)) {

        let actualURLBet = game["url_bet"];

        let newURLBet = managerFunctionCloneBetHome(
          managerClonerBetHome,
          nameBetHomeLower,
          nameBetHomeToClone,
          actualURLBet
        );


        const urlString = managerClonerBetHome[nameBetHomeLower][nameBetHomeToClone]['baseURL'];
        const url = new URL(urlString);
        const origin = url.origin;

        game["url_bet_home"] = origin;
        game["url_bet"] = newURLBet;
        game["name_bet_home"] = managerClonerBetHome[nameBetHomeLower][nameBetHomeToClone]['nameBetHome'];
        break;
      }

    }
  }
  return game
}

export function filterBetHome(fullGame, listBetHomeEnabled, listRequiredBetHome) {
  let dataSurebet = fullGame["main"];
  let strBetHomeMixed = '';
  for (let i = 0; i < orderLoop.length; i++) {
    let order = orderLoop[i];

    if (order in dataSurebet) {
      let oldNameBetHome = dataSurebet[order]["name_bet_home"].toLowerCase();
      let newDataSurebetBetHome = cloneBetHome(oldNameBetHome, dataSurebet[order], listBetHomeEnabled, order);

      let nameBetHome = newDataSurebetBetHome["name_bet_home"].toLowerCase();
      fullGame["main"][order] = newDataSurebetBetHome;

      if (listBetHomeEnabled.includes(nameBetHome) === true) {
        strBetHomeMixed += nameBetHome;
        continue
      }
      return {};
    }
  }

  let canPassBet = checkRequiredBetHome(strBetHomeMixed, listRequiredBetHome);
  if (canPassBet === true) {
    return fullGame;
  }
  return {};
}



export function formattProfitSurebetLive(strProfit) {
  return `${(Number(strProfit.replace(",", ".").replace("%", ""))).toFixed(2).replace(".", ",")}%`;
}



export function formatOddsSurebet(dataSurebet) {

  let listsOddsOrganized = {};
  for (let i = 0; i < orderLoop.length; i++) {
    let order = orderLoop[i];

    if (order in dataSurebet) {
      listsOddsOrganized[order] = Number(dataSurebet[order]["chance"]).toFixed(2);
    }
  }

  return listsOddsOrganized;
}



function checkMarket(market, listKeywordsMarket) {



  for (let i = 0; i < listKeywordsMarket.length; i++) {
    let KeywordMarket = listKeywordsMarket[i];
    let marketHasKeyword = market.includes(KeywordMarket);

    if (marketHasKeyword === true) {

      return true;
    }

  }

  return false;
}


function checkMarketWinner(market, listKeywordsMarket) {

  let others_keywords = [
    "11-2",
    "21-2",
    "11-2|",
    "21-2|",
    "1|",
    "2|",
    "1x",
    "x2",
    "1-2tempoextra",
    "time1vencetempo",
    "time2vencetempo"
  ];

  let marketFragmented = market.split("***");
  for (let i = 0; i < listKeywordsMarket.length; i++) {
    let KeywordMarket = listKeywordsMarket[i];
    if (marketFragmented.includes(KeywordMarket)) {
      return true;
    }
  }

  for (let i = 0; i < others_keywords.length; i++) {
    let keyword = others_keywords[i];
    let hasKeyword = market.includes(keyword);
    if (hasKeyword === true) {
      return true;
    }
  }


  return false;
}


function checkMarketOddOrEven(market, listKeywordsMarket) {

  let others_keywords = [
    "impar|",
    "par|",
    "ímpar|"
  ];

  let marketFragmented = market.split("***");
  for (let i = 0; i < listKeywordsMarket.length; i++) {
    let KeywordMarket = listKeywordsMarket[i];
    if (marketFragmented.includes(KeywordMarket)) {
      return true;
    }
  }

  for (let i = 0; i < others_keywords.length; i++) {
    let keyword = others_keywords[i];
    let hasKeyword = market.includes(keyword);
    if (hasKeyword === true) {
      return true;
    }
  }

  return false;
}

function isHasHandcapMarket(market) {
  let namesInHandcapMarket = keywordsHandcap;

  for (let i = 0; i < namesInHandcapMarket.length; i++) {
    let nameInHandcapMarket = namesInHandcapMarket[i];

    if (market.includes(nameInHandcapMarket) === true) {
      return true;
    }
  }

  return false;
}

const condictionByMarket = {
  "totais": (market) => {
    let noHasCorner = !(market.includes("escanteio"));


    let canPassTotalMarket = marketsSortByTotal.filter(item => (market.includes(item) === true));

    if (noHasCorner === true && canPassTotalMarket.length === 0) {
      return checkMarket(market, keywordsTotal);
    }
    return false;

  },
  "asiático": (market) => {
    let hasHandicap = (isHasHandcapMarket(market));
    if (hasHandicap) {
      return checkMarket(market, keywordsAsianHandicap);
    }
    return false;
  },
  "europeu": (market) => {
    let hasHandicap = (isHasHandcapMarket(market));
    if (hasHandicap) {
      return checkMarket(market, keywordsEuropeanHandicap);
    }
    return false;
  },
  "escanteios": (market) => {
    let hasCorner = (market.includes("escanteio"));
    let hasNoHandicap = !(isHasHandcapMarket(market));
    if (hasCorner === true && hasNoHandicap === true) {
      return true;
    }
    return false;
  },
  "resultados": (market) => {
    let noHasCorner = !(market.includes("escanteio"));
    let hasNoHandicap = !(isHasHandcapMarket(market));
    if (noHasCorner === true && hasNoHandicap === true) {
      return checkMarketWinner(market, keywordsWinner);
    }
    return false;
  },
  "pênalties": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsPenalties);
    }
    return false;
  },
  "empateanula(dnb)": (market) => {
    let noHasCorner = !(market.includes("escanteio"));
    let hasNoHandicap = !(isHasHandcapMarket(market));
    if (noHasCorner === true && hasNoHandicap === true) {
      return checkMarket(market, keywordsDrawNobet);
    }
    return false;
  },
  "cartões": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsCards);
    }
    return false;
  },
  "classificação": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsClassification);
    }
    return false;
  }
  ,
  "ímpar/par": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarketOddOrEven(market, keywordsOddsOrEven);
    }
    return false;
  },
  "chutesagol": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {

      return checkMarket(market, keywordsShotsOnGoal);
    }
    return false;
  },
  "chutesefinalizações": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    let hasShotsOnTheGoal = keywordsShotsOnGoal.filter(item => market.includes(item))
    if (noHasCorner === true && hasShotsOnTheGoal.length === 0) {

      return checkMarket(market, keywordsShotsAndFinishes);
    }
    return false;
  },
  "impedimentos": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {

      return checkMarket(market, keywordsImpediments);
    }
    return false;
  },
  "assistências": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsAssistance);
    }
    return false;
  },
  "desarmes": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsDisarms);
    }
    return false;
  },
  "faltas": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsFouls);
    }
    return false;
  },
  "lateral(reposições)": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsReplacements);
    }
    return false;
  },
  "roubodebola": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsBallStealing);
    }
    return false;
  },
  "cestasdetrês": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsBasketsOfThree);
    }
    return false;
  },
  "sim/não": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsYesNo);
    }
    return false;
  },
  "resultadocorreto(pontuação)": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsCorrectResultScore);
    }
    return false;
  },
  "aces": (market) => {
    let noHasCorner = !(market.includes("escanteio"));
    let hasNoHandicap = !(isHasHandcapMarket(market));
    if (noHasCorner === true && hasNoHandicap === true) {
      return checkMarket(market, keywordsAces);
    }
    return false;
  },
  "lay(contra)": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsLayAgainst);
    }
    return false;
  },
  "ambosmarcam": (market) => {
    let noHasCorner = !(market.includes("escanteio"));

    if (noHasCorner === true) {
      return checkMarket(market, keywordsBothScore);
    }
    return false;
  },
}
// x12 ambosmarcam
export function filterMarketEvent(listMarketsToHide, listEvents, surebet) {

  let has_description_data_market = false;
  let listMarketsToCheck = [];


  let listKeywordsLower = listEvents.map(item =>  removerAcentos(item.toLowerCase()))

  let EventsAndDescriptionEventMixed = "";

  for (let i = 0; i < orderLoop.length; i++) {
    let order = orderLoop[i];




    if (surebet[order] !== undefined) {
      let allInfoMarketMixed = "";
      let partSurebet = surebet[order];
      EventsAndDescriptionEventMixed += (" " + partSurebet["title_event"] + " " + partSurebet["sub_title_event"])
      if (has_description_data_market || partSurebet.hasOwnProperty('description_data_market')) {




        has_description_data_market = true;

        if (partSurebet["data_market"]["info"] === undefined) {
          let partmarketFragmented = Object.values(partSurebet["data_market"]);
          let partmarketToSTR = ((partmarketFragmented.join("")).toLowerCase().replace(/ /g, '')
            .replace("common_data_market", "")
            .replace("first_second_extra_time", "")
            .replace("default_data_market", "")


          );
          allInfoMarketMixed += removerAcentos(partmarketToSTR);
          allInfoMarketMixed += "***";
          allInfoMarketMixed += removerAcentos(partSurebet["description_data_market"].toLowerCase().replace(/ /g, ''))
          allInfoMarketMixed += "***";

          listMarketsToCheck.push(allInfoMarketMixed);
          continue;
        }
        let partsMarket = partSurebet["data_market"]["info"].map(item => item.value.toLowerCase().replace(/ /g, ''));

        allInfoMarketMixed += removerAcentos(partsMarket.join(""));
        allInfoMarketMixed += "***";
        allInfoMarketMixed += removerAcentos(partSurebet["description_data_market"].toLowerCase().replace(/ /g, ''))
        allInfoMarketMixed += "***";
        listMarketsToCheck.push(allInfoMarketMixed);
        continue;
      }

      allInfoMarketMixed += removerAcentos((partSurebet["data_market"]).toString().replace(/<[^>]+>/g, ' ')
        .replace(/\s+/g, ' ').trim()
        .toLowerCase().replace(/ /g, ''));
      allInfoMarketMixed += "***";
      allInfoMarketMixed += removerAcentos(partSurebet["title"].toLowerCase().replace(/ /g, ''))
      allInfoMarketMixed += "***";

      listMarketsToCheck.push(allInfoMarketMixed);

    }
  }

  EventsAndDescriptionEventMixed = removerAcentos(EventsAndDescriptionEventMixed.trim().toLowerCase());
  

  
  for(let i = 0; i < listKeywordsLower.length; i++){
    let keywordFobbided = listKeywordsLower[i];

    if(EventsAndDescriptionEventMixed.includes(keywordFobbided) === true){
      return false;
    }
  }


  for (let i = 0; i < listMarketsToHide.length; i++) {
    let marketToHide = listMarketsToHide[i];
    if (condictionByMarket[marketToHide] !== undefined) {


      for (let indexListMarketsToCheck = 0; indexListMarketsToCheck < listMarketsToCheck.length; indexListMarketsToCheck++) {
        let allMarketInfo = listMarketsToCheck[indexListMarketsToCheck];

        let resultCheckMarket = condictionByMarket[marketToHide](allMarketInfo);

        if (resultCheckMarket === true) {
          return false
        }
      }



    }
  }
  return true
}

export function decrypt_game(data) {

  if (data.length === 0) {
    return {};
  }



  const bom_dia_pra_voce_tambem = CryptoJS.enc.Utf8.parse(bom_dia);

  const decrypted = CryptoJS.AES.decrypt(data, bom_dia_pra_voce_tambem, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7, // Ensures proper padding
  });

  const dataDecrypted = decrypted.toString(CryptoJS.enc.Utf8);


  return JSON.parse(dataDecrypted);
}

export async function userCheckAsRead() {
    const b4726486 = Cookies.get('b4726486');
    const dataToSend = {
        's377466043': b4726486,
    };
    const response = await fetch(`https://rd.vps.webdock.cloud:9456/get_user_check_as_read`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify(dataToSend)
    });

    if (response.ok) {
        const data = await response.json();
        return data;
    } else {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}


export async function setUserCheckAsRead() {
  const b4726486 = Cookies.get('b4726486');
  const dataToSend = {
      's377466043': b4726486,
  };
  const response = await fetch(`https://rd.vps.webdock.cloud:9456/set_user_check_as_read`, {
      method: 'POST',
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
      },
      body: JSON.stringify(dataToSend)
  });

  if (response.ok) {
      const data = await response.json();
      return data;
  } else {
      throw new Error(`HTTP error! status: ${response.status}`);
  }
}