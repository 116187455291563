const sportsPart = [
    "Artes Marciais",
    "Badminton",
    "Bandy",
    "Basquete",
    "Basquete 3x3",
    "Basquete 4x4",
    "Beisebal",
    "Boliche",
    "Cricket",
    "Dardos",
    "Floorball",
    "Futebol",
    "Futebol 3x3",
    "Futebol 4x4",
    "Futebol 5x5",
    "Futebol Americano",
    "Futebol Australiano",
    "Futebol Gaélico",
    "Futebol de Praia",
    "Futebol de Salão",
    "Futsal",
    "Golfe",
    "Handebol",
    "Handebol de Praia",
    "Hurling",
    "Hóquei",
    "Hóquei de Ar",
    "Hóquei de Ar 2x2",
    "Hóquei em Campo",
    "Lacrosse",
    "Netbol",
    "O que Onde Quando",
    "Pólo Aquático",
    "Rugby",
    "Sinuca",
    "Tênis",
    "Tênis de Mesa",
    "Voleibol",
    "Voleibol de Praia",
    "Xadrez"
]

const esports = [
    "Arena of Valor",
    "Call of Duty",
    "Counter-Strike",
    "Dota",
    "E-Futebol",
    "King of Glory",
    "League of Legends",
    "NBA2K",
    "Overwatch",
    "Rainbow",
    "Rocket League",
    "StarCraft",
    "Valorant",
    "Warcraft"
];

const allSportsMinuscle = [
    "arena of valor",
    "artes marciais",
    "badminton",
    "bandy",
    "basquete",
    "basquete 3x3",
    "basquete 4x4",
    "beisebal",
    "boliche",
    "cricket", 
    "call of duty",
    "counter-strike",
    "dardos",
    "dota",
    "e-futebol",
    "floorball",
    "futebol",
    "futebol 3x3",
    "futebol 4x4",
    "futebol 5x5",
    "futebol americano",
    "futebol australiano",
    "futebol gaélico",
    "futebol de praia",
    "futebol de salão",
    "futsal",
    "golfe",
    "handebol",
    "handebol de praia",
    "hurling",
    "hóquei",
    "hóquei de ar",
    "hóquei de ar 2x2",
    "hóquei em campo",
    "king of glory",
    "lacrosse",
    "league of legends",
    "nba2k",
    "netbol",
    "o que onde quando",
    "overwatch",
    "pólo aquático",
    "rainbow",
    "rocket league",
    "rugby",
    "sinuca",
    "starcraft",
    "tênis",
    "tênis de mesa",
    "valorant",
    "voleibol",
    "voleibol de praia",
    "warcraft",
    "xadrez"
];

const Sports = (props) => {
    const { 
        openPageIndexFilter,
        statusMode,
        sports,
        setSports,
        saveFilterSports,
        saveFilterChanged,
    } = props;

    function EnabledDisabledSport(event){        
        const { checked, name } = event.target;
        
        if(checked === false){
            setSports(
                (allSports) =>{
                    return allSports.filter( (item) => item !== name )
                }
            );            
        }else{
            setSports(current => [...current, name]);     
            
        }
        
    }

    function markAllSports(event){        
        const { checked } = event.target;
        
        if(checked === false){            
            setSports([]);
        }
        else{      
            setSports(allSportsMinuscle);
        }
    }

    return(
        <>


        <div className={`boxHeader border-bottom p-1 py-3 d-flex justify-content-start align-items-center ${statusMode ? "bgDark borderColor textGray" : ""}`}>
            <div className="ms-3 me-2" style={{width: "min-content", color: "rgb(13,110,253)", cursor: "pointer"}} onClick={openPageIndexFilter}>
                <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" fill="currentColor" className="bi bi-arrow-left-square-fill filter-svg-back-page" viewBox="0 0 16 16">
                    <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z">                        
                    </path>
                </svg>
            </div>
            <div className="boxOptions">
                <p className="p-0 m-0 fw-semibold text-center fs-5">Esportes</p>
            </div>            
        </div>
        <div id="allCheckBox" className=" ps-4 d-flex flex-column filter fs-6" style={{height: "370px", overflow:"overlay"}}>                                            
            {sportsPart && sportsPart.map((value, index) => (                                                        
                    
                    <div className={`form-check ${statusMode ? "textGray" : ""}`} key={index}>
                        <input type="checkbox" id={"sport"+index} className="form-check-input" name={value.toLocaleLowerCase() } checked={ sports.includes( value.toLocaleLowerCase() ) } onChange={EnabledDisabledSport}/>
                        <label title="" htmlFor={"sport"+index} className="form-check-label" name={value.toLowerCase()}>
                            {value}
                        </label>
                        
                    </div>
                ))
            }
            <h4 className={`p-0 m-0 mt-3 ${statusMode ? "textGray" : ""}`}>E-sports</h4>
            {esports && esports.map((value, index) => (                                                        
                    
                    <div className={`form-check ${statusMode ? "textGray" : ""}`} key={index}>
                        <input type="checkbox" id={"sport"+(index + sportsPart.length)} className="form-check-input" name={value.toLocaleLowerCase() } checked={ sports.includes( value.toLocaleLowerCase() ) } onChange={EnabledDisabledSport}/>
                        <label title="" htmlFor={"sport"+index} className="form-check-label" name={value.toLowerCase()}>
                            {value}
                        </label>
                        
                    </div>
                ))
            }
        </div>        
        <div className={`d-flex py-2 align-item-center border-top ps-4 fs-5 ${statusMode ? "bgDark borderColor textGray" : ""}`}>           
            <input type="checkbox" id="show_all_bet_home" className="form-check-input" checked={(sports.length === allSportsMinuscle.length)} onChange={markAllSports}/>
            <label title="" htmlFor="show_all_bet_home" className="form-check-label ps-2">Mostrar Tudo</label>
        </div>
        <div className={`d-flex justify-content-end align-item-center border-top ${statusMode ? "bgDark borderColor textGray" : ""}`}>
            <button className={`btn btn-primary mt-2 me-3 fs-6 ${statusMode ? "" : ""}`}   onClick={()=>{ 
                openPageIndexFilter(); 
                saveFilterSports(sports);
                saveFilterChanged({actualSportsEnabled:sports,})
                }}>Aplicar e Filtrar</button>
        </div>
    
    </>
    )
}

export default Sports;