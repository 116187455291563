export const keywordsTotal = [
    "acima",
    "abaixo",
    "acima|",
    "abaixo|",
    "total≥",
    "gols:sim1ºotime",
    "gols:sim2ºotime",
    
];

export const keywordsAsianHandicap = [
    "asiatico",
    "asian",
    "ah1(",
    "ah2(",
    "h1(",
    "h2(",
    "h1|",
    "h2|",
];

export const keywordsEuropeanHandicap = [
    "europe",
    "eh1(",
    "eh2(",
    "eh1|",
    "eh2|"
];

export const keywordsHandcap = [
    "handicap",
    "ah1(",
    "ah2(",
    "h1(",
    "h2(",
    "h1|",
    "h2|",
    "eh1(",
    "eh2(",
    "eh1|",
    "eh2|"
  ]

export const keywordsWinner = [
    "11ªametade",
    "21ªametade",
    "1-1ºperiodo",    
    "2-1ºperiodo",
    "1asduasmetades-nao",
    "2asduasmetades-nao",
    "1asduasmetades",
    "2asduasmetades",
    "1-nao",
    "2-nao",
    "1",
    "2",
    "1asduasmetades-nao",
    "2asduasmetades-nao",
    "1asduasmetades",
    "2asduasmetades",
    "12",
    "xdentrodadistancia",
    "12dentrodadistancia",
]


export const keywordsPenalties = [
    "penalties"
]


export const keywordsDrawNobet = [
    "1/dnb",
    "2/dnb",
    "1/dnb|",
    "2/dnb|",
    "dnb1|",
    "dnb2|"
]



//***********************
export const keywordsCards = [
    "cartoe",
    "cartao",
    "cartoe",
    "cartao"
]

export const keywordsOddsOrEven = [
    "impar",
    "par",
]

export const keywordsClassification = [
    "classifi",
]


export const keywordsShotsOnGoal = [
    "chutesdegol",
    "chutedegol",
    "chutesagol",
    "chuteagol",
    "shotongoal",
    "shotsongoal",
]

export const keywordsShotsAndFinishes = [
    "chute"
]


export const keywordsImpediments = [
    "impedimento",
    "impedimentos",
]

export const keywordsAssistance = [
    "assist",
];

export const keywordsDisarms = [
  "desarme",
];

export const keywordsFouls = [
    "faltas",
];

export const keywordsReplacements = [
    "reposicoe",
    "reposicao",
]

export const keywordsBallStealing = [
    "roubo",
]

export const keywordsBasketsOfThree = [
    "cestasdetres", 
]

export const keywordsYesNo = [
    ":sim",
    ":nao",
]

export const keywordsCorrectResultScore = [
    "pontuacaode"
]

export const keywordsAces = [
    "-aces",
];

export const keywordsLayAgainst = [
    "lay(contra)",
]

export const keywordsBothScore = [
    "ambosnaomarcam",
    "ambosmarcam",
    "ambosmarcam-nao",
]


export let marketsSortByTotal = [];

let allMarketsToFilterOnTotalMarkets = [

    keywordsCards,
    keywordsClassification,
    keywordsShotsOnGoal,
    keywordsImpediments,
    keywordsAssistance,
    keywordsDisarms,
    keywordsFouls,
    keywordsReplacements,
    keywordsBallStealing,
    keywordsBasketsOfThree,
    keywordsYesNo,
    keywordsAces,
    keywordsLayAgainst,
]

for(let i = 0; i < allMarketsToFilterOnTotalMarkets.length; i++){
    let markets = allMarketsToFilterOnTotalMarkets[i];
    marketsSortByTotal = marketsSortByTotal.concat (markets);
}

export const URL_SAFE_BET = "https://arbitragem.vps.webdock.cloud/safe_bet_master_encrypted";
export const URL_DADOSPRE = "https://arbitragem.vps.webdock.cloud/safe_bet_pro_encrypted";
export const URL_SUREBET_LIVE = "https://arbitragem.vps.webdock.cloud/safe_bet_live_encrypted";