import { useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { Context } from "../../../App";
import { setUserCheckAsRead } from "../../functions/FunctionsGames";

export function Warn(props) {
    const { statusMode } = useContext(Context);

    const {show, handleClose} = props;


    const markAsRead = (event) =>{
        let statusChecked = event.target.checked;


        if (statusChecked === true) {
            setUserCheckAsRead();
        }
    }
    return (
        <>
            <Modal className={`style-modal  ${statusMode ? "style-dark-mode" : ""} overflow-hidden`} show={show} onHide={handleClose} size="sm" centered>
                <Modal.Header className={`modal-header ${statusMode ? "style-bg-dark style-border-color style-text-gray" : ""}`} closeButton>
                    <Modal.Title className="h4 fw-bold">Aviso!</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`form-check text-start ${statusMode ? "style-text-gray style-bg-dark" : ""} m-0 fw-semibold`}>

                    <div className="style-container-event">


                        <div className="">
                        Olá!<br /><br />
                        Como prometido, todos os assinantes do Plano Light receberão dias gratuitos do Plano Ao vivo!<br /><br />
                        A liberação foi feita de forma automática. Se você não recebeu, entre em contato conosco:<br /><br />
                        (11) 97853-3587<br /><br />
                        Boas apostas!
                        </div>

                    </div>



                </Modal.Body>
                <Modal.Footer className={`${statusMode ? "style-bg-dark style-border-color style-text-gray" : ""} style-container-mark-as-read`}>
                <div
                    className={`form-check ps-0 m-0`}
                >

                    <div>
                        <input
                            type="checkbox"
                            id="mark-as-read"
                            className="form-check-input ms-2"
                            onChange={markAsRead}
                        />
                    </div>

                    <label
                        title="mark-as-read"
                        htmlFor="mark-as-read"
                        className="form-check-label ms-3"
                    >
                        Marcar como lida
                    </label>
                </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}   