import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Container from "react-bootstrap/Container";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Modal } from "react-bootstrap";
import axios from "axios";

import "./PageDeCompraDoPlano.css";
import { useNavigate } from "react-router-dom";

import { loadMercadoPago } from "@mercadopago/sdk-js";
import { Payment } from "@mercadopago/sdk-react";
import { initMercadoPago } from "@mercadopago/sdk-react";
import { statusUserCoupon } from "../global/GlobalFunctions";
import StatusUpdate from "./StatusUpdate";
import { PlansSelectionMobile } from "./PlansSelectionMobile";
import { useMediaQuery } from 'react-responsive';


initMercadoPago("APP_USR-01e92b7d-7a5c-4a66-97a9-1f94dad8025a", {
  // Add your public key credential
  locale: "pt-BR",
});

await loadMercadoPago();


const Pagamentos = (props) => {
  const {
    statusMode,
    setStatusMode,
    onLogout,
    isLoggedIn,
    showLogoutButton,
    handleLoginSuccess,
    userData,
    user,
    setIdinitialization,
    setAmountinitialization,
    Idinitialization,
    DateMsg,
    setDateMsg
  } = props;

  const [cupomUtilizado, setCupomUtilizado] = useState(false);

  const navigate = useNavigate();
  const [UserID, setUserID] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [statusPayment, setStatusPayment] = useState(false);

  // Use um estado para controlar o estado do checkbox
  const [usedCupon, setUsedCupon] = useState("");
  const [showAnnual, setShowAnnual] = useState(false);
  const [showTri, setShowTri] = useState(false);
  const [showMes, setShowMes] = useState(true);
  const [activeButton, setActiveButton] = useState("Mensal");

  const [IDinitializations, setInitializations] = useState("");


  const [Priceinitialization, setPriceIdinitialization] = useState(1);
  const isTablet = useMediaQuery({ query: '(max-width: 1020px)' });

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);

    if (buttonName === "Anual") {
      togglePriceAnual();
    } else if (buttonName === "Trimestral") {
      togglePriceTri();
    } else if (buttonName === "Mensal") {
      togglePriceMensal();
    }
  };
  // Imagens
  //botão planos
  const btnmensal = `${process.env.PUBLIC_URL}/images/btn-plano-mensal.png`;
  const btntrimestral = `${process.env.PUBLIC_URL}/images/btn-plano-trimestral-resized.png`;
  const btnanuall = `${process.env.PUBLIC_URL}/images/btn-plano-anual.png`;
  //Mensal
  const imagemtest = `${process.env.PUBLIC_URL}/images/Aovivomensal.png`;

  const preeventopromensal = `${process.env.PUBLIC_URL}/images/preeventopromensal.png`;
  const preeventolightmensal = `${process.env.PUBLIC_URL}/images/preeventolightmensal.png`;
  const Profissionalmensal = `${process.env.PUBLIC_URL}/images/Profissionalmensal.png`;
  const Aovivomensal = `${process.env.PUBLIC_URL}/images/Aovivomensal.png`;

  //Trimestral
  const preeventoprotri = `${process.env.PUBLIC_URL}/images/preeventoprotri.png`;
  const preeventolighttri = `${process.env.PUBLIC_URL}/images/preeventolighttri.png`;
  const Profissionaltri = `${process.env.PUBLIC_URL}/images/Profissionaltri.png`;
  const Aovivotri = `${process.env.PUBLIC_URL}/images/Aovivotri.png`;

  //Anual

  const Profissionalanual = `${process.env.PUBLIC_URL}/images/Profissionalanual.png`;
  const preeventoproanual = `${process.env.PUBLIC_URL}/images/preeventoproanual.png`;
  const preeventolightanual = `${process.env.PUBLIC_URL}/images/preeventolightanual.png`;
  const Aovivoanual = `${process.env.PUBLIC_URL}/images/Aovivoanual.png`;

  const togglePriceMensal = () => {
    setShowMes(true);
    setShowAnnual(false);
    setShowTri(false);
  };
  const togglePriceTri = () => {
    setShowTri(true);
    setShowMes(false);
    setShowAnnual(false);
  };
  const togglePriceAnual = () => {
    setShowAnnual(true);
    setShowTri(false);
    setShowMes(false);
  };
  const handlePayment = async (planName, price) => {
    if (planName === "plano-light") {
      const preferenceData = {
        title: `Arbritagem Bet Light ${showAnnual ? "Anual" : showTri ? "Trimestral" : "Mensal"
          } `,
        description: `Sistema de Arbritagem Bet Light ${showAnnual ? "Anual" : showTri ? "Trimestral" : "Mensal"
          } para arbritagem esportivas`,
        price: showAnnual ? 999 : showTri ? 269 : 99.9,
        user: userData.email,
      };
      // Aplicar desconto se o cupom for válido
      if (cuponValido) {
        const desconto = (cuponDesconto / 100) * preferenceData.price;
        preferenceData.price -= desconto;

        // Converter para string com duas casas decimais e depois de volta para número
        preferenceData.price = parseFloat(preferenceData.price.toFixed(2));
      }

      setPriceIdinitialization(preferenceData.price);

      setDateMsg(preferenceData);



      window.localStorage.setItem("preferenceData", JSON.stringify(preferenceData));

      try {
        const response = await axios.post("https://rd.vps.webdock.cloud:9123/carts", {
          ...preferenceData,
        });

        if (response.data.erro) {
          throw new Error(response.data.erro);
        } else {

          setAmountinitialization(preferenceData.price)
          window.localStorage.setItem("Amountinitialization", JSON.stringify(preferenceData.price));
          setIdinitialization(response.data.preferenceID);
          window.localStorage.setItem("Idinitialization", JSON.stringify(response.data.preferenceID));
          localStorage.setItem('n46307526', JSON.stringify(preferenceData.title.trim()));
          localStorage.setItem('d37274784660p526', JSON.stringify(preferenceData.description.trim()));
          openPopUpPayament(response.data.preferenceID, preferenceData.price)
          //setShowModal(true);
        }
      } catch (error) {
        console.log({ error });
      }

    }
    if (planName === "plano-aovivo") {
      const preferenceData = {
        title: `Arbritagem Bet Ao Vivo ${showAnnual ? "Anual" : showTri ? "Trimestral" : "Mensal"
          } `,
        description: `Sistema de Arbritagem Bet Ao Vivo  ${showAnnual ? "Anual" : showTri ? "Trimestral" : "Mensal"
          } para arbritagem esportivas`,
        price: showAnnual ? 2987 : showTri ? 809 : 299,
        user: userData.email,
      };
      if (cuponValido) {
        const desconto = (cuponDesconto / 100) * preferenceData.price;
        preferenceData.price -= desconto;

        // Converter para string com duas casas decimais e depois de volta para número
        preferenceData.price = parseFloat(preferenceData.price.toFixed(2));
      }
      setPriceIdinitialization(preferenceData.price);

      setDateMsg(preferenceData);

      window.localStorage.setItem("preferenceData", JSON.stringify(preferenceData));

      try {
        const response = await axios.post("https://rd.vps.webdock.cloud:9123/carts", {
          ...preferenceData,
        });

        if (response.data.erro) {
          throw new Error(response.data.erro);
        } else {

          setAmountinitialization(preferenceData.price)
          window.localStorage.setItem("Amountinitialization", JSON.stringify(preferenceData.price));
          setIdinitialization(response.data.preferenceID);
          window.localStorage.setItem("Idinitialization", JSON.stringify(response.data.preferenceID));
          localStorage.setItem('n46307526', JSON.stringify(preferenceData.title.trim()));
          localStorage.setItem('d37274784660p526', JSON.stringify(preferenceData.description.trim()));
          openPopUpPayament(response.data.preferenceID, preferenceData.price)
          //setShowModal(true);
        }
      } catch (error) {
        console.log({ error });
      }

    }
    if (planName === "plano-profissional") {
      const preferenceData = {
        title: `Arbritagem Bet Profissional ${showAnnual ? "Anual" : showTri ? "Trimestral" : "Mensal"
          } `,
        description: `Sistema de Arbritagem Bet Profissional  ${showAnnual ? "Anual" : showTri ? "Trimestral" : "Mensal"
          } para arbritagem esportivas`,
        price: showAnnual ? 4979 : showTri ? 1349 : 499.0,
        user: userData.email,
      };
      if (cuponValido) {
        const desconto = (cuponDesconto / 100) * preferenceData.price;
        preferenceData.price -= desconto;

        // Converter para string com duas casas decimais e depois de volta para número
        preferenceData.price = parseFloat(preferenceData.price.toFixed(2));
      }
      setPriceIdinitialization(preferenceData.price);

      setDateMsg(preferenceData);
      window.localStorage.setItem("preferenceData", JSON.stringify(preferenceData));

      try {
        const response = await axios.post("https://rd.vps.webdock.cloud:9123/carts", {
          ...preferenceData,
        });

        if (response.data.erro) {
          throw new Error(response.data.erro);
        } else {
          setAmountinitialization(preferenceData.price)
          window.localStorage.setItem("Amountinitialization", JSON.stringify(preferenceData.price));
          setIdinitialization(response.data.preferenceID);
          window.localStorage.setItem("Idinitialization", JSON.stringify(response.data.preferenceID));
          localStorage.setItem('n46307526', JSON.stringify(preferenceData.title.trim()));
          localStorage.setItem('d37274784660p526', JSON.stringify(preferenceData.description.trim()));
          openPopUpPayament(response.data.preferenceID, preferenceData.price)
          //setShowModal(true);
        }
      } catch (error) {
        console.log({ error });
      }

    }
    if (planName === "plano-pro") {
      const preferenceData = {
        title: `Arbritagem Bet Pre evento -Pro ${showAnnual ? "Anual" : showTri ? "Trimestral" : "Mensal"
          } `,
        description: `Sistema de Arbritagem Bet Pre evento -Pro  ${showAnnual ? "Anual" : showTri ? "Trimestral" : "Mensal"
          } para arbritagem esportivas`,
        price: showAnnual ? 2987 : showTri ? 809 : 299,
        user: userData.email,
      };
      if (cuponValido) {
        const desconto = (cuponDesconto / 100) * preferenceData.price;
        preferenceData.price -= desconto;

        // Converter para string com duas casas decimais e depois de volta para número
        preferenceData.price = parseFloat(preferenceData.price.toFixed(2));
      }
      setPriceIdinitialization(preferenceData.price);

      setDateMsg(preferenceData);
      window.localStorage.setItem("preferenceData", JSON.stringify(preferenceData));

      try {
        const response = await axios.post("https://rd.vps.webdock.cloud:9123/carts", {
          ...preferenceData,
        });

        if (response.data.erro) {
          throw new Error(response.data.erro);
        } else {
          setAmountinitialization(preferenceData.price)
          window.localStorage.setItem("Amountinitialization", JSON.stringify(preferenceData.price));
          setIdinitialization(response.data.preferenceID);
          window.localStorage.setItem("Idinitialization", JSON.stringify(response.data.preferenceID));
          localStorage.setItem('n46307526', JSON.stringify(preferenceData.title.trim()));
          localStorage.setItem('d37274784660p526', JSON.stringify(preferenceData.description.trim()));
          openPopUpPayament(response.data.preferenceID, preferenceData.price)
          //setShowModal(true);
        }
      } catch (error) {
        console.log({ error });
      }

    }

  };

  const handleCloseModal = () => {
    setShowModal(false);

    setShowModal5(false);
  };
  const initialization = {
    amount: Priceinitialization,
    preferenceId: Idinitialization,
  };

  const customization = {
    paymentMethods: {
      ticket: "all",
      bankTransfer: "all",
      creditCard: "all",
      debitCard: "all",
      mercadoPago: "all",
    },
  };

  const onSubmit = async ({ selectedPaymentMethod, formData }) => {
    try {
      const IDsPreference = Idinitialization;

      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9123/process_payment",
        {
          ...formData,
        }
      );

      if (response.data.erro) {
        throw new Error(response.data.erro);
      } else {

        const { status, status_detail, id } = response.data;

        if (id != null) {


          const response = await axios.post(
            "https://rd.vps.webdock.cloud:9123/cadastrar_payment",
            {
              user: userData.email,
              idsIniciar: id,
            }
          );
          if (response.data.erro) {
            throw new Error(response.data.erro);
          } else {
            console.log(" response");
            // Chame a função fetchData para realizar a solicitação Axios

            fetchData();
          }
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const onError = async (error) => {
    // callback chamado para todos os casos de erro do Brick
    console.log(error);
  };
  const onReady = async () => {
    /*
      Callback chamado quando o Brick estiver pronto.
      Aqui você pode ocultar loadings do seu site, por exemplo.
    */
  };

  const initializations = {
    paymentId: IDinitializations, // id do pagamento a ser mostrado
  };

  const onReadys = async () => {
    /*
      Callback chamado quando o Brick estiver pronto.
      Aqui você pode ocultar loadings do seu site, por exemplo.
    */
  };
  const fechtStatus = async (UserID) => {

    try {

      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9123/get_payment",
        {
          id: UserID,
          datepayment: DateMsg,
        }
      );

      if (response.data.erro) {
        throw new Error(response.data.erro);
      } else {
        console.log("response");
      }
    } catch (error) {
      console.error("Erro na solicitação Axios:", error);
    }
  };


  const fetchData = async (e) => {
    try {
      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9123/get_dabase_userid",
        {
          user: userData.email,
        }
      );

      if (response.data.erro) {
        throw new Error(response.data.erro);
      } else {

        const preferenceID = response.data.preferenceID;

        // Atualize o objeto initializations com o preferenceID

        handleIdSuccess(preferenceID);

        // Defina o statusPayment como true para mostrar o StatusScreen
        setStatusPayment(true);
        fechtStatus(preferenceID);
      }
    } catch (error) {
      console.error("Erro na solicitação Axios:", error);
    }
  };

  const handleIdSuccess = (UserID) => {
    setUserID(UserID);

  };
  const [cupon, setCupon] = useState("");
  const [cuponValido, setCuponValido] = useState(false);
  const [mensagem, setMensagem] = useState("");
  const [canUseCoupon, setCanUseCoupon] = useState(false);
  const [cuponDesconto, setValorDesconto] = useState(100);

  const handleAdicionarCupon = async (e) => {
    e.preventDefault();

    if (cupomUtilizado) {
      setMensagem("Cupom ja foi adicionado!")
      return;
    }

    try {

      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9512/verificar_cupon",
        {
          cupon: cupon,
        }
      );

      if (response.status === 200) {
        // O cupom é válido, exibe uma mensagem de sucesso
        setCuponValido(true);
        setMensagem("Seu desconto foi aplicado! Ele será visto somente na hora do pagamento.");
        setValorDesconto(response.data.valorDesconto); // Defina o valor do desconto
        handleAdicionarCuponUsuario();
      } else if (response.status === 400) {
        // O cupom não é válido, exibe uma mensagem de erro
        setMensagem(response.data.message);
        setCuponValido(false);
      }
    } catch (error) {
      console.error("Erro ao verificar o cupom:", error);
      // Exibe uma mensagem de erro em caso de falha na solicitação
      setMensagem(
        "Opss! Parece que esse cupom expirou ou não existe."
      );
    }
  };

  const handleAdicionarCuponAdds = async () => {


    if (cupomUtilizado) {
      setMensagem("Cupom ja foi adicionado!")
      setCuponValido(false);
    }

    try {

      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9512/verificar_cupon",
        {
          cupon: userData.cuponuser,
        }
      );

      if (response.status === 200) {
        // O cupom é válido, exibe uma mensagem de sucesso
        //setCuponValido(true);
        setMensagem("Cupom adicionado para o desconto com sucesso.");
        setCuponValido(true);
        setValorDesconto(response.data.valorDesconto); // Defina o valor do desconto

      } else if (response.status === 400) {
        // O cupom não é válido, exibe uma mensagem de erro
        setMensagem(response.data.message);
        setCuponValido(false);
      }
    } catch (error) {
      console.error("Erro ao verificar o cupom:", error);
      // Exibe uma mensagem de erro em caso de falha na solicitação
      setMensagem(
        "Opss! Parece que esse cupom expirou ou não existe."
      );
    }
  };
  const handleAdicionarCuponUsuario = async () => {


    try {
      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9512/add_cupon_user",
        {
          cupon: cupon,
          idUser: userData.id,
        }
      );

      if (response.status === 200) {
        setCupomUtilizado(true);
        setMensagem("Seu desconto foi aplicado! Ele será visto somente na hora do pagamento.");
        // O cupom é válido, exibe uma mensagem de sucesso
      } else if (response.status === 400) {
        setMensagem(response.message);
        // O cupom não é válido, exibe uma mensagem de erro
      }
    } catch (error) {
      console.error("Erro ao verificar o cupom:", error);
      // Exibe uma mensagem de erro em caso de falha na solicitação
      setMensagem(
        "Opss! Parece que esse cupom expirou ou não existe."
      );
    }
  };
  useEffect(() => {

    statusUserCoupon(userData.email).then((data) => {

      setCanUseCoupon(data['status'])
    })

    if (userData.cuponuser != null) {
      setCupomUtilizado(true);
      setCuponValido(false);
      setUsedCupon(userData.cuponuser);

      if (userData.plano = "Gratuito") {
        handleAdicionarCuponAdds();

      }
    }

  }, []);

  //pop up
  async function openPopUpPayament(value, value2) {

    const url = `/payament/${value}/${value2}/${userData.email}`;
    const windowName = "popupWindowAll"; // Nome da janela pop-up

    // Verifique se a janela pop-up já existe
    if (
      window[windowName] &&
      !window[windowName].closed
    ) {
      window[windowName].location.href = url;
      return;
    } else {
      const windowFeatures = "width=800,height=750";
      window[windowName] = window.open(url, windowName, windowFeatures);
      return;
    }
  }
  return (
    <>


      {
        isTablet
          ?


          <>

<h2 className="text-light" style={{ textAlign: "center", fontSize: "1rem" }}>
                Opss! Parece que você ainda não é assinante. Conheça nossos planos
                abaixo:
              </h2>


          <div className="px-3">
            <PlansSelectionMobile
              setShowMes={setShowMes}
              setShowTri={setShowTri}
              setShowAnnual={setShowAnnual}
              handlePayment={handlePayment}
            />
          </div>
            <hr className="mx-3 text-light"/>
            {canUseCoupon ?
              <div id="title_part w-100" style={{ textAlign: "center" }}>
                <div className="title w-100">
                  <div className="title-cupon-pagamentos">
                    <p className="text-light">
                      Possui um cupom? Digite abaixo:
                    </p>
                  </div>
                  <div>
                    {cupomUtilizado ? (
                      <div className="box-pagamentos-mensagem">
                        <div className="cupom-utilizado-message mensagem-erro">
                          Você já utilizou um cupom. Não é possível adicionar outro.
                        </div>
                      </div>
                    ) : (
                      <form
                        className="login100-form validate-form cupon-page-pagamentos"
                        onSubmit={handleAdicionarCupon}
                      >
                        <input
                          className="input-cupon"
                          type="text"
                          value={cupon}
                          onChange={(e) => setCupon(e.target.value)}
                        />
                        <button className="btn-cupon" type="submit">
                          APLICAR
                        </button>
                      </form>
                    )}
                  </div>
                </div>
                <div className="mensagem-cupon">
                  {mensagem && (
                    <div
                      className={cuponValido ? "mensagem-sucesso" : "mensagem-erro"}
                    >
                      {mensagem}
                    </div>
                  )}
                </div>
              </div>
              :
              ""
            }
          </>


          :



          <Container className="container-pagamentos">
            <div>
              {" "}
              <h2 className="text-light" style={{ textAlign: "center", fontSize: "1rem" }}>
                Opss! Parece que você ainda não é assinante. Conheça nossos planos
                abaixo:
              </h2>
            </div>

            <div className="PricingCard">
              <main>


                <div id="option_part">
                  <ButtonGroup
                    aria-label="Basic example"
                    className="btn-gp-pagcompra"
                  >
                    <Button
                      variant="secondary"
                      className={`btn-pd-mg-new ${activeButton === "Anual" ? "active-btn" : ""
                        } `}
                      onClick={() => handleButtonClick("Anual")}
                    >
                      <img
                        className="btn-planos-pagecompra"
                        src={btnanuall}
                        alt="btn-anual"
                        style={{ maxWidth: "28.33%", padding: "5px" }}
                      />
                    </Button>
                    <Button
                      variant="secondary"
                      className={`btn-pd-mg-new ${activeButton === "Trimestral" ? "active-btn" : ""
                        } `}
                      onClick={() => handleButtonClick("Trimestral")}
                    >
                      <img
                        className="btn-planos-pagecompra"
                        src={btntrimestral}
                        alt="btn-trimestral"
                      />
                    </Button>
                    <Button
                      variant="secondary"
                      className={`btn-pd-mg-new ${activeButton === "Mensal" ? "active-btn" : ""
                        } `}
                      onClick={() => handleButtonClick("Mensal")}
                    >
                      <img
                        className="btn-planos-pagecompra"
                        src={btnmensal}
                        alt="btn-mensal"
                        style={{ maxWidth: "28.33%", padding: "5px" }}
                      />
                    </Button>
                  </ButtonGroup>
                </div>

                <div id="card_part">
                  <div className="card card_1 cardprofissional">
                    <img
                      src={
                        showAnnual
                          ? Profissionalanual
                          : showTri
                            ? Profissionaltri
                            : Profissionalmensal
                      }
                      alt="Sua Imagem"
                    />{" "}
                    <button
                      className="botao btnpagecompra"
                      onClick={() => {
                        handlePayment("plano-profissional");
                      }}
                    >
                      QUERO ESSE PLANO
                    </button>
                  </div>
                  <div className="card card_3 cardpro">
                    <img
                      src={
                        showAnnual
                          ? preeventoproanual
                          : showTri
                            ? preeventoprotri
                            : preeventopromensal
                      }
                      alt="Sua Imagem"
                    />{" "}
                    <button
                      className="botao btnpagecompra"
                      onClick={() => {
                        handlePayment("plano-pro");
                      }}
                    >
                      QUERO ESSE PLANO
                    </button>
                  </div>
                  <div className="card card_2 cardaovivo">
                    <img
                      src={
                        showAnnual
                          ? Aovivoanual
                          : showTri
                            ? Aovivotri
                            : Aovivomensal
                      }
                      alt="Sua Imagem"
                    />{" "}
                    <button
                      className="botao btnpagecompra"
                      onClick={() => {
                        handlePayment("plano-aovivo");
                      }}
                    >
                      QUERO ESSE PLANO
                    </button>
                  </div>

                  <div className="card card_4 cardlight">
                    <img
                      src={
                        showAnnual
                          ? preeventolightanual
                          : showTri
                            ? preeventolighttri
                            : preeventolightmensal
                      }
                      alt="Sua Imagem"
                    />{" "}
                    <button
                      className="botao btnpagecompra"
                      onClick={() => {
                        handlePayment("plano-light");
                      }}
                    >
                      QUERO ESSE PLANO
                    </button>
                  </div>
                </div>
                <br></br>
                <hr />
                {
                  canUseCoupon
                    ?
                    <div id="title_part">
                      <div className="title">
                        <div className="title-cupon-pagamentos">
                          <p className="text-light">
                            Possui um cupom? Digite abaixo:
                          </p>
                        </div>
                        <div>
                          {cupomUtilizado ? (
                            <div className="box-pagamentos-mensagem">
                              <div className="cupom-utilizado-message mensagem-erro">
                                Você já utilizou um cupom. Não é possível adicionar outro.
                              </div>
                            </div>
                          ) : (
                            <form
                              className="login100-form validate-form cupon-page-pagamentos"
                              onSubmit={handleAdicionarCupon}
                            >
                              <input
                                className="input-cupon"
                                type="text"
                                value={cupon}
                                onChange={(e) => setCupon(e.target.value)}
                              />
                              <button className="btn-cupon" type="submit">
                                APLICAR
                              </button>
                            </form>
                          )}
                        </div>
                      </div>
                      <div className="mensagem-cupon">
                        {mensagem && (
                          <div
                            className={cuponValido ? "mensagem-sucesso" : "mensagem-erro"}
                          >
                            {mensagem}
                          </div>
                        )}
                      </div>
                    </div>
                    :
                    ""
                }
              </main>
            </div>
            <br></br>

            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Detalhes do Pagamento </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {statusPayment ? (
                  <>
                    <StatusUpdate preferenceID={UserID} />
                  </>
                ) : (
                  <>
                    <Payment
                      initialization={initialization}
                      customization={customization}
                      onSubmit={onSubmit}
                      onReady={onReady}
                      onError={onError}
                    />
                  </>
                )}
              </Modal.Body>
            </Modal>


          </Container>
      }
    </>
  );
};

export default Pagamentos;
