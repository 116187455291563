import { useRef, useState, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import IconPlus from "../../../assets/svg/icon_plus.svg";
import { Toaster, toast } from 'sonner'
import { Context } from "../../../App";
import { saveWordOnBlackListWords } from "../../functions/FunctionsFilter";
import { SaveFilterArbitragem } from "../../functions/FunctionsFilter";
export function EventDesktop(props) {
    const { blacklistWords, setBlacklistWords } = useContext(Context);


    const { handleClose, show, statusMode, formatDataFilterToSave } = props;
    const inputkeywordMarket = useRef(null);
    const [keywordsAdded, setKeywordsAdded] = useState(blacklistWords);

    function eraseTextInput() {
        if (inputkeywordMarket.current) {
            inputkeywordMarket.current.value = "";
        }
    }

    function saveKeyword() {
        let resultReturn = { "status": false };
        if (inputkeywordMarket.current) {
            let valueWrited = inputkeywordMarket.current.value.trim();
            
            if(keywordsAdded.length >= 100){
                toast.error('Limite de 100 palavras-chave atingido! Remova algumas para continuar.')
            }
            else if (valueWrited.length === 0) {
                toast.warning('Campo vazio! Digite pelo menos um caractere para continuar.')

            }
            else if (valueWrited.length > 100) {
                toast.error('Você extrapolou o limite de 100 caracteres! Apague ou reduza o texto para continuar.')
            }
            else if (keywordsAdded.includes(valueWrited) === true) {
                toast.info('Ops! Essa palavra-chave já foi adicionada. Escolha uma diferente.')
            }
            else {
                setKeywordsAdded([...keywordsAdded, valueWrited]);
                resultReturn["status"] = true;
            }
        }

        return resultReturn;
    }

    function startFunctionsButtonAdd() {
        let hasSaveKeyword = saveKeyword();
        if (hasSaveKeyword["status"] === true) {
            eraseTextInput();
        }
    }

    const saveAndApplyFilter = () => {
        saveWordOnBlackListWords(keywordsAdded, setBlacklistWords);

        let dataFilterToUpdate = formatDataFilterToSave(
            { blacklistWordsSurebet: keywordsAdded, }
        );
        SaveFilterArbitragem(dataFilterToUpdate);
    }
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            startFunctionsButtonAdd();
        }
    }

    const deleteKeywod = (keyword) => {
        setKeywordsAdded(keywordsAdded.filter(item => item !== keyword));
    }


    return (
        <>

            <Modal className={`filter-modal  ${statusMode ? "filter-dark-mode" : ""}`} show={show} onHide={handleClose}>
                <Toaster closeButton />
                <Modal.Header className={`modal-header ${statusMode ? "filter-bg-dark filter-border-color filter-text-gray" : ""}`} closeButton>
                    <Modal.Title className="h4">Excluir eventos</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`form-check text-start ${statusMode ? "filter-text-gray filter-bg-dark" : ""}`}>

                    <div className="filter-container-event">

                        <div className="filter-inside-event-functionality">

                            <div className="d-flex">
                                <div className="    w-100">
                                    <input
                                        ref={inputkeywordMarket}
                                        type="text"
                                        className="form-control filter-input-keyword-surebet"
                                        placeholder="Insira aqui as palavras-chave para filtrar..."
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>

                                <div className="ms-2">
                                    <button type="submit" className="btn btn-danger mb-3 filter-button-set-keyword-surebet" onClick={() => { startFunctionsButtonAdd(); }}>

                                        <img className="img-fluid" src={IconPlus} alt="" />
                                    </button>
                                </div>
                            </div>


                            <div className="filter-container-keywords-saved">

                                <div className="filter-div-keywords-saved">

                                    {
                                        keywordsAdded.map((item, index) => {



                                            return (
                                                <div className="bg-primary filter-min-content text-light m-1 py-1 px-2 rounded-pill" key={index}>


                                                    <div className="filter-white-space-btn-remove-keyword">
                                                        <div className="filter-container-event-btn-close" onClick={() => { deleteKeywod(item); }}>

                                                            <div className="filter-event-btn-close">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <span className="text-break">
                                                        {item}
                                                    </span>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>



                </Modal.Body>
                <Modal.Footer className={`${statusMode ? "filter-bg-dark filter-border-color filter-text-gray" : ""}`}>

                    <button className='btn btn-primary' onClick={() => { handleClose(); saveAndApplyFilter(); }}>
                        Aplicar e filtrar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}   