import { CommonCompenentDataMarket, CompenentDataMarketFirstSecondExtraTime, CompenentDataMarketDefault, CompenentDataMarketDynamic } from "./GlobalComponents"
import { createListBetHomeDemarkOnFilter } from "./GlobalFunctions";

export const orderLoop = ["first_home_bet", "second_home_bet", "third_home_bet", "fourth_home_bet"];
export const linkChangePlan = '/renovacao';
export const linkWhatsapp = "https://wa.me/message/76CEMC5GZUWPA1";
export const listSortType = ['Lucro', 'Início', 'Recentes'];
export const listSortTypeToSelect = { 'início': 'Início', 'recentes': 'Recentes' };

export const NameBetHomeRenamed = {
  "betano(br)": "Betano",
  "vbet(lat)": "Vbet",
  "bodog(en)": "Bodog",
}

export const managerClonerBetHome = {
  'allBetHomeAbleToClone': [
    "sportingbet",
    "betnacional",
    "estrelabet",
    "bolsadeaposta",
    "pinnacle",
    'vaidebet',
    'vbet (lat)',
    'betfast',
    /*"kto",*/
    "f12bet",
    "bet7k",
    "stake",
    "blaze",
    "luvabet",
    "brbet"
  ],

  'sportingbet': {
    'allBetHomeClone': ['betboo'],
    'betboo': {
      'baseURL': 'https://sports.br.betboo.com',
      'nameBetHome': 'Betboo',
    }
  },
  'betnacional': {
    'allBetHomeClone': ['mrjack'],
    'mrjack': {
      'baseURL': 'https://mrjack.bet',
      'nameBetHome': 'MrJack',
    },
  },
  'estrelabet': {
    'allBetHomeClone': [ 'betaki', 'jogodeouro'],
    "betaki": { "baseURL": "https://betaki.bet.br", "nameBetHome": "Betaki" },
    "jogodeouro": { "baseURL": "https://jogodeouro.bet.br", "nameBetHome": "Jogodeouro" }
  },
  'bolsadeaposta': {
    'allBetHomeClone': ['bet-bra', 'fulltbet'],
    'bet-bra': {
      'baseURL': 'https://bet-bra.com',
      'nameBetHome': 'Bet-Bra',
    },
    'fulltbet': {
      'baseURL': 'https://fulltbet.com',
      'nameBetHome': 'FulltBet',
    },

  },

  'pinnacle': {
    'allBetHomeClone': ['bolsadeaposta sb', 'bet-bra sb', 'fulltbet sb'],
    'bolsadeaposta sb': {
      'baseURL': 'https://sports.bolsadeaposta.com',
      'nameBetHome': 'BolsaDeAposta SB',
    },
    'bet-bra sb': {
      'baseURL': 'https://sports2.bet-bra.com',
      'nameBetHome': 'Bet-Bra SB',
    },
    'fulltbet sb': {
      'baseURL': 'https://sports.fulltbet.com',
      'nameBetHome': 'FulltBet SB',
    },
  },

  'vaidebet': {
    'allBetHomeClone': ['betpix365', 'esportesdasorte'],
    'betpix365': {
      'baseURL': 'https://betpix365.com',
      'nameBetHome': 'BetPix365',
    },
    'esportesdasorte': {
      'baseURL': 'https://www.esportesdasorte.com',
      'nameBetHome': 'EsportesDaSorte',
    },
  },
  'vbet (lat)': {
    'allBetHomeClone': ['segurobet', '7games', 'seubet',
      'betão', 'r7bet', "xpbet", "bravobet", "oleybet", "betpark", "b2xbet", "h2bet",
      "supremabet", "maximabet"
    ],
    'segurobet': {
      'baseURL': 'https://www.seguro.bet.br/pre-jogo/match',
      'nameBetHome': 'Segurobet',
    },
    '7games': {
      'baseURL': 'https://7games.bet.br/pb/sports/pre-match/event-view',
      'nameBetHome': '7games',
    },
    'seubet': {
      'baseURL': 'https://www.seu.bet.br/pre-jogo/match',
      'nameBetHome': 'Seubet',
    },
    'betão': {
      'baseURL': 'https://betao.bet.br/pb/sports/pre-match/event-view',
      'nameBetHome': 'Betão',
    },
    'r7bet': {
      'baseURL': 'https://r7.bet.br',
      'nameBetHome': 'R7Bet',
    },
    'xpbet': {
      'baseURL': 'https://xp.bet.br',
      'nameBetHome': "XpBet",
    },
    'bravobet': {
      'baseURL': 'https://www.bravo.bet.br/esportes/match/topLeague',
      'nameBetHome': "BravoBet",
    },
    'oleybet': {
      'baseURL': 'https://www.oleybet.bet.br',
      'nameBetHome': "Oleybet",
    },
    'betpark': {
      'baseURL': 'https://www.betpark.bet.br',
      'nameBetHome': "BetPark",
    },
    'b2xbet': {
      'baseURL': 'https://www.b2x.bet.br',
      'nameBetHome': "B2XBet",
    },
    'h2bet': {
      'baseURL': 'https://www.h2.bet.br/esportes/match',
      'nameBetHome': "H2Bet",
    },
    'supremabet': {
      'baseURL': 'https://suprema.bet.br',
      'nameBetHome': "SupremaBet",
    },
    'maximabet': {
      'baseURL': 'https://www.maxima.bet.br/esportes/match/topLeague',
      'nameBetHome': "MaximaBet",
    }
  },
  'betfast': {
    'allBetHomeClone': ['faz1bet', 'tivobet', "aposta1"],
    'faz1bet': {
      'baseURL': 'https://faz1bet.com/br#/',
      'nameBetHome': 'Faz1bet',
    },
    'tivobet': {
      'baseURL': 'https://tivo.bet/br/sportsbook/prematch#/',
      'nameBetHome': 'Tivobet',
    },
    "aposta1": { "baseURL": "https://www.aposta1.bet.br/esportes/", "nameBetHome": "Aposta1" },
  },
  /*'kto': {
    'allBetHomeClone': ['estrelabet'],
    'estrelabet': {
      'baseURL': 'https://estrelabet.com/pb/esportes#/sport/145/category/1533/championship/12345/event',
      'nameBetHome': 'EstrelaBet',
    },
  },*/
  'f12bet': {
    'allBetHomeClone': ['fazobetai'],
    'fazobetai': {
      'baseURL': 'https://fazobetai.bet.br',
      'nameBetHome': 'FazoBetAi',
    },
  },
  'bet7k': {
    'allBetHomeClone': [
      "4play",
      "apostamax",
      "apostatudo",
      "bateubet",
      "betfusion",
      "betgorillas",
      "brx",
      "bullsbet",
      "cassino",
      "donald",
      "esportiva",
      "multi",
      "pagol",
      "pixbet",
      "sortenabet",
      "vera"
  ],

    "4play": { "baseURL": "https://4play.bet.br", "nameBetHome": "4Play" },
    "pagol": { "baseURL": "https://pagol.bet.br", "nameBetHome": "Pagol" },
    "apostatudo": { "baseURL": "https://apostatudo.bet.br", "nameBetHome": "Apostatudo" },
    "betgorillas": { "baseURL": "https://betgorillas.bet.br", "nameBetHome": "Betgorillas" },
    "esportiva": { "baseURL": "https://esportiva.bet.br", "nameBetHome": "Esportiva" },
    "sortenabet": { "baseURL": "https://sortenabet.bet.br", "nameBetHome": "Sortenabet" },
    "betfusion": { "baseURL": "https://betfusion.bet.br", "nameBetHome": "Betfusion" },
    "vera": { "baseURL": "https://vera.bet.br", "nameBetHome": "Vera" },
    "cassino": { "baseURL": "https://cassino.bet.br", "nameBetHome": "Cassino" },
    "apostamax": { "baseURL": "https://apostamax.bet.br", "nameBetHome": "Apostamax" },
    "multi": { "baseURL": "https://multi.bet.br", "nameBetHome": "Multi" },
    "brx": { "baseURL": "https://brx.bet.br", "nameBetHome": "Brx" },
    "bullsbet": { "baseURL": "https://bullsbet.bet.br", "nameBetHome": "Bullsbet" },
    "donald": { "baseURL": "https://donald.bet.br", "nameBetHome": "Donald" },
    "bateubet": { "baseURL": "https://bateu.bet.br", "nameBetHome": "BateuBet" },
    "pixbet": { "baseURL": "https://prod20383.fssb.io", "nameBetHome": "Pixbet" },
    "b1bet": { "baseURL": "https://www.b1bet.bet.br", "nameBetHome": "B1Bet" },
  },
  'stake': {
    'allBetHomeClone': ['kto', 'betwarrior'],
    'kto': {
      'baseURL': 'https://www.kto.bet.br',
      'nameBetHome': 'KTO',
    },
    'betwarrior': {
      'baseURL': 'https://apostas.betwarrior.bet.br/pt-br/sports/event',
      'nameBetHome': 'Betwarrior',
    }
  },
  "blaze": {
    'allBetHomeClone': ['jonbet', 'ganhabet', 'flabet'],
    'jonbet': { 'baseURL': 'https://jonbet.bet.br', 'nameBetHome': 'Jonbet', },
    'ganhabet': { 'baseURL': 'https://ganhabet.com', 'nameBetHome': 'GanhaBet', },
    'flabet': { 'baseURL': 'https://www.fla.bet.br', 'nameBetHome': 'Flabet', },
  },
  "luvabet": {
    'allBetHomeClone': ['reals', 'lider', 'geralbet'],
    "reals": { "baseURL": "https://reals.bet.br", "nameBetHome": "Reals" },
    "lider": { "baseURL": "https://lider.bet.br", "nameBetHome": "Lider" },
    "geralbet": { "baseURL": "https://geralbet.bet.br", "nameBetHome": "Geralbet" }
  },
  "brbet": {
    'allBetHomeClone': ["apostoubet", "b1bet", "brasildasorte", "uxbet"],
    "apostoubet": { "baseURL": "https://www.apostou.bet.br", "nameBetHome": "Apostoubet" },
    "uxbet": { "baseURL": "https://www.ux.bet.br", "nameBetHome": "UXBet" },
    "brasildasorte": { "baseURL": "https://www.brasildasorte.bet.br", "nameBetHome": "Brasildasorte" },
    "b1bet": { "baseURL": "https://www.b1bet.bet.br", "nameBetHome": "B1Bet" },
  },
}


export const listCloneBetHomeModeLive = {
  'vbet (lat)': {
    'segurobet': {
      'baseURL': 'https://www.seguro.bet.br/ao-vivo/match',
      'nameBetHome': 'Segurobet',
    },
    '7games': {
      'baseURL': 'https://7games.bet.br/pb/sports/live/event-view',
      'nameBetHome': '7games',
    },
    'seubet': {
      'baseURL': 'https://www.seu.bet.br/ao-vivo/match',
      'nameBetHome': 'Seubet',
    },
    'r7bet': {
      'baseURL': 'https://r7.bet.br',
      'nameBetHome': 'R7Bet',
    },
    'oleybet': {
      'baseURL': 'https://www.oleybet.bet.br',
      'nameBetHome': "Oleybet",
    },
    'betpark': {
      'baseURL': 'https://www.betpark.bet.br',
      'nameBetHome': "BetPark",
    },
    'b2xbet': {
      'baseURL': 'https://www.b2x.bet.br',
      'nameBetHome': "B2XBet",
    },
    'supremabet': {
      'baseURL': 'https://suprema.bet.br',
      'nameBetHome': "SupremaBet",
    },
    'xpbet': {
      'baseURL': 'https://xp.bet.br',
      'nameBetHome': "XpBet",
    },

    'betão': {
      'baseURL': 'https://betao.bet.br/pb/sports/live/event-view',
      'nameBetHome': 'Betão',
    },
    'h2bet': {
      'baseURL': 'https://www.h2.bet.br/ao-vivo/match',
      'nameBetHome': "H2Bet",
    },
    'bravobet': {
      'baseURL': 'https://www.bravo.bet.br/ao-vivo/match',
      'nameBetHome': "BravoBet",
    },
    'maximabet': {
      'baseURL': 'https://www.maxima.bet.br/ao-vivo/match',
      'nameBetHome': "MaximaBet",
    }
  },
  /*'kto': {
    'estrelabet': {
      'baseURL': 'https://estrelabet.com/pb/esportes#/sport/146/category/1728/championship/12345/liveEvent',
      'nameBetHome': 'EstrelaBet',
    },
  },*/
}






export const listBetHomeDemarkOnFilter = createListBetHomeDemarkOnFilter( JSON.stringify(managerClonerBetHome) );


export const styleDataMarket = {

  'common_data_market': {
    'componentFunction': function (infoDataMarket, description) {

      return (
        <CommonCompenentDataMarket
          infoDataMarket={infoDataMarket}
          description={description}
        />
      )
    }
  },
  'first_second_extra_time': {
    'componentFunction': function (infoDataMarket, description) {

      return (
        <CompenentDataMarketFirstSecondExtraTime
          infoDataMarket={infoDataMarket}
          description={description}
        />
      )
    }
  },

  'default_data_market': {
    'componentFunction': function (infoDataMarket, description) {

      return (
        <CompenentDataMarketDefault
          infoDataMarket={infoDataMarket}
          description={description}
        />
      )
    },
  },

  'dynamic_market': {
    'componentFunction': function (infoDataMarket, description) {

      return (
        <CompenentDataMarketDynamic
          infoDataMarket={infoDataMarket}
          description={description}
        />
      )
    },
  }
}


export const timeSurebetAllowed = 900000;

//------📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱CALCULATOR📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱📱

export const partInfoOddSurebetJSON = '{"percent":0,"typeBet":"normal"}';


export const modelPopUpSettings = {
  'popupWindowAll': {
    'width': 800,
    'height': 460,
    'top': 0,
    'left': 0,
  },
  'popupWindowAll2': {
    'width': 800,
    'height': 460,
    'top': 0,
    'left': 0,
  },
}


export const bom_dia = "open__Pop_Up__Calculator";