import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { useState } from "react";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../assets/css/PlansSelectionMobile.css';

const preeventolightmensal = `${process.env.PUBLIC_URL}/images/preeventolightmensal.png`;
const preeventolighttri = `${process.env.PUBLIC_URL}/images/preeventolighttri.png`;
const preeventolightanual = `${process.env.PUBLIC_URL}/images/preeventolightanual.png`;
const preeventopromensal = `${process.env.PUBLIC_URL}/images/preeventopromensal.png`;
const preeventoprotri = `${process.env.PUBLIC_URL}/images/preeventoprotri.png`;
const preeventoproanual = `${process.env.PUBLIC_URL}/images/preeventoproanual.png`;
const Profissionalmensal = `${process.env.PUBLIC_URL}/images/Profissionalmensal.png`;
const Profissionaltri = `${process.env.PUBLIC_URL}/images/Profissionaltri.png`;
const Profissionalanual = `${process.env.PUBLIC_URL}/images/Profissionalanual.png`;
const Aovivomensal = `${process.env.PUBLIC_URL}/images/Aovivomensal.png`;
const Aovivotri = `${process.env.PUBLIC_URL}/images/Aovivotri.png`;
const Aovivoanual = `${process.env.PUBLIC_URL}/images/Aovivoanual.png`;



const dataPlans = [
  { imageAddres: preeventolightmensal, bgColor: 'linear-gradient(180deg,#00554a,#018b73)', time: "monthly", namePlan: "plano-light"},
  { imageAddres: Aovivomensal, bgColor: 'linear-gradient(180deg,#322456,#574793)', time: "monthly", namePlan: "plano-aovivo"},
  { imageAddres: preeventopromensal, bgColor: 'linear-gradient(180deg,#174157,#01848f)', time: "monthly", namePlan: "plano-pro"},
  { imageAddres: Profissionalmensal, bgColor: 'linear-gradient(180deg,#025700,#008e00)', time: "monthly", namePlan: "plano-profissional"},

  { imageAddres: preeventolighttri, bgColor: 'linear-gradient(180deg,#00554a,#018b73)', time: "quarterly", namePlan: "plano-light"},
  { imageAddres: Aovivotri, bgColor: 'linear-gradient(180deg,#322456,#574793)', time: "quarterly", namePlan: "plano-aovivo"},
  { imageAddres: preeventoprotri, bgColor: 'linear-gradient(180deg,#174157,#01848f)', time: "quarterly", namePlan: "plano-pro"},
  { imageAddres: Profissionaltri, bgColor: 'linear-gradient(180deg,#025700,#008e00)', time: "quarterly", namePlan: "plano-profissional"},

  { imageAddres: preeventolightanual, bgColor: 'linear-gradient(180deg,#00554a,#018b73)', time: "annual", namePlan: "plano-light"},
  { imageAddres: Aovivoanual, bgColor: 'linear-gradient(180deg,#322456,#574793)', time: "annual", namePlan: "plano-aovivo"},
  { imageAddres: preeventoproanual, bgColor: 'linear-gradient(180deg,#174157,#01848f)', time: "annual", namePlan: "plano-pro"},
  { imageAddres: Profissionalanual, bgColor: 'linear-gradient(180deg,#025700,#008e00)', time: "annual", namePlan: "plano-profissional"},
];


const dataPlansTime = {
  "monthly": {"name": "Mensal", "discount": 0},
  "quarterly": {"name": "Trimestral", "discount": 10},
  "annual": {"name": "Anual", "discount": 20}
}

export function PlansSelectionMobile(props) {

  const {
    setShowMes,
    setShowTri,
    setShowAnnual,
    handlePayment,
  } = props;
  const [timePlan, setTimePlan] = useState('monthly');




  function changeTimePlan(actualTimePlan){
    if(actualTimePlan === "monthly"){
      setShowTri(false);
      setShowAnnual(false);
      setShowMes(true);
    }
    if(actualTimePlan === "quarterly"){
      setShowMes(false);
      setShowAnnual(false);
      setShowTri(true);
    }
    if(actualTimePlan === "annual"){
      setShowMes(false);
      setShowTri(false);
      setShowAnnual(true);
    }
  }

  function openCheckout(namePlan){
    handlePayment(namePlan)
  }

  return (
    <>

      <div className='container-time'>
        <span id="time-plan">{dataPlansTime[timePlan]["name"]}   </span>{dataPlansTime[timePlan]["discount"] > 0 ?<span className='letter-discount'>-{dataPlansTime[timePlan]["discount"]}%</span> : ""} </div>


      <Swiper
        className="tranding-slider"
        modules={[EffectCoverflow, Pagination, Navigation]}
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}

        onSlideChange={(swiper) => {
          let indexSlide = swiper.realIndex;
          

          if(timePlan !== dataPlans[indexSlide]["time"]){
            setTimePlan(dataPlans[indexSlide]["time"]);
            changeTimePlan(dataPlans[indexSlide]["time"]);
          }
        }}
      >

        {
          dataPlans.map((item, index) => {
            return (
              <SwiperSlide className="container-slider" style={{ background: `${item.bgColor}` }} key={index}>
                <div className='w-h-100 container-plan' style={{ backgroundImage: `url(${item.imageAddres})` }}>

                  <button onClick={() => { openCheckout(item.namePlan) }} className='btn-buy'>
                    QUERO ESSE PLANO
                  </button>
                </div>
              </SwiperSlide>
            );
          })
        }



        {/* Pagination and navigation elements */}
        <div className="swiper-pagination" />
        <div className="swiper-button-next" />
        <div className="swiper-button-prev" />
      </Swiper>
    </>
  );
}