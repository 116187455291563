import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";

import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { format,  addDays } from "date-fns";

import "../assets/css/ConfigPage.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Importe o estilo da biblioteca
import DataTable from "datatables.net-dt";

// Defina um objeto para armazenar a tabela
const tabelaModalidades = {
  Light: {
    Mensal: 0,
    Trimestral: 0,
    Anual: 0,
  },
  Pro: {
    Mensal: 0,
    Trimestral: 0,
    Anual: 0,
  },
  "Ao Vivo": {
    Mensal: 0,
    Trimestral: 0,
    Anual: 0,
  },
  Profissional: {
    Mensal: 0,
    Trimestral: 0,
    Anual: 0,
  },
};
const DashboardControl = (props) => {
  const { statusMode, setStatusMode, isLoggedIn } = props;
  const [key, setKey] = useState("home");
  const [users, setUsers] = useState([]);
  const [cupomName, setCupomName] = useState("");
  const [cupomValue, setCupomValue] = useState("");
  const [cupomCreated, setCupomCreated] = useState(false);
  const [cupons, setCupons] = useState([]);
  const [cupomCreationDate, setCupomCreationDate] = useState("");
  const [cupomValidity, setCupomValidity] = useState("");
  const [activeTab, setActiveTab] = useState("status");
  const [periodos, setPeriodos] = useState([]);
  const [modalidades, setModalidades] = useState([]);
  const [userCounts, setuserCounts] = useState({});
  const [totalUsers, setTotalUsers] = useState(0);
  const [statusconta, setStatusCOnta] = useState("");
  
  
  const [tabelaModalidadess, setTabelaModalidadess] = useState({
    Light: {
      Mensal: 0,
      Trimestral: 0,
      Anual: 0,
    },
    Pro: {
      Mensal: 0,
      Trimestral: 0,
      Anual: 0,
    },
    "Ao Vivo": {
      Mensal: 0,
      Trimestral: 0,
      Anual: 0,
    },
    Profissional: {
      Mensal: 0,
      Trimestral: 0,
      Anual: 0,
    },
  });
  const [tabelaModalidades, setTabelaModalidades] = useState({
    Light: {
      Mensal: 0,
      Trimestral: 0,
      Anual: 0,
    },
    Pro: {
      Mensal: 0,
      Trimestral: 0,
      Anual: 0,
    },
    "Ao Vivo": {
      Mensal: 0,
      Trimestral: 0,
      Anual: 0,
    },
    Profissional: {
      Mensal: 0,
      Trimestral: 0,
      Anual: 0,
    },
  });
  const [newUser, setNewUser] = useState({
    nome: "",
    email: "",
    senha: "",
    plano: "",
    tipo_de_plano: "",
    status_da_conta: "",
    phone: "",
  });
  // Função para mudar a aba ativa
  const handleSetActiveTab = (tab) => {
    setActiveTab(tab);
  };
  const changeStatusMode = (oldStatusMode) => {
    setStatusMode(!oldStatusMode);

    window.localStorage.setItem("statusMode", JSON.stringify(!oldStatusMode));
    const dataToJson = JSON.stringify(!oldStatusMode);
    localStorage.setItem("statusMode", dataToJson);
    document.body.style.backgroundColor = oldStatusMode ? "" : "#0d0d0d";
  };

  // Funções simplificadas para carregar usuários e cupons
  const loadData = async (url, setState) => {
    try {
      const response = await axios.get(url);
      setState(response.data);
    } catch (error) {
      console.error("Erro ao carregar dados:", error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      await axios.post(`https://rd.vps.webdock.cloud:9512/users/${userId}`);
      loadData("https://rd.vps.webdock.cloud:9512/users", setUsers);
    } catch (error) {
      console.error("Erro ao excluir usuário:", error);
    }
  };

  const deleteCupon = (userId) => {
    axios.delete(`https://rd.vps.webdock.cloud:9512/cupon/${userId}`).then(() => {
      loadData("https://rd.vps.webdock.cloud:9512/cupons", setCupons);
    });
  };

  const updateUserCategory = (userId, newCategory) => {
    axios
      .put(`"https://rd.vps.webdock.cloud:9512/users/${userId}`, {
        tipo_de_plano: newCategory,
      })
      .then(() => {
        loadData("https://rd.vps.webdock.cloud:9512/users", setUsers);
      });
  };

  const createUser = () => {
    axios.post("https://rd.vps.webdock.cloud:9512/users", newUser).then(() => {
      loadData("https://rd.vps.webdock.cloud:9512/users", setUsers);
    });
  };
  const loadDados = () => {
    const updatedUsers = users.map((user) => {
      let status_Conta = user.status_Conta;

      if (user.status_da_conta === "Ativo") {
        status_Conta = "oficial";
      } else if (user.status_da_conta === "FourHours") {
        status_Conta = "fourhours";
      } else if (user.status_da_conta === "Inativo") {
        status_Conta = "inativar";
      }

      return {
        ...user,
        status_Conta: status_Conta,
      };
    });
    return updatedUsers;
  };
  const createCupom = async (e) => {
    e.preventDefault();
    if (!cupomName || !cupomValue || !cupomCreationDate || !cupomValidity) {
      alert("Por favor, preencha todos os campos do cupom.");
      return;
    }

    axios
      .post("https://rd.vps.webdock.cloud:9512/gerarcupom", {
        nome: cupomName,
        valor_desconto: cupomValue,
        data_criacao: cupomCreationDate,
        validade: cupomValidity,
      })
      .then((response) => {
        setCupomCreated(true);
        setCupomName("");
        setCupomValue("");
        setCupomCreationDate("");
        setCupomValidity("");
        loadData("https://rd.vps.webdock.cloud:9512/cupons", setCupons);
      })
      .catch((error) => {
        console.error("Erro ao criar o cupom:", error);
      });
  };
  useEffect(() => {
    loadData("https://rd.vps.webdock.cloud:9512/users", setUsers);
    loadData("https://rd.vps.webdock.cloud:9512/cupons", setCupons);
  }, []);
  
  useEffect(() => {
    const FectDataCInterval = setInterval(() => {
      let table = new DataTable("#myTable");
    }, 1000);
    return () => clearInterval(FectDataCInterval);
  }, []);
  

  // Atualizar contagens de usuários e total
  useEffect(() => {
    const FectDataCInterval = setInterval(() => {
      countUsersOverTime(users);
    }, 1000);

    const countUsers = () => {
      let counts = Array(12).fill(0);
      users.forEach((user) => {
        const month = new Date(user.data_cadastro).getMonth();
        counts[month]++;
      });
      return counts;
    };

    const userCounts = countUsers();
    setuserCounts(userCounts);
    setTotalUsers(userCounts.reduce((total, count) => total + count, 0));
    return () => clearInterval(FectDataCInterval);
  }, [users]); // Se totalUsers não é alterado fora deste useEffect, a dependência pode ser um array vazio

  // Função para extrair a duração do plano com base na descrição
  const extrairDuracaoDoPlano = (descricaoPlano) => {
    if (descricaoPlano.toLowerCase().includes("mensal")) {
      return 30; // Se a descrição incluir "mensal", assume uma duração de 30 dias
    } else if (descricaoPlano.toLowerCase().includes("trimestral")) {
      return 90; // Se a descrição incluir "trimestral", assume uma duração de 90 dias
    } else if (descricaoPlano.toLowerCase().includes("anual")) {
      return 365; // Se a descrição incluir "anual", assume uma duração de 365 dias
    } else {
      return 0; // Se não encontrar uma correspondência, assume 0 dias (plano indefinido)
    }
  };

  // Função para contar o número de usuários ao longo do tempo
  const countUsersOverTime = (users) => {
    const userCountsResult = Array(12).fill(0);
    const tabelaModalidadesResult = {
      Light: { Mensal: 0, Trimestral: 0, Anual: 0 },
      Pro: { Mensal: 0, Trimestral: 0, Anual: 0 },
      "Ao Vivo": { Mensal: 0, Trimestral: 0, Anual: 0 },
      Profissional: { Mensal: 0, Trimestral: 0, Anual: 0 },
    };
    const tabelaModalidadesResults = {
      Light: {
        mensallight: 0,
        trimestrallight: 0,
        anuallight: 0,
      },
      Pro: {
        mensalpro: 0,
        trimestralpro: 0,
        anualpro: 0,
      },
      "Ao Vivo": {
        mensalaovivo: 0,
        trimestralaovivo: 0,
        anualaovivo: 0,
      },
      Profissional: {
        mensalprofissional: 0,
        trimestralprofissional: 0,
        anualprofissional: 0,
      },
    };
    const mapeamentoPlanos = {
      "Arbritagem Bet Light Mensal ": "mensallight",
      "Arbritagem Bet Light Mensal": "mensallight",
      "Arbritagem Bet Pro Mensal": "mensalpro",
      "Arbritagem Bet Ao vivo Mensal": "mensalaovivo",
      "Arbritagem Bet Profissional Mensal": "mensalprofissional",

      "Arbritagem Bet Light Trimestral": "trimestrallight",
      "Arbritagem Bet Pro Trimestral": "trimestralpro",
      "Arbritagem Bet Ao vivo Trimestral": "trimestralaovivo",
      "Arbritagem Bet Profissional Trimestral": "trimestralprofissional",

      "Arbritagem Bet Light Anual": "anuallight",
      "Arbritagem Bet Pro Anual": "anualpro",
      "Arbritagem Bet Ao vivo Anual": "anualaovivo",
      "Arbritagem Bet Profissional Anual": "anualprofissional",
    };

    // Função auxiliar para obter a duração do plano
    const getDuracao = (tipoPlano) => {
      return Object.keys(tabelaModalidadesResult.Light)[
        Object.keys(tabelaModalidadesResult.Light).findIndex((duracao) =>
          tipoPlano.includes(duracao)
        )
      ];
    };

    // Atualização das tabelas de modalidades
    users.forEach((user) => {
      const tipoPlano = user.tipo_de_plano;

      for (const modalidade in tabelaModalidadesResults) {
        if (
          tabelaModalidadesResults[modalidade][mapeamentoPlanos[tipoPlano]] >= 0
        ) {
          tabelaModalidadesResults[modalidade][mapeamentoPlanos[tipoPlano]]++;
        }
      }
      // Garantindo que tabelaModalidadesResult seja igual à quantidade de tabelaModalidadesResults
    });

    // Atualização dos resultados de contagem de usuários ao longo do tempo
    users.forEach((user) => {
      const registrationDate = new Date(user.data_cadastro);
      const month = registrationDate.getMonth();
      userCountsResult[month]++;
    });

    // Agora, a tabelaModalidades contém o número de usuários para cada combinação de modalidade e duração.

    // Realize a busca ou processamento necessário aqui e, em seguida, defina os estados.
    const periodosResult = ["Mensal", "Trimestral", "Anual"]; // Exemplo de dados da busca
    const modalidadesResult = Object.keys(tabelaModalidadesResult); // Exemplo de dados da busca

    setPeriodos(periodosResult);
    setModalidades(modalidadesResult);
    setTabelaModalidades(tabelaModalidadesResults);

    return userCountsResult;
  };
  const handleChangePlan = (userId, categoria) => {
    // Verificação e ajuste da categoria antes de enviar
    let categoriaValidada = validarCategoria(categoria);
    let data_expiracaos = null;
    if (categoriaValidada != "Inativo") {
      const dias_expiracao = extrairDuracaoDoPlano(categoriaValidada);
      data_expiracaos = addDays(new Date(), dias_expiracao);
    }

    // Axios POST request
    axios
      .post("https://rd.vps.webdock.cloud:9512/alterplano", {
        userid: userId,
        newplans: categoriaValidada,
        data_expiracao: data_expiracaos,
      })
      .then((response) => {
        loadData("https://rd.vps.webdock.cloud:9512/users", setUsers); // Recarregar a lista de usuários após a atualização
      })
      .catch((error) => {
        console.error("Erro ao criar o cupom:", error);
      });
  };

  const validarCategoria = (categoria) => {
    // Implementar a lógica de validação aqui
    // Por exemplo, verificar se a categoria contém palavras-chave específicas
    // e ajustá-la para se adequar ao padrão desejado, se necessário

    const opcoesPlanos = {
      mensallight: "Arbritagem Bet Light Mensal",
      mensalpro: "Arbritagem Bet Pro Mensal",
      mensalaovivo: "Arbritagem Bet Ao vivo Mensal",
      mensalprofissional: "Arbritagem Bet Profissional Mensal",
      trimestrallight: "Arbritagem Bet Light Trimestral",
      trimestralpro: "Arbritagem Bet Pro Trimestral",
      trimestralaovivo: "Arbritagem Bet Ao vivo Trimestral",
      trimestralprofissional: "Arbritagem Bet Profissional Trimestral",
      anuallight: "Arbritagem Bet Light Anual",
      anualpro: "Arbritagem Bet Pro Anual",
      anualaovivo: "Arbritagem Bet Ao vivo Anual",
      anualprofissional: "Arbritagem Bet Profissional Anual",
    };
    return opcoesPlanos[categoria] || "Inativo";
  };

  const handleChangeCategoria = (userId, newPlan) => {
    // Aqui você pode enviar uma solicitação para atualizar o plano no banco de dados

    axios
      .post("https://rd.vps.webdock.cloud:9512/altercategoria", {
        userid: userId,
        newplans: newPlan,
      })
      .then((response) => {
        loadData("https://rd.vps.webdock.cloud:9512/users", setUsers);
      })
      .catch((error) => {
        console.error("Erro ao criar o alterar categoria usuario :", error);
      });
  };

  const labels = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Usuários Registrados por Mês",
        data: userCounts,
        backgroundColor: ["rgba(75, 192, 192, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)"],
        borderWidth: 1,
      },
    ],
  };
  const getTotalUsers = (userCounts) => {
    return userCounts.reduce((total, count) => total + count, 0);
  };

  // Estado para rastrear o número de dias a serem adicionados
  const [diasAdicionados, setDiasAdicionados] = useState(0);

  // Função para adicionar dias à data de vencimento

  useEffect(() => {

    //const FectDataCInterval = setInterval(() => {
      setUsers([]);
      loadData("https://rd.vps.webdock.cloud:9512/users", setUsers);
    //}, 10000);
    //return () => clearInterval(FectDataCInterval);
  }, []);


  const adicionarDias = (userId) => {
    // Encontre o usuário pelo ID
    const userToUpdate = users.find((user) => user.id === userId);
    // Função para extrair a duração do plano com base na descrição
    const extrairDuracaoDoPlano = (descricaoPlano) => {
      if (descricaoPlano.toLowerCase().includes("mensal")) {
        return 30; // Se a descrição incluir "mensal", assume uma duração de 30 dias
      } else if (descricaoPlano.toLowerCase().includes("trimestral")) {
        return 90; // Se a descrição incluir "trimestral", assume uma duração de 90 dias
      } else if (descricaoPlano.toLowerCase().includes("anual")) {
        return 365; // Se a descrição incluir "anual", assume uma duração de 365 dias
      } else {
        return 0; // Se não encontrar uma correspondência, assume 0 dias (plano indefinido)
      }
    };

    // Obtém a duração com base na descrição do plano do usuário
    const duracaoPlano = extrairDuracaoDoPlano(userToUpdate.tipo_de_plano);

    // Calcula a data de expiração do plano
    const dataExpiracao = addDays(
      new Date(userToUpdate.data_pagamento),
      duracaoPlano
    );
    if (userToUpdate) {
      // Obtenha a data de vencimento atual do usuário
      const dataVencimentoAtual = new Date(dataExpiracao);

      if (!isNaN(dataVencimentoAtual)) {
        // Verifique se a data é válida
        // Adicione os dias à data de vencimento
        dataVencimentoAtual.setDate(
          dataVencimentoAtual.getDate() + parseInt(diasAdicionados, 10)
        );

        // Verifique novamente se a data é válida após a adição de dias
        if (!isNaN(dataVencimentoAtual)) {
          // Atualize o estado do usuário com a nova data de vencimento no formato ISO 8601
          const updatedUsers = users.map((user) =>
            user.id === userId
              ? {
                  ...user,
                  data_expiracao: dataVencimentoAtual.toISOString(), // Formate a data como ISO 8601
                }
              : user
          );

          // Atualize o estado global dos usuários com a lista atualizada
          setUsers(updatedUsers);

          // Limpe o campo de entrada de dias adicionados
          setDiasAdicionados(0);
        } else {
          console.error("Data de vencimento inválida após adicionar dias.");
          // Lide com o erro de data inválida aqui, se necessário
        }
      } else {
        console.error("Data de vencimento atual inválida.");
        // Lide com o erro de data inválida aqui, se necessário
      }
    }
  };

  // Estado para rastrear a data selecionada
  const [dataSelecionada, setDataSelecionada] = useState(null);
  const [mensagem, setMensagem] = useState("");
  const [cuponValido, setCuponValido] = useState(false);
  // Função para adicionar a data selecionada à data de vencimento
  const adicionarData = async (userId) => {
    // Verifique se uma data foi selecionada
    if (dataSelecionada) {
      // Encontre o usuário pelo ID
      const userToUpdate = users.find((user) => user.id === userId);

      if (userToUpdate) {
        // Atualize o estado do usuário com a nova data selecionada
        const updatedUsers = users.map((user) =>
          user.id === userId
            ? {
                ...user,
                data_expiracao: dataSelecionada.toISOString(), // Formate a data como ISO 8601
              }
            : user
        );
        try {
          const response = await axios.post(
            "https://rd.vps.webdock.cloud:9512/add_data_expiration",
            {
              dataexpiracao: dataSelecionada.toISOString(),
              idUser: userId,
            }
          );

          if (response.status === 200) {
            // Atualize o estado global dos usuários com a lista atualizada
            setUsers(updatedUsers);

            setMensagem("A data de expiração foi alterada!");
            setCuponValido(true);
            // Limpe a data selecionada
            setDataSelecionada(null);
            // O cupom é válido, exibe uma mensagem de sucesso
          } else if (response.status === 400) {
            setMensagem(response.message);
            // O cupom não é válido, exibe uma mensagem de erro
          }
        } catch (error) {
          console.error("Erro ao verificar o cupom:", error);
          // Exibe uma mensagem de erro em caso de falha na solicitação
          setMensagem("Opss! Não houve alteração tente novamente mais tarde");
        }
      }
    } else {
      console.error("Nenhuma data selecionada.");
      // Lide com o erro de nenhuma data selecionada aqui, se necessário
    }
  };

  const mapeamentoPlanos = {
    "Arbritagem Bet Light Mensal ": "mensallight",
    "Arbritagem Bet Light Mensal": "mensallight",
    "Arbritagem Bet Pro Mensal": "mensalpro",
    "Arbritagem Bet Ao vivo Mensal": "mensalaovivo",
    "Arbritagem Bet Profissional Mensal": "mensalprofissional",

    "Arbritagem Bet Light Trimestral": "trimestrallight",
    "Arbritagem Bet Pro Trimestral": "trimestralpro",
    "Arbritagem Bet Ao vivo Trimestral": "trimestralaovivo",
    "Arbritagem Bet Profissional Trimestral": "trimestralprofissional",

    "Arbritagem Bet Light Anual": "anuallight",
    "Arbritagem Bet Pro Anual": "anualpro",
    "Arbritagem Bet Ao vivo Anual": "anualaovivo",
    "Arbritagem Bet Profissional Anual": "anualprofissional",
  };

  const obterValorPlano = (textoPlano) => {
    return mapeamentoPlanos[textoPlano] || "inativo"; // Retorna uma string vazia se não encontrar uma correspondência
  };

  return (
    <>
      <div className="config-wrappers">
        <div className="main-content">
          {activeTab === "status" && (
            <div className="status-card body-carduser">
              <div className="status-header">
                <div className="bodysidebar">
                  <div
                    className="sidebar-item"
                    onClick={() => handleSetActiveTab("status")}
                  >
                    Dashboard
                  </div>
                  <div
                    className="sidebar-item"
                    onClick={() => handleSetActiveTab("dados")}
                  >
                    Usuarios
                  </div>
                  <div
                    className="sidebar-item"
                    onClick={() => handleSetActiveTab("downloads")}
                  >
                    Controle Afiliados
                  </div>
                </div>
                <h3 className={` ${statusMode ? "text-light" : "textDark"}`}>
                  Dashboard Sistema
                </h3>
              </div>
              <div className="status-info">
                <div className="dadosconta">
                  <div className="statusconta"></div>
                  <div className="planoconta">
                    <p
                      className={` ${statusMode ? "text-light" : "textDark"}`}
                    ></p>
                  </div>

                  <h3> Graficos Quantidade de Usuarios</h3>
                  <div className="graficoqtdusuarios">
                    <Table
                      defaultActiveKey="home"
                      id="justify-tab-example"
                      striped
                      bordered
                      hover
                      className={`tabledashboard mb-3 ${
                        statusMode ? "dark-mode" : ""
                      }`}
                    >
                      <thead>
                        <tr>
                          <th>Total de Usuários</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          className={`${statusMode ? "tr-dark" : "tr-white"}`}
                        >
                          <td>{totalUsers}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <h3> Graficos Registro Quantidade de Usuarios por mês</h3>
                  <div className="graficoqtdusuariosregistrerdia">
                    <Table
                      defaultActiveKey="home"
                      id="justify-tab-example"
                      striped
                      bordered
                      hover
                      className={`tabledashboard mb-3 ${
                        statusMode ? "dark-mode" : ""
                      }`}
                    >
                      <thead>
                        <tr>
                          {labels.map((label) => (
                            <th key={label}>{label}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          className={`${statusMode ? "tr-dark" : "tr-white"}`}
                        >
                          {Array.isArray(userCounts) &&
                            userCounts.map((count, index) => (
                              <td key={index}>{count}</td>
                            ))}
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <h3> Registro tipo de Plano</h3>
                  <div className="graficoqtdusuariosregistrerdia">
                    <table className="table table-striped table-bordered table-hover tabledashboard">
                      <thead>
                        <tr>
                          <th></th>
                          {Object.keys(tabelaModalidadess.Light).map(
                            (periodo) => (
                              <th>{periodo}</th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(tabelaModalidades).map((modalidade) => (
                          <tr key={modalidade} className="tr-dark">
                            <th>{modalidade}</th>
                            {Object.keys(tabelaModalidades[modalidade]).map(
                              (periodo) => (
                                <td key={periodo}>
                                  {tabelaModalidades[modalidade][periodo]}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === "dados" && (
            <div className="user-data-card body-carduser">
              <div className="status-header">
                <div className="bodysidebar">
                  <div
                    className="sidebar-item"
                    onClick={() => handleSetActiveTab("status")}
                  >
                    Dashboard
                  </div>
                  <div
                    className="sidebar-item"
                    onClick={() => handleSetActiveTab("dados")}
                  >
                    Usuarios
                  </div>
                  <div
                    className="sidebar-item"
                    onClick={() => handleSetActiveTab("downloads")}
                  >
                    Controle Afiliados
                  </div>
                </div>

                <h3 className={` ${statusMode ? "text-light" : "textDark"}`}>
                  <div
                    className={`tabledashboard container fs-5 me-3 ${
                      statusMode ? "textDashDark" : "textDashlight"
                    }`}
                  >
                    <h2>Controle do Dashboard</h2>
                    <h3>Lista de Usuários</h3>
                  </div>
                </h3>
              </div>

              <hr />
              <div className="mensagem-cupon">
                {mensagem && (
                  <div
                    className={
                      cuponValido ? "mensagem-sucesso" : "mensagem-erro"
                    }
                  >
                    {mensagem}
                  </div>
                )}
              </div>
              <div className="container-table">
                <Table
                  defaultActiveKey="home"
                  id="myTable"
                  striped
                  bordered
                  hover
                  className={`table-striped table-bordered table-hover tabledashboard dark-mode`}
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Telefone</th>
                      <th>Registro</th>
                      <th>Categoria</th>
                      <th>Plano</th>
                      <th>Status Plano</th>
                      <th>Cupon</th>
                      <th>Vencimento</th>
                      <th>Alterar Data Vencimento</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => {

                      

                      // Função para extrair a duração do plano com base na descrição
                      const extrairDuracaoDoPlano = (descricaoPlano) => {
                        if (descricaoPlano.toLowerCase().includes("mensal")) {
                          return 30; // Se a descrição incluir "mensal", assume uma duração de 30 dias
                        } else if (
                          descricaoPlano.toLowerCase().includes("trimestral")
                        ) {
                          return 90; // Se a descrição incluir "trimestral", assume uma duração de 90 dias
                        } else if (
                          descricaoPlano.toLowerCase().includes("anual")
                        ) {
                          return 365; // Se a descrição incluir "anual", assume uma duração de 365 dias
                        } else {
                          return 0; // Se não encontrar uma correspondência, assume 0 dias (plano indefinido)
                        }
                      };

                      // Obtém a duração com base na descrição do plano do usuário
                      const duracaoPlano = extrairDuracaoDoPlano(
                        user.tipo_de_plano
                      );

                      // Calcula a data de expiração do plano
                      const dataExpiracao = addDays(
                        new Date(user.data_pagamento),
                        duracaoPlano
                      );
                      
                      return (
                        <tr className={`tr-dark`} key={user.id}>
                          <td style={{ fontSize: "0.85rem" }}>{user.id}</td>
                          <td style={{ fontSize: "0.85rem" }}>{user.nome}</td>
                          <td style={{ fontSize: "0.85rem" }}>{user.email}</td>
                          <td style={{ fontSize: "0.85rem" }}>{user.phone}</td>
                          <td style={{ fontSize: "0.85rem" }}>
                            {format(new Date(user.data_cadastro), "dd/MM/yyyy")}
                          </td>
                          <td style={{ fontSize: "0.85rem" }}>
                            <select
                              value={user.username ? user.username : "user"} // Define o valor da lista suspensa como o plano atual do usuário
                              onChange={(e) =>
                                handleChangeCategoria(user.id, e.target.value)
                              } // Chama a função para atualizar o plano quando o usuário seleciona um plano diferente
                            >
                              <option value="admin">Administrador</option>
                              <option value="user">Usuario</option>
                            </select>
                          </td>
                          <td style={{ fontSize: "0.85rem" }}>
                            <select
                              value={obterValorPlano(user.tipo_de_plano)} // Define o valor da lista suspensa como o plano atual do usuário
                              onChange={(e) =>
                                handleChangePlan(user.id, e.target.value)
                              } // Chama a função para atualizar o plano quando o usuário seleciona um plano diferente
                            >
                              <option value="mensallight">
                                Mensal - Light
                              </option>
                              <option value="mensalpro">Mensal - Pro</option>
                              <option value="mensalaovivo">
                                Mensal - Ao vivo
                              </option>
                              <option value="mensalprofissional">
                                Mensal - Profissional
                              </option>

                              <option value="trimestrallight">
                                Trimestral - Light
                              </option>
                              <option value="trimestralpro">
                                Trimestral - Pro
                              </option>
                              <option value="trimestralaovivo">
                                Trimestral - Ao vivo
                              </option>
                              <option value="trimestralprofissional">
                                Trimestral - Profissional
                              </option>

                              <option value="anuallight">Anual - Light</option>
                              <option value="anualpro">Anual - Pro</option>
                              <option value="anualaovivo">
                                Anual - Ao vivo
                              </option>
                              <option value="anualprofissional">
                                Anual - Profissional
                              </option>
                              <option value="inativo">Inativo</option>
                            </select>
                          </td>
                          <td style={{ fontSize: "0.85rem" }}>
                            {user.tipo_de_plano == "Inativo"
                              ? "Inativo"
                              : user.tipo_de_plano == "Gratuito"
                              ? "Inativo"
                              : "Ativo"}
                          </td>
                          <td style={{ fontSize: "0.85rem" }}>
                            {user.cuponuser === null ? (
                              <>-</>
                            ) : (
                              <>{user.cuponuser}</>
                            )}
                          </td>
                          <td style={{ fontSize: "0.85rem" }}>
                            {user.data_expiracao
                              ? format(
                                  new Date(user.data_expiracao),
                                  "dd/MM/yyyy"
                                )
                              : format(dataExpiracao, "dd/MM/yyyy")}
                          </td>{" "}
                          {/* Exibe a data de expiração formatada */}
                          <td
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto auto",
                              fontSize: "0.85rem",
                              width: "100%",
                            }}
                          >
                            <DatePicker
                              selected={dataSelecionada}
                              onChange={(date) => setDataSelecionada(date)} // Atualize a data selecionada
                              dateFormat="dd/MM/yyyy" // Formato da data a ser exibido
                              style={{
                                backgroundColor: "gray",
                                width: "50%",
                                color: "white",
                              }}
                            />
                            <button
                              style={{
                                background: "green",
                                color: "white",
                                padding: "5px",
                                borderRadius: "3px",
                              }}
                              onClick={() => adicionarData(user.id)}
                            >
                              Adicionar
                            </button>
                          </td>
                          <td style={{ fontSize: "0.85rem" }}>
                            <button
                              style={{
                                background: "red",
                                color: "white",
                                padding: "5px",
                              }}
                              onClick={() => deleteUser(user.id)}
                            >
                              Excluir
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <hr />
            </div>
          )}
          {activeTab === "downloads" && (
            <div className="downloads-card body-carduser">
              <div className="status-header">
                <div className="bodysidebar">
                  <div
                    className="sidebar-item"
                    onClick={() => handleSetActiveTab("status")}
                  >
                    Dashboard
                  </div>
                  <div
                    className="sidebar-item"
                    onClick={() => handleSetActiveTab("dados")}
                  >
                    Usuarios
                  </div>
                  <div
                    className="sidebar-item"
                    onClick={() => handleSetActiveTab("downloads")}
                  >
                    Controle Afiliados
                  </div>
                </div>
                <h3 className={` ${statusMode ? "text-light" : "textDark"}`}>
                  Área de Downloads
                </h3>
              </div>
              <hr />
              <Form className="containerdashboard">
                <h3>Criar Cupom de Desconto</h3>
                <FloatingLabel controlId="cupomName" label="Nome do Cupom">
                  <Form.Control
                    type="text"
                    placeholder="Nome do Cupom"
                    value={cupomName}
                    onChange={(e) => setCupomName(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel controlId="cupomValue" label="% do Desconto">
                  <Form.Control
                    type="number"
                    placeholder="Valor do Desconto"
                    value={cupomValue}
                    step="0.01"
                    onChange={(e) => setCupomValue(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="cupomCreationDate"
                  label="Data de Criação"
                >
                  <Form.Control
                    type="date"
                    placeholder="Data de Criação"
                    value={cupomCreationDate}
                    onChange={(e) => setCupomCreationDate(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel controlId="cupomValidity" label="Validade">
                  <Form.Control
                    type="date"
                    placeholder="Validade"
                    value={cupomValidity}
                    onChange={(e) => setCupomValidity(e.target.value)}
                  />
                </FloatingLabel>

                <button className="btn-dashboard" onClick={createCupom}>
                  Criar Cupom
                </button>
              </Form>
              <hr />
              {cupomCreated && (
                <ToastContainer position="top" className="p-3">
                  <Toast
                    bg="success"
                    onClose={() => setCupomCreated(false)}
                    show={cupomCreated}
                    delay={3000}
                    autohide
                  >
                    <Toast.Header>
                      <strong className="me-auto">Cupom Criado</strong>
                    </Toast.Header>
                    <Toast.Body>O cupom foi criado com sucesso!</Toast.Body>
                  </Toast>
                </ToastContainer>
              )}
              <hr />
              <h3>Lista de Cupons</h3>
              <Table
                striped
                bordered
                hover
                className={`tabledashboard mb-3 ${
                  statusMode ? "dark-mode" : ""
                }`}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nome do Cupom</th>
                    <th>% do Desconto</th>
                    <th>Data de Criação</th>
                    <th>Validade</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cupons.map((cupom) => (
                    <tr
                      className={`${statusMode ? "tr-dark" : "tr-white"}`}
                      key={cupom.id}
                    >
                      <td>{cupom.id}</td>
                      <td>{cupom.nome}</td>
                      <td>{cupom.valor_desconto}</td>
                      <td>
                        {format(new Date(cupom.data_criacao), "dd/MM/yyyy")}
                      </td>
                      <td>{format(new Date(cupom.validade), "dd/MM/yyyy")}</td>
                      <td>
                        <button
                          style={{
                            background: "red",
                            color: "white",
                            padding: "5px",
                          }}
                          onClick={() => deleteCupon(cupom.id)}
                        >
                          Excluir
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* Conteúdo dos Downloads */}
            </div>
          )}
          {activeTab === "suporte" && (
            <div className="support-card body-carduser">
              <div className="status-header">
                <h3>Suporte</h3>
              </div>

              <p>Para obter suporte, entre em contato pelo WhatsApp.</p>
              <Button
                className={` ${statusMode ? "text-light" : "textDark"}`}
                variant="success"
                href="https://api.whatsapp.com/send?phone=SEU_NUMERO_DE_WHATSAPP"
              >
                Contatar Suporte via WhatsApp
              </Button>
            </div>
          )}
          {activeTab === "sair" && (
            <div className="body-carduser">
              <div className="status-header">
                <h1 className={` ${statusMode ? "text-light" : "textDark"}`}>
                  Desconectar
                </h1>
              </div>

              <hr />

              <li className="nav-link">
                <span className="navlink">
                  <a className="linknavbar">
                    <b
                      className={` ${statusMode ? "text-light" : "textDark"}`}
                    ></b>
                  </a>
                </span>
              </li>
            </div>
            // Ação para sair, como handleLogout ou redirecionamento
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardControl;
