import Cookies from 'js-cookie';
import { listKeysToCheck, listValueItemAccepted, listKeyItemsToUpdateInLocalStorage } from '../variables/VariablesFilterSurebet';
import { listBetHomeDemarkOnFilter } from '../../global/GlobalVariables';


function modelFilter(data) {
    let listKeyEnabledToUse = []
    if (typeof (data) === 'object') {
        let checkList = listKeysToCheck.filter(item => {
            return (data[item] !== undefined);
        });

        listKeyEnabledToUse = checkList.filter(key => {
            let typeVariableAccepted = listValueItemAccepted[key];
            let valueVariableInsideFilter = data[key];
            if (typeof (valueVariableInsideFilter) === typeVariableAccepted) {
                return key
            }
        });
    }
    return listKeyEnabledToUse
}
export async function SaveFilterArbitragem(dataFilterToSave) {
    const b4726486 = Cookies.get('b4726486');
    const dataToSend = {
        's377466043': b4726486,
        'f45837': dataFilterToSave
    };
    const response = await fetch(`https://rd.vps.webdock.cloud:9456/save_filter`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify(dataToSend)
    });

    if (response.ok) {
        const data = await response.json();
        return data;
    } else {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}




export async function getFilterArbitragem(
    setBetHomeEnabled, setSportsEnabled, setActualFilterOrder, setActualTimeEvent, setActualValueMin, setActualValueMax,
    setQuantityBetHomeEnabled, setQuantitySportsEnabled, setMarketsUnselected, setBlacklistWords
) {
    const b4726486 = Cookies.get('b4726486');
    const dataToSend = {
        's377466043': b4726486,
    };


    let listSetState = {
        "betHome": setBetHomeEnabled,
        "sports": setSportsEnabled,
        "timeEvent": setActualTimeEvent,
        "filterOrder": setActualFilterOrder,
        "minProfit": setActualValueMin,
        "maxProfit": setActualValueMax,
        "marketsUnselected": setMarketsUnselected,
        "blacklistWordsSurebet": setBlacklistWords
    }
    let listQuantityToUpdate = {
        "listBehomeEnabled": setQuantityBetHomeEnabled,
        "listSportsEnabled": setQuantitySportsEnabled,
    }



    const response = await fetch(`https://rd.vps.webdock.cloud:9456/get_filter_arbitragem`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify(dataToSend)
    });

    if (response.ok) {
        try {
            const data = await response.json();

            let allKeysFilter = modelFilter(data);

            for (let i = 0; i < allKeysFilter.length; i++) {
                let keyFilter = allKeysFilter[i];
                let keyLocalStorage = listKeyItemsToUpdateInLocalStorage[keyFilter];
                let actualValue = data[keyFilter];



                
                listSetState[keyFilter](actualValue);

                localStorage.setItem(keyLocalStorage, JSON.stringify(actualValue));

                if (keyLocalStorage in listQuantityToUpdate) {
                    listQuantityToUpdate[keyLocalStorage](actualValue.length);
                }
            }
            return data;
        }
        catch {
            console.log('')
        }
    } else {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export function deMarkCloneBetHome(nameBetHomeLower, setBetHomeFrontEnd) {
    const listBetHomeToDemark = listBetHomeDemarkOnFilter.filter((item) => item.includes(nameBetHomeLower));

    if (listBetHomeToDemark.length > 0) {
        setBetHomeFrontEnd((current) => {
            return current
                .filter((item) => !listBetHomeToDemark[0].includes(item))
                .concat(nameBetHomeLower);
        });
    }
}





export function sortListBetHome(listClonesAllBetHomes, listBetHome) {


    let allBetHomeAbleToClone = listClonesAllBetHomes["allBetHomeAbleToClone"].filter(item => item.toLocaleLowerCase().replaceAll(" ", "") !== "bolsadeaposta");
  
  
    let allBetHomeUnited = []
    let clonesSortedByBetHome = {};
  
    for (let i = 0; i < allBetHomeAbleToClone.length; i++) {
      let keyBetHome = allBetHomeAbleToClone[i];
      let itemCloneBetHome = listClonesAllBetHomes[keyBetHome]["allBetHomeClone"];      
      allBetHomeUnited = allBetHomeUnited.concat(itemCloneBetHome);
      clonesSortedByBetHome[keyBetHome] = itemCloneBetHome;
    }
  
  
    let listBetHomeLower = listBetHome.map(item => item.toLocaleLowerCase());
    let newBetHomeRemad = listBetHome.filter(item => allBetHomeUnited.includes(item.toLocaleLowerCase()) == false)
  
  
    let allBetHome = [];        
    for (let i = 0; i < newBetHomeRemad.length; i++) {
      let nameBetHome = newBetHomeRemad[i];
      let nameBetHomeLower = nameBetHome.toLocaleLowerCase();
  
  
      let betHomeOrganized = { "name": nameBetHome, hasClone: false, allClones: [] };
        
      if (nameBetHomeLower in clonesSortedByBetHome) {        
        let clonesBetHome = clonesSortedByBetHome[nameBetHomeLower];
  
  
        let newClonesBetHome = [];
        for (let oi = 0; oi < clonesBetHome.length; oi++) {
          let cloneBetHome = clonesBetHome[oi];
  
          let indexCloneBetHome = listBetHomeLower.indexOf(cloneBetHome);            
          if (indexCloneBetHome !== -1) {
            newClonesBetHome.push(listBetHome[indexCloneBetHome]);
          }
        }
  
  
        if (newClonesBetHome.length > 0) {
          betHomeOrganized["hasClone"] = true;
          betHomeOrganized["allClones"] = newClonesBetHome;
        }
      }
  
  
      allBetHome = allBetHome.concat(betHomeOrganized);
    }
  
    return allBetHome
  }
  

export function setClearOldSurebet(useRefe_clearOldSurebet){
    useRefe_clearOldSurebet.current = true;
}


export function saveWordOnBlackListWords(keywordsAdded, setBlacklistWords){
    setBlacklistWords(keywordsAdded);
    let newBlacklistWords = JSON.stringify(keywordsAdded);
    localStorage.setItem("blacklistWords", newBlacklistWords);
}