
import { listSortType, listSortTypeToSelect, } from "../global/GlobalVariables";
import { updateClassifiactionSurebet } from "../global/GlobalFunctions";

import React, { useEffect, useState, useContext } from "react";
import {
  listBetHomeMinuscle, allBetHome, exchangeBetHome, sportsbookBetHome, NameBetHomeRenamedInFilter,
  allSportsMinuscle, sportsPart1, sportsPart2, esports, warningBetHome, warningBetHomeMessages, warningBetHomeIcons, newListBetHomesSorted
} from "./variables/VariablesFilterSurebet";
import { SaveFilterArbitragem, deMarkCloneBetHome, setClearOldSurebet } from "./functions/FunctionsFilter";
import { Tooltip } from 'react-tooltip';
import { quantityMarkets } from "./variables/VariablesFilterSurebet";
import { Context } from "../App";
import { MarketsDesktop } from "./sub-componentes/filter/MarketsDesktop";
import { EventDesktop } from "./sub-componentes/filter/EventDesktop";
import { saveWordOnBlackListWords } from "./functions/FunctionsFilter";
import "../assets/css/style-filter.css";


function Filter(props) {
  const {
    controllerLocalStorage,
    valueMin,
    setValueMin,
    valueMax,
    setValueMax,
    betHome,
    setBetHome,
    quantityBetHomeEnabled,
    setQuantityBetHomeEnabled,
    sports,
    setSports,
    quantitySportsEnabled,
    setQuantitySportsEnabled,
    timeEvent,
    setTimeEvent,
    statusMode,
    setlistBehomeEnableds,
    setentradasLigth,
    entradasLigth,
    setentradasPro,
    entradasPro,
    AparecerProf,
    AparecerLight,
    AparecerPro,
    filterOrder,
    setFilterOrder,
    requiredBetHome,
    setRequiredBetHome
  } = props;

  const [betHomeFrontEnd, setBetHomeFrontEnd] = useState([]);
  const [requiredBetHomeFrontEnd, setRequiredBetHomeFrontEnd] = useState([]);

  const [showMarket, setShowMarket] = useState(false);
  const handleCloseFilterMarket = () => setShowMarket(false);
  const handleShowFilterMarket = () => setShowMarket(true);
  const [showFilterEvent, setShowFilterEvent] = useState(false);
  const { marketsUnselected, setMarketsUnselected, clearOldSurebet, blacklistWords, setBlacklistWords } = useContext(Context);
  
  let actualMarketsUnselected = marketsUnselected;
  let quantityAllBetHome = allBetHome.length;
  let quantityAllBetHomeExchange = exchangeBetHome.length;
  let quantityAllBetHomeSportsbook = sportsbookBetHome.length;
  
  //console.log(44, 'requiredBetHome', requiredBetHome);
  function formatDataFilterToSave(
    {
      actualBethomeEnable = betHome,
      actualSportsEnabled = sports,
      actuaTimeEvent = timeEvent,
      actualOrderBet = filterOrder,
      actualMinProfit = valueMin,
      actualMaxProfit = valueMax,
      marketsUnselected = actualMarketsUnselected,
      blacklistWordsSurebet = blacklistWords
    }
  ) {


    let dataFilterReseted = {
      'betHome': actualBethomeEnable,
      'sports': actualSportsEnabled,
      'timeEvent': actuaTimeEvent,
      'filterOrder': actualOrderBet,
      'minProfit': Number(actualMinProfit),
      'maxProfit': Number(actualMaxProfit),
      'marketsUnselected': marketsUnselected,
      'blacklistWordsSurebet': blacklistWordsSurebet
    };


    return dataFilterReseted;
  }

  function markAll(event) {
    const { checked } = event.target;
    if (checked === false) {
      setBetHomeFrontEnd([]);
      setRequiredBetHomeFrontEnd([]);
    } else {
      setBetHomeFrontEnd(listBetHomeMinuscle);
    }
  }

  function markAllSports(event) {
    const { checked } = event.target;

    if (checked === false) {
      setSports([]);
    } else {
      setSports(allSportsMinuscle);
    }
  }

  function saveFilterSports(dataSports, saveFilterSportInCloud = false) {
    controllerLocalStorage["updateLocalStorage"](
      "listSportsEnabled",
      dataSports
    );
    setQuantitySportsEnabled(
      JSON.parse(localStorage["listSportsEnabled"]).length
    );

    if (saveFilterSportInCloud === true) {

      let dataFilterToUpdate = formatDataFilterToSave(
        { actualSportsEnabled: dataSports, }
      )
      SaveFilterArbitragem(dataFilterToUpdate);
    }

    setSports(JSON.parse(localStorage["listSportsEnabled"]));
  }

  function saveFilterBetHome(dataBethome, saveFilterInCloud = false) {
    controllerLocalStorage["updateLocalStorage"](
      "listBehomeEnabled",
      dataBethome
    );

    if (saveFilterInCloud === true) {
      let dataFilterToUpdate = formatDataFilterToSave(
        { actualBethomeEnable: dataBethome, }
      )


      SaveFilterArbitragem(dataFilterToUpdate);
    }

    setQuantityBetHomeEnabled(
      JSON.parse(localStorage["listBehomeEnabled"]).length
    );
    setBetHome(JSON.parse(localStorage["listBehomeEnabled"]));
    setlistBehomeEnableds(dataBethome);

    setRequiredBetHome(requiredBetHomeFrontEnd);

    localStorage.setItem('riquiredsBethome', JSON.stringify(requiredBetHomeFrontEnd));
  }

  function restoreFrontEndFilter() {
    setBetHomeFrontEnd(JSON.parse(localStorage["listBehomeEnabled"]));
    setSports(JSON.parse(localStorage["listSportsEnabled"]));
    setRequiredBetHomeFrontEnd(requiredBetHome);
  }




  function EnabledDisablebBethome(event) {
    const { checked, name } = event.target;
    const lowerCaseName = name.toLowerCase();

    if (checked === false) {



      if (requiredBetHomeFrontEnd.includes(lowerCaseName) === true) {
        setBetHomeFrontEnd((betHomes) => {
          return betHomes.filter((item) => item !== lowerCaseName);
        });

        setRequiredBetHomeFrontEnd((betHomes) => {
          return betHomes.filter((item) => item !== lowerCaseName);
        });
      } else {

        deMarkCloneBetHome(lowerCaseName, setBetHomeFrontEnd);
        deMarkCloneBetHome(lowerCaseName, setRequiredBetHomeFrontEnd);
        setRequiredBetHomeFrontEnd((current) => { return current.filter((item) => item !== lowerCaseName).concat(lowerCaseName) });
      }
    } else {


      deMarkCloneBetHome(lowerCaseName, setBetHomeFrontEnd);


      setBetHomeFrontEnd((current) => {
        return current
          .filter((item) => item !== lowerCaseName)
          .concat(lowerCaseName);
      });

    }
  }
  function EnabledDisablebEntradas(event) {
    const { checked, name } = event.target;

    controllerLocalStorage["updateLocalStorage"](name, checked);
    if (name === "entradaslight") {
      setentradasLigth(checked);
    } else if (name === "entradaspro") {
      setentradasPro(checked);
    }

    setClearOldSurebet(clearOldSurebet);
  }

  function EnabledDisabledSport(event) {
    const { checked, name } = event.target;
    const lowerCaseName = name.toLowerCase();

    if (checked === false) {
      setSports((allSports) => {
        return allSports.filter((item) => item !== lowerCaseName);
      });
    } else {
      // Check if the selected option is one of the restricted ones
      if (["betboo", "sportingbet", "bwin"].includes(lowerCaseName)) {
        // Unselect all restricted options
        setSports([]);
      } else {
        // Check if one of the restricted options is already selected
        if (
          sports.some((item) =>
            ["betboo", "sportingbet", "bwin"].includes(item)
          )
        ) {
          // Unselect all restricted options
          setSports([]);
        }
        setSports((current) => [...current, lowerCaseName]);
      }
    }
  }

  const setValue = {
    profitMin: (paramenterValueMin, keyLocalStorage, saveMinFilterInCloud = false) => {

      if (saveMinFilterInCloud === true) {

        let dataFilterToUpdate = formatDataFilterToSave(
          { actualMinProfit: paramenterValueMin, }
        );


        SaveFilterArbitragem(dataFilterToUpdate);
      }

      setValueMin(paramenterValueMin);
      controllerLocalStorage["updateLocalStorage"](
        keyLocalStorage,
        paramenterValueMin
      );
    },
    profitMax: (parameterValueMax, keyLocalStorage, saveMaxFilterInCloud = false) => {

      if (saveMaxFilterInCloud === true) {
        let dataFilterToUpdate = formatDataFilterToSave(
          {
            actualMaxProfit: parameterValueMax,
          }
        )


        SaveFilterArbitragem(dataFilterToUpdate);
      }

      setValueMax(parameterValueMax);
      controllerLocalStorage["updateLocalStorage"](
        keyLocalStorage,
        parameterValueMax
      );
    },
  };
  const handleChange = (event) => {
    const { value, id } = event.target;
    setValue[id](value, id, true);
  };

  function changeTime(event) {
    const { name } = event.target;




    let dataFilterToUpdate = formatDataFilterToSave(
      {
        actuaTimeEvent: name,
      }
    )


    SaveFilterArbitragem(dataFilterToUpdate);

    setTimeEvent(name);
    controllerLocalStorage["updateLocalStorage"]("time", name);
  }

  function filterReset() {
    setTimeEvent("Qualquer Horário");
    controllerLocalStorage["updateLocalStorage"]("time", "Qualquer Horário");
    setValue["profitMin"](-999, "profitMin");
    setValue["profitMax"](999, "profitMax");

    setSports(allSportsMinuscle);
    saveFilterSports(allSportsMinuscle);

    setBetHome(listBetHomeMinuscle);
    saveFilterBetHome(listBetHomeMinuscle);

    setRequiredBetHome([]);
    localStorage.setItem('riquiredsBethome', JSON.stringify([]));

    let dataFilterToUpdate = formatDataFilterToSave(
      {
        actualBethomeEnable: listBetHomeMinuscle,
        actualSportsEnabled: allSportsMinuscle,
        actuaTimeEvent: "Qualquer Horário",
        actualMinProfit: -999,
        actualMaxProfit: 999,
        marketsUnselected: [],
        blacklistWordsSurebet: []
      }
    )
    setMarketsUnselected([]);
    localStorage.setItem("marketsUnselected", JSON.stringify([]));
    saveWordOnBlackListWords([], setBlacklistWords);

    SaveFilterArbitragem(dataFilterToUpdate);
  }

  const handleCloseFilterEvent = () => setShowFilterEvent(false);

  useEffect(() => {
    if ((filterOrder in listSortTypeToSelect) === false) {
      setFilterOrder('lucro');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      id="filterDesktop"
      className={`containerFilter mt-3 mx-2 border border-2 rounded ${statusMode ? "borderColor" : ""
        }`}
      style={{
        height: "fit-content",
        backgroundColor: statusMode ? "#0d0d0d" : "",
      }}
    >
      <div
        className={`boxHeader p-2 border-bottom ${statusMode ? "borderColor" : ""
          }`}
      >
        <div className="boxOptions">
          <div
            className={`text-Filter p-0 m-0 fw-bold text-center ${statusMode ? "textGray" : ""
              }`}
          >
            FILTROS
          </div>
        </div>
      </div>
      <div
        id="filter"
        className="px-4 d-flex flex-column align-items-center filter"
      >
        <p
          className={`text-faixa-lucro fs-6 mt-2 mb-2 ${statusMode ? "textGray" : ""
            }`}
        >
          Faixa de lucro (min/max)
        </p>
        <div className=" d-flex align-items-center mb-2">
          <input
            id="profitMin"
            style={{
              width: "80px",
              height: "35px",
              backgroundColor: statusMode ? "#0d0d0d" : "",
            }}
            type="number"
            className={`form-control fs-6 ${statusMode ? "borderColor textGray" : ""
              }`}
            placeholder="0.00"
            onChange={handleChange}
            value={valueMin}
          />
          <span className=" mx-2 fs-6">/</span>
          <input
            id="profitMax"
            style={{
              width: "80px",
              height: "35px",
              backgroundColor: statusMode ? "#0d0d0d" : "",
            }}
            type="number"
            className={`form-control fs-6 ${statusMode ? "borderColor textGray" : ""
              }`}
            placeholder="0.00"
            onChange={handleChange}
            value={valueMax}
          />
        </div>

        <div
          className="dropdown fs-6 d-flex flex-column align-items-center mt-3"
          id="filter_time"
        >
          <button
            className={`dropdown-toggle btn btn-success btn-sm fs-6 ${statusMode ? "" : ""
              }`}
            style={{ background: "#2a960d", color: "white" }}
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Evento Durante
          </button>
          <ul
            className={`dropdown-menu ${statusMode ? "bgDark borderColor" : ""
              }`}
          >
            <li>
              <button
                className={`dropdown-item fs-6  ${"Qualquer Horário" === timeEvent ? "active" : ""
                  } ${statusMode ? "textGray hoverGray" : ""}`}
                name="Qualquer Horário"
                onClick={changeTime}
              >
                Qualquer Horário
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item fs-6  ${"6 Horas" === timeEvent ? "active" : ""
                  } ${statusMode ? "textGray hoverGray" : ""}`}
                name="6 Horas"
                onClick={changeTime}
              >
                6 Horas
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item fs-6  ${"12 Horas" === timeEvent ? "active" : ""
                  } ${statusMode ? "textGray hoverGray" : ""}`}
                name="12 Horas"
                onClick={changeTime}
              >
                12 Horas
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item fs-6  ${"16 Horas" === timeEvent ? "active" : ""
                  } ${statusMode ? "textGray hoverGray" : ""}`}
                name="16 Horas"
                onClick={changeTime}
              >
                16 Horas
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item fs-6  ${"1 Dia" === timeEvent ? "active" : ""
                  } ${statusMode ? "textGray hoverGray" : ""}`}
                name="1 Dia"
                onClick={changeTime}
              >
                1 Dia
              </button>
            </li>
          </ul>

          <div className="text-center" style={{ width: "115px" }}>
            <span
              id="badge_time_event"
              className={`badge rounded-pill text-white bg-primary  ${statusMode ? "" : ""
                }`}
            >
              {timeEvent}
            </span>
          </div>
        </div>





        <div className="mt-3">
          <button type="button" className="btn btn-success dropdown-toggle text-light default-green btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
            Ordenar por
          </button>
          <ul className="dropdown-menu custom-theme-dropdown">

            {

              listSortType.map((itemSort, indexSort) => {
                return (
                  <li className={`curso-pointer ${filterOrder === itemSort.toLowerCase() ? 'classificationActive' : ''}`} key={indexSort} onClick={() => {
                    updateClassifiactionSurebet(itemSort, setFilterOrder, 'classificationSurebet');
                    let dataFilterToUpdate = formatDataFilterToSave(
                      { actualOrderBet: itemSort.toLocaleLowerCase(), }
                    )


                    SaveFilterArbitragem(dataFilterToUpdate);
                  }} >
                    <span className="dropdown-item ">
                      {itemSort}
                    </span>
                  </li>
                )
              })
            }
          </ul>
        </div>



        <div className="text-center" style={{ width: "115px" }}>
          <span
            id="badge_time_event"
            className={`badge rounded-pill text-white bg-primary  ${statusMode ? "" : ""
              }`}
          >
            {(filterOrder in listSortTypeToSelect) === true ? listSortTypeToSelect[filterOrder] : 'Lucro'}
          </span>
        </div>
        <br />


        {betHome.includes('novibet') ?

          <div className="bg-danger  rounded text-light text-center fw-bold" style={{ maxWidth: '250px' }}>
            Atenção! NoviBet selecionada. Essa casa pode causar problemas.
          </div>

          :
          ""
        }
        <span
          id="btn_filter_bet_home"
          className={`fw-bolder fs-6 textDecoration text-center ${statusMode ? "textGray" : "textGray-update2"
            }`}
          data-bs-toggle="modal"
          data-bs-target="#filterBetHome"
          style={{ cursor: "pointer" }}
          onClick={restoreFrontEndFilter}
        >
          Casas de Apostas{" "}
          <span
            id="qt_bet_home_selected"
            className=""
            style={{
              color: "#ced2d6!important",
              borderRadius: "30%",
              padding: "3px",
              fontSize: "0.9rem",
            }}
          >
            {" "}
            <b>({quantityBetHomeEnabled}) </b>
          </span>
          {
            requiredBetHome.length >= 1 ?
              <div className="text-light bg-primary rounded-5 ">
                {requiredBetHome.length} em prioridade
              </div>
              :
              ""
          }
        </span>


        <div
          className="modal fade alt_margin_x"
          id="filterBetHome"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg d-flex justify-content-center mt-3">
            <div className="modal-content moldal-lg " style={{ width: 'fit-content', minWidth: '1000px' }}>
              <div
                className={`modal-header ${statusMode ? "bgDark borderColor textGray" : ""
                  }`}
              >
                <div className="modal-title h4" id="exampleModalLabel">
                  Casas de Apostas
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div
                className={`modal-body px-0 pb-0  ${statusMode ? "bgDark" : ""
                  }`}
              >

                <div className="w-100 style-filter-surebet-desktop">
                  {newListBetHomesSorted &&
                    newListBetHomesSorted.map((value, index) => {
                      let nameBetHomeFilterLower = value["name"].toLowerCase()
                      let isEnableWarning = nameBetHomeFilterLower in warningBetHome ? true : false;
                      return (

                        <>
                          <div
                            className={`form-check d-flex align-items-center ${exchangeBetHome.includes(value["name"]) ? 'ms-4' : 'mx-3'} ${statusMode ? "textGray" : ""
                              }`}
                            key={index}

                          >


                            <input
                              type="checkbox"
                              id={"casasDeApostas" + index}
                              className={`form-check-input ${requiredBetHomeFrontEnd.includes(nameBetHomeFilterLower) === true ? 'cus' : ''}`}
                              name={nameBetHomeFilterLower}
                              checked={
                                typeof betHomeFrontEnd === "object" &&
                                betHomeFrontEnd &&
                                betHomeFrontEnd.includes(nameBetHomeFilterLower)
                              }
                              onChange={EnabledDisablebBethome}
                            />
                            {nameBetHomeFilterLower in warningBetHome ? <img data-tooltip-content={isEnableWarning ? warningBetHomeMessages[warningBetHome[nameBetHomeFilterLower]] : undefined}
                              data-tooltip-id={isEnableWarning ? 'tooltip-surebet' : undefined} className="style-icon-alert-filter" src={warningBetHomeIcons[warningBetHome[nameBetHomeFilterLower]]} /> : ''}
                            <label
                              title=""
                              htmlFor={"casasDeApostas" + index}
                              className="form-check-label"
                              name={nameBetHomeFilterLower}
                            >
                              {value["name"] in NameBetHomeRenamedInFilter ? NameBetHomeRenamedInFilter[value["name"]] : value["name"]}

                            </label>
                          </div>



                          {
                            value["hasClone"] ?
                              <>
                                {
                                  value["allClones"].map((itemClone, indexClone) => {
                                    let nameCloneBetHomeFilterLower = itemClone.toLocaleLowerCase();
                                    return (
                                      <div
                                        className={` form-check d-flex align-items-center ${exchangeBetHome.includes(itemClone) ? 'ms-4' : 'mx-3'} ${statusMode ? "textGray" : ""
                                          } ms-5`}
                                        key={indexClone}

                                      >


                                        <input
                                          type="checkbox"
                                          id={"casasDeApostasClone" + value["name"] + indexClone}
                                          className={`form-check-input ${requiredBetHomeFrontEnd.includes(nameCloneBetHomeFilterLower) === true ? 'cus' : ''}`}
                                          name={nameCloneBetHomeFilterLower}
                                          checked={
                                            typeof betHomeFrontEnd === "object" &&
                                            betHomeFrontEnd &&
                                            betHomeFrontEnd.includes(nameCloneBetHomeFilterLower)
                                          }
                                          onChange={EnabledDisablebBethome}
                                        />
                                        {nameCloneBetHomeFilterLower in warningBetHome ? <img data-tooltip-content={isEnableWarning ? warningBetHomeMessages[warningBetHome[nameCloneBetHomeFilterLower]] : undefined}
                                          data-tooltip-id={isEnableWarning ? 'tooltip-surebet' : undefined} className="style-icon-alert-filter" src={warningBetHomeIcons[warningBetHome[nameCloneBetHomeFilterLower]]} /> : ''}
                                        <label
                                          title=""
                                          htmlFor={"casasDeApostasClone" + value["name"] + indexClone}
                                          className="form-check-label"
                                          name={nameCloneBetHomeFilterLower}
                                        >
                                          {itemClone in NameBetHomeRenamedInFilter ? NameBetHomeRenamedInFilter[itemClone] : itemClone}

                                        </label>
                                      </div>
                                    );
                                  })
                                }

                              </>
                              :
                              ""
                          }

                        </>
                      )
                    })}
                </div>

                <div className="d-flex">
                  <div className="w-100 pe-2 mt-3 mb-2">
                    {allBetHome &&
                      exchangeBetHome.map((value, index) => {
                        let nameBetHomeFilterLower = value.toLowerCase();
                        let isEnableWarning = nameBetHomeFilterLower in warningBetHome ? true : false;

                        return (


                          index === 0 ?

                            <React.Fragment key={index}>
                              <h4 className="ms-3 mode-h4">Exchange</h4>
                              <div
                                className={`form-check ms-4 d-flex align-items-center ${statusMode ? "textGray" : ""
                                  }`}
                              >
                                <input
                                  type="checkbox"
                                  id={"casasDeApostasExchange" + index}
                                  className={`form-check-input ${requiredBetHomeFrontEnd.includes(nameBetHomeFilterLower) === true ? 'cus' : ''}`}
                                  name={nameBetHomeFilterLower}
                                  checked={
                                    typeof betHomeFrontEnd === "object" &&
                                    betHomeFrontEnd &&
                                    betHomeFrontEnd.includes(nameBetHomeFilterLower)
                                  }
                                  onChange={EnabledDisablebBethome}
                                />

                                {nameBetHomeFilterLower in warningBetHome ? <img data-tooltip-content={isEnableWarning ? warningBetHomeMessages[warningBetHome[nameBetHomeFilterLower]] : undefined}
                                  data-tooltip-id={isEnableWarning ? 'tooltip-surebet' : undefined} className="style-icon-alert-filter" src={warningBetHomeIcons[warningBetHome[nameBetHomeFilterLower]]} /> : ''}

                                <label
                                  title=""
                                  htmlFor={"casasDeApostasExchange" + index}
                                  className="form-check-label white-space-nowrap"
                                  name={nameBetHomeFilterLower}
                                >
                                  {value}

                                </label>
                              </div>
                            </React.Fragment>


                            :
                            <div
                              className={`form-check ms-4 d-flex align-items-center ${statusMode ? "textGray" : ""
                                }`}
                              key={index}
                            >
                              <input
                                type="checkbox"
                                id={"casasDeApostasExchange" + index}
                                className={`form-check-input ${requiredBetHomeFrontEnd.includes(value.toLowerCase()) === true ? 'cus' : ''}`}
                                name={value.toLowerCase()}
                                checked={
                                  typeof betHomeFrontEnd === "object" &&
                                  betHomeFrontEnd &&
                                  betHomeFrontEnd.includes(value.toLowerCase())
                                }
                                onChange={EnabledDisablebBethome}
                              />

                              {nameBetHomeFilterLower in warningBetHome ? <img data-tooltip-content={isEnableWarning ? warningBetHomeMessages[warningBetHome[nameBetHomeFilterLower]] : undefined}
                                data-tooltip-id={isEnableWarning ? 'tooltip-surebet' : undefined} className="style-icon-alert-filter" src={warningBetHomeIcons[warningBetHome[nameBetHomeFilterLower]]} /> : ''}

                              <label
                                title=""
                                htmlFor={"casasDeApostasExchange" + index}
                                className="form-check-label white-space-nowrap"
                                name={value.toLowerCase()}
                              >
                                {value in NameBetHomeRenamedInFilter ? NameBetHomeRenamedInFilter[value] : value}

                              </label>
                            </div>

                        )
                      })}



                  </div>

                  <div className="w-100 pe-2 mt-3 mb-2">
                    {allBetHome &&
                      sportsbookBetHome.map((value, index) => {
                        let nameBetHomeFilterLower = value.toLowerCase();
                        let isEnableWarning = nameBetHomeFilterLower in warningBetHome ? true : false;

                        return (


                          index === 0 ?

                            <React.Fragment key={index}>
                              <h4 className="ms-3 mode-h4">Sportsbook</h4>
                              <div
                                className={`form-check ms-4 d-flex align-items-center ${statusMode ? "textGray" : ""
                                  }`}
                              >
                                <input
                                  type="checkbox"
                                  id={"casasDeApostasSportsbook" + index}
                                  className={`form-check-input ${requiredBetHomeFrontEnd.includes(nameBetHomeFilterLower) === true ? 'cus' : ''}`}
                                  name={nameBetHomeFilterLower}
                                  checked={
                                    typeof betHomeFrontEnd === "object" &&
                                    betHomeFrontEnd &&
                                    betHomeFrontEnd.includes(nameBetHomeFilterLower)
                                  }
                                  onChange={EnabledDisablebBethome}
                                />

                                {nameBetHomeFilterLower in warningBetHome ? <img data-tooltip-content={isEnableWarning ? warningBetHomeMessages[warningBetHome[nameBetHomeFilterLower]] : undefined}
                                  data-tooltip-id={isEnableWarning ? 'tooltip-surebet' : undefined} className="style-icon-alert-filter" src={warningBetHomeIcons[warningBetHome[nameBetHomeFilterLower]]} /> : ''}

                                <label
                                  title=""
                                  htmlFor={"casasDeApostasSportsbook" + index}
                                  className="form-check-label white-space-nowrap"
                                  name={nameBetHomeFilterLower}
                                >
                                  {value}

                                </label>
                              </div>
                            </React.Fragment>


                            :
                            <div
                              className={`form-check ms-4 d-flex align-items-center ${statusMode ? "textGray" : ""
                                }`}
                              key={index}
                            >
                              <input
                                type="checkbox"
                                id={"casasDeApostasSportsbook" + index}
                                className={`form-check-input ${requiredBetHomeFrontEnd.includes(value.toLowerCase()) === true ? 'cus' : ''}`}
                                name={value.toLowerCase()}
                                checked={
                                  typeof betHomeFrontEnd === "object" &&
                                  betHomeFrontEnd &&
                                  betHomeFrontEnd.includes(value.toLowerCase())
                                }
                                onChange={EnabledDisablebBethome}
                              />

                              {nameBetHomeFilterLower in warningBetHome ? <img data-tooltip-content={isEnableWarning ? warningBetHomeMessages[warningBetHome[nameBetHomeFilterLower]] : undefined}
                                data-tooltip-id={isEnableWarning ? 'tooltip-surebet' : undefined} className="style-icon-alert-filter" src={warningBetHomeIcons[warningBetHome[nameBetHomeFilterLower]]} /> : ''}

                              <label
                                title=""
                                htmlFor={"casasDeApostasSportsbook" + index}
                                className="form-check-label white-space-nowrap"
                                name={value.toLowerCase()}
                              >
                                {value in NameBetHomeRenamedInFilter ? NameBetHomeRenamedInFilter[value] : value}

                              </label>
                            </div>

                        )
                      })}



                  </div>
                </div>
              </div>
              <div
                className={`border-top ps-0 py-1   ${statusMode ? "bgDark borderColor textGray" : ""
                  }`}
              >

                <Tooltip id="tooltip-surebet" data-tooltip-place='top' style={{ maxWidth: '100%' }} />
                <input
                  type="checkbox"
                  id="show_all_bet_home"
                  className="form-check-input ms-3"
                  checked={betHomeFrontEnd && betHomeFrontEnd.length === quantityAllBetHome}
                  onChange={markAll}
                />

                <label
                  title=""
                  htmlFor="show_all_bet_home"
                  className="form-check-label ps-5"
                  onChange={markAll}
                >
                  Mostrar Tudo
                </label>
              </div>

              <div
                className={`modal-footer  ${statusMode ? "bgDark borderColor textGray" : ""
                  }`}
              >


                <button
                  type="button"
                  className={`btn btn-primary ${statusMode ? "" : ""}`}
                  data-bs-dismiss="modal"
                  onClick={() => {
                    saveFilterBetHome(betHomeFrontEnd, true);



                  }}
                >
                  Aplicar e filtrar
                </button>
              </div>
            </div>
          </div>
        </div>

        <span
          id="btn_filter_sports"
          className={`fw-bolder fs-6 textDecoration text-center ${statusMode ? "textGray" : "textGray-update2"}`}
          data-bs-toggle="modal"
          data-bs-target="#filterSports"
          style={{ cursor: "pointer" }}
          onClick={restoreFrontEndFilter}
        >
          Esportes{" "}
          <span
            id="qt_sports_selected"
            className=""
            style={{
              color: "#ced2d6!important",
              borderRadius: "30%",
              padding: "3px",
              fontSize: "1rem", 
            }}
          >
            {" "}
            <b> ({quantitySportsEnabled}) </b>
          </span>
        </span>
        <div
          className="modal fade alt_margin_x"
          id="filterSports"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog d-flex justify-content-center mt-3">
            <div className="modal-content" style={{ width: "auto" }}>
              <div
                className={`modal-header ${statusMode ? "bgDark borderColor textGray" : ""}`}
              >
                <div className="modal-title h4" id="exampleModalLabel">
                  Esportes
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div
                className={`modal-body d-flex  ${statusMode ? "bgDark" : ""}`}
              >
                <div className="mx-2" style={{ whiteSpace: "nowrap" }}>
                  {sportsPart1 &&
                    sportsPart1.map((value, index) => (
                      <div
                        className={`form-check ms-3 ${statusMode ? "textGray" : ""}`}
                        key={index}
                      >
                        <input
                          type="checkbox"
                          id={"sport" + index}
                          className="form-check-input"
                          name={value.toLocaleLowerCase()}
                          checked={
                            sports &&
                            typeof sports === "object" &&
                            sports.includes(value.toLocaleLowerCase())
                          }
                          onChange={EnabledDisabledSport}
                        />
                        <label
                          title=""
                          htmlFor={"sport" + index}
                          className="form-check-label"
                          name={value.toLowerCase()}
                        >
                          {value.replace("(BR)", "").replace("(PT-BR)", "")}
                        </label>
                      </div>
                    ))}
                </div>

                <div className="mx-2" style={{ whiteSpace: "nowrap" }}>
                  {sportsPart2 &&
                    sportsPart2.map((value, index) => (
                      <div
                        className={`form-check ms-3 ${statusMode ? "textGray" : ""
                          }`}
                        key={index}
                      >
                        <input
                          type="checkbox"
                          id={"otherSport" + index}
                          className="form-check-input"
                          name={value.toLocaleLowerCase()}
                          checked={
                            sports &&
                            typeof sports === "object" &&
                            sports.includes(value.toLocaleLowerCase())
                          }
                          onChange={EnabledDisabledSport}
                        />
                        <label
                          title=""
                          htmlFor={"otherSport" + index}
                          className="form-check-label"
                          name={value.toLowerCase()}
                        >
                          {value}
                        </label>
                      </div>
                    ))}
                </div>

                <div className="ms-2 me-3   " style={{ whiteSpace: "nowrap" }}>
                  <h4 className={`ms-3 ${statusMode ? "textGray" : ""}`}>
                    E-Sports
                  </h4>
                  {esports &&
                    esports.map((value, index) => (
                      <div
                        className={`form-check ms-3 ${statusMode ? "textGray" : ""
                          }`}
                        key={index}
                      >
                        <input
                          type="checkbox"
                          id={"esport" + index}
                          className="form-check-input"
                          name={value.toLocaleLowerCase()}
                          checked={
                            sports &&
                            typeof sports === "object" &&
                            sports.includes(value.toLocaleLowerCase())
                          }
                          onChange={EnabledDisabledSport}
                        />
                        <label
                          title=""
                          htmlFor={"esport" + index}
                          className="form-check-label"
                          name={value.toLowerCase()}
                        >
                          {value}
                        </label>
                      </div>
                    ))}
                  <div
                    className={`form-check border-top ps-0 py-2 mt-3 ${statusMode ? "bgDark borderColor textGray" : ""}`}
                  >
                    <input
                      type="checkbox"
                      id="showAllSports"
                      className="form-check-input ms-3"
                      checked={
                        sports &&
                        allSportsMinuscle &&
                        sports.length === allSportsMinuscle.length
                      }
                      onChange={markAllSports}
                    />
                    <label
                      title=""
                      htmlFor="showAllSports"
                      className="form-check-label ps2new"
                    >
                      Mostrar Tudo
                    </label>
                  </div>
                </div>
              </div>
              <div
                className={`modal-footer  ${statusMode ? "bgDark borderColor textGray" : ""
                  }`}
              >
                <button
                  type="button"
                  className={`btn btn-primary ${statusMode ? "" : ""}`}
                  data-bs-dismiss="modal"
                  onClick={() => {
                    saveFilterSports(sports, true);
                  }}
                >
                  Aplicar e filtrar
                </button>
              </div>
            </div>
          </div>
        </div>

        <span
          id="btn-filter-markets"
          className={`fw-bolder fs-6 textDecoration text-center ${statusMode ? "filter-text-gray" : ""}`}
          style={{ cursor: "pointer" }}
          onClick={handleShowFilterMarket}
        >
          Mercados
          <span
            id="qt-filter-markets"
            className=""
            style={{
              color: "#ced2d6!important",
              borderRadius: "30%",
              padding: "3px",
              fontSize: "1rem",
            }}
          >

            <b> ({quantityMarkets - marketsUnselected.length}) </b>
          </span>
        </span>
        {
          showFilterEvent &&
          <EventDesktop
            handleClose={handleCloseFilterEvent}
            show={showFilterEvent}
            statusMode={statusMode}
            formatDataFilterToSave={formatDataFilterToSave}
          />
        }
        <span
          id="btn_filter_sports"
          className={`fw-bolder fs-6 textDecoration text-center ${statusMode ? "filter-text-gray" : ""}`}
          style={{ cursor: "pointer" }}
          onClick={() => { setShowFilterEvent(!showFilterEvent) }}
        >
          Excluir eventos
          <span
            id="qt_sports_selected"
            className=""
            style={{
              color: "#ced2d6!important",
              borderRadius: "30%",
              padding: "3px",
              fontSize: "1rem",
            }}
          >

            <b> ({blacklistWords.length}) </b>
          </span>
        </span>


        {

          showMarket &&
          <MarketsDesktop
            handleClose={handleCloseFilterMarket}
            show={showMarket}
            formatDataFilterToSave={formatDataFilterToSave}
            statusMode={statusMode}
          />
        }

        {(AparecerLight || AparecerProf) && (
          <>
            <div className="checkbox-wrapper">
              <span
                id="btn_filter_sports"
                className={`fw-bolder fs-6 textDecoration ${statusMode ? "textGray" : "textGray-update2"
                  }`}
                style={{ cursor: "pointer" }}
              >
                <div className="diventradas">Entradas Light </div>
              </span>
              <input
                type="checkbox"
                id={"ligth"}
                className="form-check-input"
                name={"entradaslight"}
                checked={entradasLigth}
                onChange={EnabledDisablebEntradas}
              />
            </div>
          </>
        )}
        {(AparecerPro || AparecerProf) && (
          <>
            <div className="checkbox-wrapper">
              <span
                id="btn_filter_sports"
                className={`fw-bolder fs-6 textDecoration ${statusMode ? "textGray" : "textGray-update2"
                  }`}
                style={{ cursor: "pointer" }}
              >
                <div className="diventradas">Entradas Pro </div>
              </span>
              <input
                type="checkbox"
                id={"pro"}
                className="form-check-input"
                name={"entradaspro"}
                checked={entradasPro}
                onChange={EnabledDisablebEntradas}
              />
            </div>
          </>
        )}



        <button
          type="button"
          id="filter_reset"
          className={`btn btn-primary alt_margin fs-6 mt-3 mb-3   ${statusMode ? "" : ""
            }`}
          onClick={filterReset}
        >
          Resetar Filtros
        </button>
      </div>
    </div>
  );
}
export default Filter;
