import axios from "axios";

export async function loginUserAfterRegistration(credentialUser, setUserData, onLoginSuccess, navigate) {

    const response = await axios.post(
        "https://rd.vps.webdock.cloud:9512/authenticator",
        {
            ...credentialUser,
        }
    );

    if ((response.data.erro === false) === false)   {
        setUserData(response.data);
        onLoginSuccess(true, response.data);
        if (
            response.data.plano == "free" ||
            response.data.plano == "Gratuito" ||
            response.data.plano == "" ||
            response.data.plano == null
        ) {
            navigate("/plano");
        } else {
            navigate("/");
        }
    }
}