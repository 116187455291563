import { listMarketsOnfilterMarket, dangersMarket, listSecondaryMarkets } from "../../variables/VariablesFilterSurebet";
import { useRef, useContext } from "react";
import { Context } from "../../../App";
import IconExclamation from '../../../assets/svg/exclamation.svg';
import { Tooltip } from 'react-tooltip';

const MarketsMobile = (props) => {
    const {
        openPageIndexFilter,
        statusMode,
        saveFilterChanged
    } = props;

    const { marketsUnselected, setMarketsUnselected } = useContext(Context);
    const listMarketsElements = useRef([]);
    const markAllElement = useRef(null);

    let marketsTitles = Object.keys(listSecondaryMarkets);
    let valueToSpace = [];
    for (let i = 0; i < marketsTitles.length; i++) {
        let titleMarket = marketsTitles[i];
        valueToSpace = valueToSpace.concat(listSecondaryMarkets[titleMarket])
    }

    const changeValueCheckbox = (event) => {
        let statusChecked = event.target.checked;

        if (statusChecked === false) {
            markAllElement.current.checked = false;
        } else {
            let listElementsApproved = listMarketsElements.current.filter(item => !item.checked);

            if (listElementsApproved.length === 0) {
                markAllElement.current.checked = true;
            }
        }
    };

    const saveAndApplyFilter = () => {
        let listElementsApproved = listMarketsElements.current.filter(item => !item.checked);
        let listNamesMarketsBlecked = listElementsApproved.map(item => item.name)

        setMarketsUnselected(listNamesMarketsBlecked);
        localStorage.setItem("marketsUnselected", JSON.stringify(listNamesMarketsBlecked));

        saveFilterChanged({ marketsUnselected: listNamesMarketsBlecked })
    }


    const checkUncheckAllSecondaryMarket = (event, nameMarket) => {
        let statusChecked = event.target.checked;

        let listMarkets = listSecondaryMarkets[nameMarket].map(item => item.toLocaleLowerCase().replace(/ /g, ''));

        if (statusChecked === false) {
            markAllElement.current.checked = false;
            for (let i = 0; i < listMarketsElements.current.length; i++) {
                let element = listMarketsElements.current[i];
                let nameElement = element.name;

                if (listMarkets.includes(nameElement) === true) {
                    listMarketsElements.current[i].checked = false;
                }
            }

        } else {

            for (let i = 0; i < listMarketsElements.current.length; i++) {
                let element = listMarketsElements.current[i];
                let nameElement = element.name;

                if (listMarkets.includes(nameElement) === true) {
                    listMarketsElements.current[i].checked = true;
                }
            }

            let listElementsApproved = listMarketsElements.current.filter(item => !item.checked);

            if (listElementsApproved.length === 0) {
                markAllElement.current.checked = true;
            }
        }
    }

    const changeValueCheckboxSecondaryMarkets = (event, nameMarket) => {
        let statusChecked = event.target.checked;

        let nameRootMarket = "";
        let listMarkets = [];
        for (let i = 0; i < marketsTitles.length; i++) {
            let titleMarket = marketsTitles[i];

            if (listSecondaryMarkets[titleMarket].includes(nameMarket) === true) {
                nameRootMarket = titleMarket.toLocaleLowerCase().replace(/ /g, '');
                listMarkets = listSecondaryMarkets[titleMarket].map(item => item.toLocaleLowerCase().replace(/ /g, ''));
                break;
            }
        }

        if (statusChecked === false) {

            markAllElement.current.checked = false;




            let marketsEnabled = listMarketsElements.current.filter((item) => {
                if (listMarkets.includes(item.name) === true && item.checked === true) {
                    return true
                }
                return false
            })
            if (marketsEnabled.length === 0) {
                for (let i = 0; i < listMarketsElements.current.length; i++) {
                    let element = listMarketsElements.current[i];
                    let nameElement = element.name;

                    if (nameElement === nameRootMarket) {
                        listMarketsElements.current[i].checked = false;
                        break;
                    }
                }
            }
        } else {
            for (let i = 0; i < listMarketsElements.current.length; i++) {
                let element = listMarketsElements.current[i];
                let nameElement = element.name;
                if (nameElement === nameRootMarket) {
                    listMarketsElements.current[i].checked = true;
                    break;
                }
            }
            let listElementsApproved = listMarketsElements.current.filter(item => !item.checked);
            if (listElementsApproved.length === 0) {
                markAllElement.current.checked = true;
            }
        }
    };

    const checkUncheckAllMarkets = (event) => {
        let statusChecked = event.target.checked;


        if (statusChecked === true) {
            listMarketsElements.current.forEach((ref) => {
                ref.checked = true;
            });
        } else {
            listMarketsElements.current.forEach((ref) => {
                ref.checked = false;
            });
        }
    }
    return (
        <>


            <div className={`boxHeader border-bottom p-1 py-3 d-flex justify-content-start align-items-center ${statusMode ? "bgDark borderColor textGray" : ""}`}>
                <div className="ms-3 me-2" style={{ width: "min-content", color: "rgb(13,110,253)", cursor: "pointer" }} onClick={openPageIndexFilter}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" fill="currentColor" className="bi bi-arrow-left-square-fill filter-svg-back-page" viewBox="0 0 16 16">
                        <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z">
                        </path>
                    </svg>
                </div>
                <div className="boxOptions">
                    <p className="p-0 m-0 fw-semibold text-center fs-5">Mercados</p>
                </div>
            </div>
            <div id="allCheckBox" className=" ps-4 d-flex flex-column filter fs-6" style={{ height: "370px", overflow: "overlay" }}>
                <Tooltip id="tooltip-filter-market" data-tooltip-place='top' style={{ maxWidth: '100%', zIndex: "9999999" }} />
                {
                    listMarketsOnfilterMarket.map((value, index) => {
                        let itemLower = value.toLocaleLowerCase().replace(/ /g, '');
                        let isSecondaryMarket = valueToSpace.includes(value) ? true : false;

                        return (

                            <div className={`form-check ${statusMode ? "textGray" : ""} ${isSecondaryMarket ? "ms-4" : ""} overflow-hidden`} key={index}>
                                <input
                                    type="checkbox"
                                    id={`market-${itemLower}`}
                                    className="form-check-input"
                                    name={itemLower}
                                    defaultChecked={marketsUnselected.includes(itemLower) === false}
                                    onChange={(event) => {
                                        if (isSecondaryMarket === true) {
                                            changeValueCheckboxSecondaryMarkets(event, value);
                                        }
                                        else if (marketsTitles.includes(value) === true) {
                                            checkUncheckAllSecondaryMarket(event, value)
                                        }

                                        else {
                                            changeValueCheckbox(event)
                                        }
                                    }}
                                    ref={(el) => {
                                        if (el) {
                                            listMarketsElements.current[index] = el
                                        };
                                    }}
                                />

                                <div className="d-flex">
                                    <label htmlFor={`market-${itemLower}`} className="form-check-label d-flex ps-0" name={value.toLowerCase()}>


                                        {value}
                                    </label>

                                    {

                                        dangersMarket.includes(value) ?
                                            <img
                                                data-tooltip-content="Mercado não recomendado"
                                                data-tooltip-id="tooltip-filter-market"
                                                class="style-icon-alert-filter ms-1 "
                                                src={IconExclamation}
                                                alt="Logo mercado não recomendado"
                                            />
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        )
                    }
                    )
                }

            </div>
            <div className={`d-flex py-2 align-item-center border-top ps-4 fs-5 ${statusMode ? "bgDark borderColor textGray" : ""}`}>
                <input
                    type="checkbox"
                    id="show-all-markets"
                    className="form-check-input"
                    defaultChecked={marketsUnselected.length === 0 ? true : false}
                    onChange={checkUncheckAllMarkets}
                    ref={(el) => {
                        if (el) {
                            markAllElement.current = el;
                        }
                    }
                    }
                />
                <label title="" htmlFor="show_all_bet_home" className="form-check-label ps-2">Mostrar Tudo</label>
            </div>
            <div className={`d-flex justify-content-end align-item-center border-top ${statusMode ? "bgDark borderColor textGray" : ""}`}>
                <button className={`btn btn-primary mt-2 me-3 fs-6 ${statusMode ? "" : ""}`} onClick={() => {
                    saveAndApplyFilter();
                    openPageIndexFilter();
                }}>Aplicar e Filtrar</button>
            </div>

        </>
    )
}

export default MarketsMobile;