import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import MainBets from "./components/MainBets";
import PayamentMP from "./components/PayamentMP";
import React, { useState, useEffect, Suspense, createContext, useRef } from "react";
import { localStorageManagerReturn } from "./global/GlobalFunctions";
import HeaderWebsite from './components/HeaderWebsite'
import HeaderCalculator from './components/HeadrCalculator'
import Calculator from './components/Calculator';
import EmptyCalculator from "./components/EmptyCalculator"
import Filter from "./components/Filter";
import HideSurebet from "./components/HideSurebet";
import axios from "axios";
import AuthenticationForm from "./components/AuthenticationForm";
import SubNavbar from "./components/SubNavbar";
import LandingPage from "./components/LandingPage";
import Renovacao from "./components/PageCompraRenovacao";
import Afilhado from "./components/Afilhado";
import DashboardControl from "./components/DashboardControl";
import ConfigPage from "./components/ConfigPage";
import AfiliadoLink from "./components/AfiliadoLink";
import HeaderPagamentos from "./components/HeaderPagamentos";
import Sobrenos from "./components/sobrenos";
import Termoscondicoes from "./components/Termoscodincoes";
import Cookies from 'js-cookie';
import { getFilterArbitragem } from "./components/functions/FunctionsFilter";
import PageDownloadExtension from "./pages/PageDownloadExtension";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { FilterHead } from "./hooks/FilterHead";
import { decrypt_game } from "./components/functions/FunctionsGames";
import { URL_SAFE_BET, URL_DADOSPRE } from "./components/variables/VariablesGame";
export const Context = createContext();


const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");

  useEffect(() => {
    function clearCacheAndCookies() {
      window.location.reload(); // Recarrega a página, limpando o cache e cookies
    }

    clearCacheAndCookies(); // Chama a função uma vez quando a página é carregada

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    axios
      .get(
        `https://rd.vps.webdock.cloud:9512/session-status?session_id=${sessionId}`,
        {}
      )
      .then(function (response) {
        setStatus(JSON.parse(localStorage["statusMode"]));

        setCustomerEmail(response.data.customer_email);
      })

      .catch((error) => {
        console.error("Erro ao criar a assinatura:", error);
        // Lida com erros, se houver
      });
  }, []);

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to{" "}
          {customerEmail}. If you have any questions, please email
          <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    );
  }

  return null;
};






function App() {
  //Cookies.set('cookie_login', 'kpd-cccp-kgb', { expires: 7 }); // Expires in 7 days

  /*useEffect(() => {
    
    const handleUnload = () => {
      // Chame a função de logout aqui
      handleLogout();
    };

    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);*/



  const location = useLocation();
  const [userPlan, setUserPlan] = useState("free");
  const [linkCopied, setLinkCopied] = useState(false);
  const [popupWindow, setPopupWindow] = useState(null);
  const [popuwindowsBet, setPopupWindowBet] = useState(null);
  const [filterOrder, setFilterOrder] = useState(localStorageManagerReturn('classificationSurebet', 'lucro'));
  
  const handleCopyLink = () => {
    setLinkCopied(true);
  };

  const [canOpenCalculator, setCanOpenCalculator] = useState(false);

  const handleGenerateUniqueCode = (code) => {

    // Você também pode enviar o código ao servidor a partir daqui, se necessário.
    // onGenerateUniqueCode(code);
  };
  let valueObjects = [];
  const controllerLocalStorage = FilterHead();
  controllerLocalStorage["readLocalstorage"]();
  const [fullData, setFullData] = useState([]);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage["userData"])
  );
  // Entradas Ligth e Pro
  const [entradasLigth, setentradasLigth] = useState(
    JSON.parse(localStorage["entradaslight"])
  );
  const [entradasPro, setentradasPro] = useState(
    JSON.parse(localStorage["entradaspro"])
  );

  const clearOldSurebet = useRef(false);

  //Setar Mensagem
  const [DateMsg, setDateMsg] = useState(
    JSON.parse(localStorage["preferenceData"])
  );

  const [valueMin, setValueMin] = useState(
    JSON.parse(localStorage["profitMin"])
  );
  const [valueMax, setValueMax] = useState(
    JSON.parse(localStorage["profitMax"])
  );
  const [betHome, setBetHome] = useState(
    JSON.parse(localStorage["listBehomeEnabled"])
  );
  const [sports, setSports] = useState(
    JSON.parse(localStorage["listSportsEnabled"])
  );
  const [quantitySportsEnabled, setQuantitySportsEnabled] = useState(
    JSON.parse(localStorage["listSportsEnabled"]).length
  );
  const [quantityBetHomeEnabled, setQuantityBetHomeEnabled] = useState(
    JSON.parse(localStorage["listBehomeEnabled"]).length
  );
  const [AparecerProf, setAparecerProf] = useState(false);
  const [AparecerVivo, setAparecerVivo] = useState(false);
  const [AparecerLight, setAparecerLight] = useState(false);
  const [AparecerPro, setAparecerPro] = useState(false);

  const [timeEvent, setTimeEvent] = useState(null);
  const [itemsTrash, setItemsTrash] = useState(
    JSON.parse(localStorage["trash"])
  );
  const [statusMode, setStatusMode] = useState(
    JSON.parse(localStorage["statusMode"])
  );
  const [login, setLogin] = useState(JSON.parse(localStorage["login"]));
  const showLogoutButton = location.pathname === "/";


  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage["login"])
  );
  const [listBehomeEnableds, setlistBehomeEnableds] = useState(
    JSON.parse(localStorage["listBehomeEnabled"])
  );
  const [notificationVolume, setNotificationVolume] = useState(null);
  const  [marketsUnselected, setMarketsUnselected] = useState([]);
  const [blacklistWords, setBlacklistWords] = useState([]);

  const isCalculatorPage = location.pathname.includes("/calculator/");
  
  const isCalculatorPages = location.pathname.startsWith("/calculator/");
  const isPayamentPages = location.pathname.startsWith("/payament/");

  const [Idinitialization, setIdinitialization] = useState(
    JSON.parse(localStorage["Idinitialization"])
  );
  const [Amountinitialization, setAmountinitialization] = useState(
    JSON.parse(localStorage["Amountinitialization"])
  );
  const [requiredBetHome, setRequiredBetHome] = useState([]);

  const [canCheckLogin, setCanCheckLogin] = useState(false);
  const skipAutoLogin = useRef(false);

  const handleLogout = () => {
    // Limpe o estado de login e remova-o do armazenamento local
    // Do something when the correct password is entered

    window.localStorage.setItem("login", JSON.stringify(false));
    setLogin(false);
    setIsLoggedIn(false);

    saveUserPreferencesLogout();
  };

  // Função para enviar os dados para o servidor
  const saveUserPreferencesLogout = async () => {
    if (userData) {
      try {
        const response = await axios.post(
          "https://rd.vps.webdock.cloud:9512/saveuserpreference",
          {
            // Coloque seus dados aqui, por exemplo:
            id: userData.id,
            userPreferences: {
              modo: statusMode,
              ValueMin: JSON.parse(localStorage["profitMin"]),
              ValueMax: JSON.parse(localStorage["profitMax"]),
              BetHomes: betHome,
              QtdEnableBet: quantityBetHomeEnabled,
              sports: sports,
              QtdEnableSport: quantitySportsEnabled,
              setTtempo: timeEvent,
              volume: notificationVolume,
              listBehomeEnabled: quantityBetHomeEnabled,
              listSportsEnabled: quantitySportsEnabled,
              login: false,
            },
          }
        );
      } catch (error) {
        console.error("Erro ao enviar dados:", error);
      }
    }
  };
  // Dentro da função handleLoginSuccess
  const handleLoginSuccess = async (isLoggedIn, userData) => {
    let dados;
    while (true) {
      dados = await updateUserPreferences(userData);

      if (dados) {
        break; // Saia do loop se dados for verdadeiro
      }

      // Aguarde algum tempo antes de tentar novamente (opcional)
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    window.localStorage.setItem("login", JSON.stringify(true));
    setIsLoggedIn(isLoggedIn);
  };

  const FectDataC = async () => {

    if (localStorage.getItem('dataCalculator') === null) {
      const b4726486 = Cookies.get('b4726486');

      
      if(b4726486 === undefined){
        return undefined;
      }

     

      const response = await axios.post(URL_SAFE_BET,
        {
          'b652242': b4726486
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        }
      );
      if (response.status !== 200) throw new Error("Falha na requisição");

      let oldDataJsonConvertted;
      try {
        oldDataJsonConvertted = decrypt_game(response.data);
      } catch (error) {
        console.error("Erro ao analisar response.data:", error);
        oldDataJsonConvertted = {};
      }

      Object.keys(oldDataJsonConvertted).forEach((key) => {
        if (!oldDataJsonConvertted.hasOwnProperty(key)) return;
        const dataBet = oldDataJsonConvertted[key]?.main || {};


        Object.entries(oldDataJsonConvertted[key]?.group_events || {}).forEach(
          ([subKey, subValue]) => {


            if (
              subValue.main.profit === dataBet.profit &&
              subValue.main.first_home_bet.name_bet_home ===
              dataBet.first_home_bet.name_bet_home &&
              subValue.main.second_home_bet.name_bet_home ===
              dataBet.second_home_bet.name_bet_home
            )
              return;
            const subValueMain = subValue.main.profit;
            const newTitle = subValue.main.first_home_bet.title_event;
            const FirstName = subValue.main.first_home_bet.name_bet_home;
            const SecondName = subValue.main.second_home_bet.name_bet_home;

            const newSubKey = subValueMain.replace("%", "").replace(",", "") + newTitle.replace(/[^\w\s]/gi, "").replace(/\s+/g, "") + FirstName.replace(/[^\w\s]/gi, "").replace(/\s+/g, "") + SecondName.replace(/[^\w\s]/gi, "").replace(/\s+/g, "");

            subValue.main.other_key_bet = subValueMain;
            valueObjects[newSubKey] = subValue;

            valueObjects[newSubKey].group_events = {};
          }
        );
      });


      const responses = await axios.post(URL_DADOSPRE,
        {
          'b652242': b4726486
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        }
      );
      if (responses.status !== 200) throw new Error("Falha na requisição");


      const oldDataJsonConvertted_pre = decrypt_game(responses.data);;

      let valueObjectcombinado = {
        ...oldDataJsonConvertted,
        ...oldDataJsonConvertted_pre,
        ...valueObjects,
      };


      localStorage.setItem('dataCalculator', JSON.stringify(valueObjectcombinado));
      setTimeout(() => {
        setCanOpenCalculator(true)
      }, 1000);
    } else {
      setTimeout(() => {
        setCanOpenCalculator(true)
      }, 100);
    }

  };

  // Checar a Sessão do usuario
  async function checkSessiondID(sessionIDs) {
    const statusLogin = JSON.parse(localStorage.getItem("login"));

    if (sessionIDs) {
      try {
        const concurrentLoginResponse = await axios.post(
          "https://rd.vps.webdock.cloud:9512/api/check-concurrent-session",
          {
            userID: sessionIDs, // Use o ID retornado com sucesso
          }
        );

        const checkdata = concurrentLoginResponse.data.concurrentLogin;

        if (!checkdata) {
          // Se o usuário já estiver logado em outro lugar, mostre uma mensagem de erro

          setIsLoggedIn(false);
          saveUserPreferencesLogout();
          setUserData(null);
          setUserData(null);
          handleLogout();
          return null;
        }
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 200 range
          console.error('Error response:', error.response);
        } else if (error.request) {
          // Request was made but no response was received
          console.error('Error request:', error.request);
        } else {
          // Something happened in setting up the request
          console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
        // Optionally, you can handle the error more gracefully here
      }
    } else {
      return null;
    }
  }

  useEffect(() => {

    if (userData == null) {

      setIsLoggedIn(false);
      setLogin(false);
      window.localStorage.setItem("login", JSON.stringify(false));
    }
    if (isLoggedIn && !isPayamentPages) {
      const dados = JSON.parse(localStorage.getItem("userData"));

      handleLoginSuccess(isLoggedIn, dados);
    } else {
      setCanCheckLogin(true);
    }
    FectDataC();
    const FectDataCInterval = setTimeout(() => {
      FectDataC();
    }, 5000);
    

    let marketsUnselectedLocalStorage = localStorage.getItem("marketsUnselected");
    let blacklistWordsLocalStorage = localStorage.getItem("blacklistWords");

    if(marketsUnselectedLocalStorage !== null && marketsUnselectedLocalStorage !== undefined){
      let marketsUnselected = JSON.parse(marketsUnselectedLocalStorage);
      setMarketsUnselected(marketsUnselected);  
    }
    
    if(blacklistWordsLocalStorage !== null && blacklistWordsLocalStorage !== undefined){
      let blacklistWordsStorage = JSON.parse(blacklistWordsLocalStorage);
      setBlacklistWords(blacklistWordsStorage);  
    }

    return () => clearInterval(FectDataCInterval);
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    //🔴
      if (userData && userData.status_da_conta != "Ativo") {
        if (isLoggedIn && isPayamentPages) {
          const loadUsers = () => {
            axios
              .post("https://rd.vps.webdock.cloud:9512/login", {
                email: userData.email,
                senha: userData.senha,
              })
              .then((response) => {
                const dado = response.data;
                if (dado) {

                  if (dado.status_da_conta != "Inativo") {

                    setUserData(response.data);
                    //navigate("/");
                  }
                }
              });
          };

          loadUsers();
        }
      }

      if (userData) {
        checkSessiondID(userData.sessionID);
      }
    //🔴
    
  }, [userData]);
  
  useEffect(() => {
    getFilterArbitragem(
      setBetHome,
      setSports,
      setFilterOrder,
      setTimeEvent,
      setValueMin,
      setValueMax,
      setQuantityBetHomeEnabled,
      setQuantitySportsEnabled,
      setMarketsUnselected,
      setBlacklistWords
    );
    let requiredBetHomeSave = localStorageManagerReturn('riquiredsBethome', []);
    if (Array.isArray(requiredBetHomeSave) === true) {
      let listFilteredRiquiredBetHome = []

      setRequiredBetHome(requiredBetHomeSave)
    };



    statusMode
      ? (document.body.style.backgroundColor = "#0d0d0d")
      : (document.body.style.backgroundColor = "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Função 'para' enviar os dados para o servidor
  const updateUserPreferences = async (userData) => {
    try {
      const userID = userData.id; // ID que você deseja passar para a pesquisa
      let varcheck = JSON.parse(localStorage["listBehomeEnabled"]);

      // Construa a URL com o ID como parâmetro
      const url = `https://rd.vps.webdock.cloud:9512/getuserpreferences/${userID}`;
      // Envie a solicitação GET com o ID como parte da URL
      const response = await axios.get(url);
      while (response) {
        // Lide com a resposta do servidor, se necessário

        const dataresponse = response.data;
        const userPreferences = dataresponse.userPreferences;

        setlistBehomeEnableds(varcheck);

        try {
          window.localStorage.setItem(
            "listBehomeEnabled",
            JSON.stringify(varcheck)
          );
          setlistBehomeEnableds(varcheck);
        } catch (error) {
          console.error("Erro ao adicionar valor ao localStorage:", error);
        }


        setStatusMode(JSON.parse(localStorage["statusMode"]));
        window.localStorage.setItem("statusMode", JSON.stringify(statusMode));



        if (userPreferences.setTtempo === null) {

          let statusTime = localStorage.getItem('time');
          if (statusTime !== null) {

            setTimeEvent(JSON.parse(statusTime));
          } else {

            setTimeEvent('Qualquer Horário');
          }
        }
        setNotificationVolume(userPreferences.volume);
        setQuantityBetHomeEnabled(userPreferences.QtdEnableBet);
        setQuantitySportsEnabled(userPreferences.QtdEnableSport);
        setLogin(userPreferences.login);
        setIsLoggedIn(userPreferences.login);

        let responses = {};
        if (skipAutoLogin.current === false) {
          responses = await axios.post(
            "https://rd.vps.webdock.cloud:9512/authenticator",
            {
              ...userData,
            }
          );
        }
        else {
          responses["data"] = userData
        }

        window.localStorage.setItem("userData", JSON.stringify(responses.data));
        window.localStorage.setItem("statusMode", JSON.stringify(statusMode));
        Cookies.set('b4726486', responses.data.sessionID, { expires: 3 });
        setCanCheckLogin(true);
        getFilterArbitragem(
          setBetHome,
          setSports,
          setFilterOrder,
          setTimeEvent,
          setValueMin,
          setValueMax,
          setQuantityBetHomeEnabled,
          setQuantitySportsEnabled,
          setMarketsUnselected,
          setBlacklistWords
        );
        setUserData(responses.data);
        setUserPlan(responses.data.plano);

        let userPlanLower = responses.data.plano.toLowerCase();
        if (userPlanLower === "free" || userPlanLower === "gratuito") {
          navigate('/plano')
        }
        return true;
      }
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
      return false;
    }
  };
  

  return (

    <>
      <Routes>
        <Route path="/extensao" element={
          <>
            {
              canCheckLogin ?
                <>
                  <PageDownloadExtension
                    statusMode={statusMode}
                    setStatusMode={setStatusMode}
                    handleLogout={handleLogout}
                    isLoggedIn={isLoggedIn}
                    showLogoutButton={showLogoutButton}
                    notificationVolume={notificationVolume}
                    setNotificationVolume={setNotificationVolume}
                    userData={userData}
                  />
                </>
                :
                ""
            }
          </>
        } />
      </Routes>

      {location.pathname !== '/extensao' ?
        <div className="App">
          {isPayamentPages ? (
            <>
              <HeaderCalculator
                statusMode={statusMode}
                setStatusMode={setStatusMode}
                onLogout={handleLogout}
                isLoggedIn={isLoggedIn}
                showLogoutButton={showLogoutButton}
              />

              <PayamentMP
                statusMode={statusMode}
                isLoggedIn={isLoggedIn}
                userData={userData}
                user={userData}
                Idinitialization={Idinitialization}
                Amountinitialization={Amountinitialization}
                DateMsg={DateMsg}
                setDateMsg={setDateMsg}
              />

            </>
          ) : isLoggedIn === false ? (
            <div>
              {isLoggedIn === false &&
                location.pathname !== "/logar" &&
                location.pathname !== "/afiliado" &&
                location.pathname !== "/quesomos" &&
                location.pathname !== "/termoscondicoes" &&
                location.pathname !== "/curso" &&
                location.pathname !== "/calculator/" &&
                location.pathname !== "/calculator/*" &&
                location.pathname !== "/calculator/:id" &&
                location.pathname !== "/payament/:id/:id2/:id3" &&
                !isCalculatorPage &&
                !isCalculatorPages &&
                !isPayamentPages ? (
                <div>

                  <Suspense fallback={<div>Loading...</div>}>
                    <SubNavbar
                      statusMode={statusMode}
                      setStatusMode={setStatusMode}
                      onLogout={handleLogout}
                      isLoggedIn={isLoggedIn}
                      showLogoutButton={showLogoutButton}
                      handleLoginSuccess={handleLoginSuccess}
                    />
                  </Suspense>
                </div>
              ) : null}
              {isLoggedIn === false ? (
                <>
                  <Routes>
                    <Route
                      path="/logar"
                      element={
                        <Suspense fallback={<div>Loading...</div>}>
                          <AuthenticationForm
                            statusMode={statusMode}
                            onLoginSuccess={handleLoginSuccess}
                            handleLogout={handleLogout}
                            setUserData={setUserData}
                            skipAutoLogin={skipAutoLogin}
                          />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/afiliado"
                      element={
                        <Suspense fallback={<div>Loading...</div>}>
                          <Afilhado />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/curso"
                      element={
                        <Suspense fallback={<div>Loading...</div>}>
                          <LandingPage />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/quesomos"
                      element={
                        <Suspense fallback={<div>Loading...</div>}>
                          <Sobrenos />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/termoscondicoes"
                      element={
                        <Suspense fallback={<div>Loading...</div>}>
                          <Termoscondicoes />
                        </Suspense>
                      }
                    />
                    <Route
                      path={"/calculator"}
                      element={
                        <>

                          <HeaderCalculator
                            statusMode={statusMode}
                            setStatusMode={setStatusMode}
                            onLogout={handleLogout}
                            isLoggedIn={isLoggedIn}
                            showLogoutButton={showLogoutButton}
                          />


                          <EmptyCalculator
                            statusMode={statusMode}
                            isLoggedIn={isLoggedIn}
                          />

                        </>
                      }
                    />

                    <Route
                      path={"/calculator/:id"}
                      element={
                        <>
                          {
                            canOpenCalculator ?
                              <>

                                <HeaderCalculator
                                  statusMode={statusMode}
                                  setStatusMode={setStatusMode}
                                  onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                                  isLoggedIn={isLoggedIn}
                                  showLogoutButton={showLogoutButton}
                                />



                                <Calculator
                                  statusMode={statusMode}
                                  isLoggedIn={isLoggedIn}
                                />

                              </>
                              :
                              ""
                          }
                        </>
                      }
                    />

                    <Route
                      path={"/calculator/:id/:id2"}
                      element={
                        <>

                          {
                            canOpenCalculator ?
                              <>

                                <HeaderCalculator
                                  statusMode={statusMode}
                                  setStatusMode={setStatusMode}
                                  onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                                  isLoggedIn={isLoggedIn}
                                  showLogoutButton={showLogoutButton}
                                />



                                <Calculator
                                  statusMode={statusMode}
                                  isLoggedIn={isLoggedIn}
                                />

                              </>
                              :
                              ""
                          }
                        </>
                      }
                    />
                    <Route
                      path="/payament/:id/:id2/:id3"
                      element={
                        <>

                          <HeaderCalculator
                            statusMode={statusMode}
                            setStatusMode={setStatusMode}
                            onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                            isLoggedIn={isLoggedIn}
                            showLogoutButton={showLogoutButton}
                          />

                          <PayamentMP
                            statusMode={statusMode}
                            isLoggedIn={isLoggedIn}
                            userData={userData}
                            user={userData}
                            DateMsg={DateMsg}
                            setDateMsg={setDateMsg}
                          />

                        </>
                      }
                    />
                  </Routes>
                </>
              ) : null}
            </div>
          ) : (
            <>
              {userPlan === "free" || userPlan === "Gratuito" ? (
                <Routes>
                  <Route
                    path="/plano"
                    element={
                      <>
                        <Suspense fallback={<div>Loading...</div>}>
                          <HeaderPagamentos
                            setIsLoggedIn={setIsLoggedIn}
                            userData={userData}
                            user={userData}
                            setIdinitialization={setIdinitialization}
                            setAmountinitialization={setAmountinitialization}
                            Idinitialization={Idinitialization}
                            DateMsg={DateMsg}
                            setDateMsg={setDateMsg}
                            setUserData={setUserData}
                          />
                        </Suspense>
                      </>
                    }
                  />
                </Routes>
              ) : (
                <>
                  <>

                    {isCalculatorPage && isCalculatorPages ? (
                      <></>
                    ) : (
                      <HeaderWebsite
                        statusMode={statusMode}
                        setStatusMode={setStatusMode}
                        onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                        isLoggedIn={isLoggedIn}
                        showLogoutButton={showLogoutButton}
                        notificationVolume={notificationVolume}
                        setNotificationVolume={setNotificationVolume}
                        userData={userData}
                        user={userData}
                      />
                    )}

                    <Routes>
                      <Route
                        path={"/"}
                        element={
                          <Context.Provider value={{marketsUnselected, setMarketsUnselected, clearOldSurebet, blacklistWords, setBlacklistWords, statusMode}}>
                          <div className="d-flex">

                            <MainBets
                              valueMin={valueMin}
                              valueMax={valueMax}
                              betHome={betHome}
                              sports={sports}
                              fullData={fullData}
                              setFullData={setFullData}
                              timeEvent={timeEvent}
                              itemsTrash={itemsTrash}
                              setItemsTrash={setItemsTrash}
                              statusMode={statusMode}
                              controllerLocalStorage={controllerLocalStorage}
                              setValueMin={setValueMin}
                              setValueMax={setValueMax}
                              setBetHome={setBetHome}
                              quantityBetHomeEnabled={quantityBetHomeEnabled}
                              setQuantityBetHomeEnabled={setQuantityBetHomeEnabled}
                              setSports={setSports}
                              quantitySportsEnabled={quantitySportsEnabled}
                              setQuantitySportsEnabled={setQuantitySportsEnabled}
                              setTimeEvent={setTimeEvent}
                              onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                              isLoggedIn={isLoggedIn}
                              showLogoutButton={showLogoutButton}
                              notificationVolume={notificationVolume}
                              setPopupWindow={setPopupWindow}
                              popupWindow={popupWindow}
                              setPopupWindowBet={setPopupWindowBet}
                              popuwindowsBet={popuwindowsBet}
                              user={userData}
                              handleLogout={handleLogout}
                              setIsLoggedIn={setIsLoggedIn}
                              setUserData={setUserData}
                              setlistBehomeEnableds={setlistBehomeEnableds}
                              setentradasLigth={setentradasLigth}
                              entradasLigth={entradasLigth}
                              setentradasPro={setentradasPro}
                              entradasPro={entradasPro}
                              setAparecerProf={setAparecerProf}
                              setAparecerVivo={setAparecerVivo}
                              setAparecerLight={setAparecerLight}
                              setAparecerPro={setAparecerPro}
                              AparecerProf={AparecerProf}
                              AparecerVivo={AparecerVivo}
                              AparecerLight={AparecerLight}
                              AparecerPro={AparecerPro}
                              filterOrder={filterOrder}
                              setFilterOrder={setFilterOrder}
                              requiredBetHome={requiredBetHome}
                              setRequiredBetHome={setRequiredBetHome}
                            />


                            <Filter
                              statusMode={statusMode}
                              controllerLocalStorage={controllerLocalStorage}
                              valueMin={valueMin}
                              setValueMin={setValueMin}
                              valueMax={valueMax}
                              setValueMax={setValueMax}
                              betHome={betHome}
                              setBetHome={setBetHome}
                              quantityBetHomeEnabled={quantityBetHomeEnabled}
                              setQuantityBetHomeEnabled={
                                setQuantityBetHomeEnabled
                              }
                              sports={sports}
                              setSports={setSports}
                              quantitySportsEnabled={quantitySportsEnabled}
                              setQuantitySportsEnabled={setQuantitySportsEnabled}
                              timeEvent={timeEvent}
                              setTimeEvent={setTimeEvent}
                              setlistBehomeEnableds={setlistBehomeEnableds}
                              setentradasLigth={setentradasLigth}
                              entradasLigth={entradasLigth}
                              setentradasPro={setentradasPro}
                              entradasPro={entradasPro}
                              AparecerProf={AparecerProf}
                              AparecerLight={AparecerLight}
                              AparecerPro={AparecerPro}
                              filterOrder={filterOrder}
                              setFilterOrder={setFilterOrder}
                              requiredBetHome={requiredBetHome}
                              setRequiredBetHome={setRequiredBetHome}
                            />

                          </div>
                          </Context.Provider>


                        }
                      />

                      <Route
                        path={"/:id"}
                        element={
                          <div className="d-flex">
                            <Suspense fallback={<div>Loading...</div>}>
                              <HideSurebet
                                valueMin={valueMin}
                                valueMax={valueMax}
                                betHome={betHome}
                                sports={sports}
                                fullData={fullData}
                                setFullData={setFullData}
                                timeEvent={timeEvent}
                                itemsTrash={itemsTrash}
                                setItemsTrash={setItemsTrash}
                                statusMode={statusMode}
                                controllerLocalStorage={controllerLocalStorage}
                                setValueMin={setValueMin}
                                setValueMax={setValueMax}
                                setBetHome={setBetHome}
                                quantityBetHomeEnabled={quantityBetHomeEnabled}
                                setQuantityBetHomeEnabled={
                                  setQuantityBetHomeEnabled
                                }
                                setSports={setSports}
                                quantitySportsEnabled={quantitySportsEnabled}
                                setQuantitySportsEnabled={setQuantitySportsEnabled}
                                setTimeEvent={setTimeEvent}
                                setPopupWindow={setPopupWindow}
                                setPopupWindowBet={setPopupWindowBet}
                                popupWindow={popupWindow}
                                popuwindowsBet={popuwindowsBet}
                                setentradasLigth={setentradasLigth}
                                entradasLigth={entradasLigth}
                                setentradasPro={setentradasPro}
                                entradasPro={entradasPro}
                                filterOrder={filterOrder}
                                setFilterOrder={setFilterOrder}
                              />
                            </Suspense>
                            <Suspense fallback={<div>Loading...</div>}>
                              <Filter
                                statusMode={statusMode}
                                controllerLocalStorage={controllerLocalStorage}
                                valueMin={valueMin}
                                setValueMin={setValueMin}
                                valueMax={valueMax}
                                setValueMax={setValueMax}
                                betHome={betHome}
                                setBetHome={setBetHome}
                                quantityBetHomeEnabled={quantityBetHomeEnabled}
                                setQuantityBetHomeEnabled={
                                  setQuantityBetHomeEnabled
                                }
                                sports={sports}
                                setSports={setSports}
                                quantitySportsEnabled={quantitySportsEnabled}
                                setQuantitySportsEnabled={setQuantitySportsEnabled}
                                timeEvent={timeEvent}
                                setTimeEvent={setTimeEvent}
                                setlistBehomeEnableds={setlistBehomeEnableds}
                                setentradasLigth={setentradasLigth}
                                entradasLigth={entradasLigth}
                                setentradasPro={setentradasPro}
                                entradasPro={entradasPro}
                                filterOrder={filterOrder}
                                setFilterOrder={setFilterOrder}
                              />
                            </Suspense>
                          </div>
                        }
                      />
                      <Route
                        path={"/calculator"}
                        element={
                          <>

                            <HeaderCalculator
                              statusMode={statusMode}
                              setStatusMode={setStatusMode}
                              onLogout={handleLogout}
                              isLoggedIn={isLoggedIn}
                              showLogoutButton={showLogoutButton}
                            />

                            <EmptyCalculator
                              statusMode={statusMode}
                              isLoggedIn={isLoggedIn}
                            />
                          </>
                        }
                      />

                      <Route
                        path={"/calculator/:id"}
                        element={
                          <>
                            {
                              canOpenCalculator ?
                                <>

                                  <HeaderCalculator
                                    statusMode={statusMode}
                                    setStatusMode={setStatusMode}
                                    onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                                    isLoggedIn={isLoggedIn}
                                    showLogoutButton={showLogoutButton}
                                  />



                                  <Calculator
                                    statusMode={statusMode}
                                    isLoggedIn={isLoggedIn}
                                  />

                                </>
                                :
                                ""
                            }
                          </>
                        }
                      />

                      <Route
                        path={"/calculator/:id/:id2"}
                        element={
                          <>
                            {
                              canOpenCalculator ?
                                <>

                                  <HeaderCalculator
                                    statusMode={statusMode}
                                    setStatusMode={setStatusMode}
                                    onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                                    isLoggedIn={isLoggedIn}
                                    showLogoutButton={showLogoutButton}
                                  />



                                  <Calculator
                                    statusMode={statusMode}
                                    isLoggedIn={isLoggedIn}
                                  />

                                </>
                                :
                                ""
                            }
                          </>
                        }
                      />
                      <Route
                        path="/dashboard"
                        element={
                          <Suspense fallback={<div>Loading...</div>}>
                            <DashboardControl
                              statusMode={statusMode}
                              setStatusMode={setStatusMode}
                              isLoggedIn={isLoggedIn}
                            />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/config"
                        element={
                          <>
                            <Suspense fallback={<div>Loading...</div>}>
                              <ConfigPage
                                valueMin={valueMin}
                                valueMax={valueMax}
                                betHome={betHome}
                                sports={sports}
                                fullData={fullData}
                                setFullData={setFullData}
                                timeEvent={timeEvent}
                                itemsTrash={itemsTrash}
                                setItemsTrash={setItemsTrash}
                                statusMode={statusMode}
                                controllerLocalStorage={controllerLocalStorage}
                                setValueMin={setValueMin}
                                setValueMax={setValueMax}
                                setBetHome={setBetHome}
                                quantityBetHomeEnabled={quantityBetHomeEnabled}
                                setQuantityBetHomeEnabled={
                                  setQuantityBetHomeEnabled
                                }
                                setSports={setSports}
                                quantitySportsEnabled={quantitySportsEnabled}
                                setQuantitySportsEnabled={setQuantitySportsEnabled}
                                setTimeEvent={setTimeEvent}
                                setStatusMode={setStatusMode}
                                onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                                isLoggedIn={isLoggedIn}
                                showLogoutButton={showLogoutButton}
                                notificationVolume={notificationVolume}
                                setNotificationVolume={setNotificationVolume}
                                setPopupWindow={setPopupWindow}
                                popupWindow={popupWindow}
                                setPopupWindowBet={setPopupWindowBet}
                                popuwindowsBet={popuwindowsBet}
                                userData={userData}
                                user={userData}
                                handleLogout={handleLogout}
                                setIsLoggedIn={setIsLoggedIn}
                                setIdinitialization={setIdinitialization}
                                setAmountinitialization={setAmountinitialization}
                                Idinitialization={Idinitialization}
                                setUserData={setUserData}
                              />
                            </Suspense>
                          </>
                        }
                      />
                      <Route
                        path="/gerarlink"
                        element={
                          <>
                            <Suspense fallback={<div>Loading...</div>}>
                              <AfiliadoLink
                                onCopyLink={handleCopyLink}
                                onGenerateUniqueCode={handleGenerateUniqueCode}
                                user={userData}
                              />
                              {linkCopied && (
                                <p>Link copiado para a área de transferência!</p>
                              )}
                            </Suspense>
                          </>
                        }
                      />
                      <Route
                        path="/cursos"
                        element={
                          <Suspense fallback={<div>Loading...</div>}>
                            <LandingPage />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/renovacao"
                        element={
                          <Suspense fallback={<div>Loading...</div>}>
                            <Renovacao
                              userData={userData}
                              user={userData}
                              setIdinitialization={setIdinitialization}
                              setAmountinitialization={setAmountinitialization}
                              Idinitialization={Idinitialization}
                              DateMsg={DateMsg}
                              setDateMsg={setDateMsg}
                            />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/session-status"
                        element={
                          <Suspense fallback={<div>Loading...</div>}>
                            <Return />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/payament/:id/:id2/:id3"
                        element={
                          <>
                            <HeaderCalculator
                              statusMode={statusMode}
                              setStatusMode={setStatusMode}
                              onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                              isLoggedIn={isLoggedIn}
                              showLogoutButton={showLogoutButton}
                            />

                            <PayamentMP
                              statusMode={statusMode}
                              isLoggedIn={isLoggedIn}
                              userData={userData}
                              user={userData}
                              DateMsg={DateMsg}
                              setDateMsg={setDateMsg}
                            />

                          </>
                        }
                      />
                    </Routes>
                  </>
                </>
              )}
            </>
          )}
        </div>
        :
        ""
      }
    </>
  );
}

export default App;
