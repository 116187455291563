import { listMarketsOnfilterMarket, dangersMarket, listSecondaryMarkets } from "../../variables/VariablesFilterSurebet";
import { useRef, useContext } from "react";
import { Context } from "../../../App";
import Modal from 'react-bootstrap/Modal';
import { SaveFilterArbitragem } from "../../functions/FunctionsFilter";
import IconExclamation from '../../../assets/svg/exclamation.svg';
import { Tooltip } from 'react-tooltip';
import { event } from "jquery";
import { includes } from "lodash";

export function MarketsDesktop(props) {
    const { handleClose, show, formatDataFilterToSave, statusMode } = props;
    const { marketsUnselected, setMarketsUnselected } = useContext(Context);
    const listMarketsElements = useRef([]);
    const markAllElement = useRef(null);

    let marketsTitles = Object.keys(listSecondaryMarkets);
    let valueToSpace = [];
    for(let i = 0; i < marketsTitles.length; i++){
        let titleMarket = marketsTitles[i];
        valueToSpace = valueToSpace.concat(listSecondaryMarkets[titleMarket])
    }

    const changeValueCheckbox = (event) => {
        let statusChecked = event.target.checked;

        if (statusChecked === false) {
            markAllElement.current.checked = false;
        } else {
            let listElementsApproved = listMarketsElements.current.filter(item => !item.checked);

            if (listElementsApproved.length === 0) {
                markAllElement.current.checked = true;
            }
        }
    };
    
    const checkUncheckAllSecondaryMarket = (event, nameMarket) => {
        let statusChecked = event.target.checked;

        let listMarkets = listSecondaryMarkets[nameMarket].map(item => item.toLocaleLowerCase().replace(/ /g, ''));

        if (statusChecked === false) {
            markAllElement.current.checked = false;
            for(let i = 0; i < listMarketsElements.current.length; i++){
                let element = listMarketsElements.current[i];
                let nameElement = element.name;

                if(listMarkets.includes(nameElement) === true){
                    listMarketsElements.current[i].checked = false;
                }
            }

        } else {

            for(let i = 0; i < listMarketsElements.current.length; i++){
                let element = listMarketsElements.current[i];
                let nameElement = element.name;

                if(listMarkets.includes(nameElement) === true){
                    listMarketsElements.current[i].checked = true;
                }
            }

            let listElementsApproved = listMarketsElements.current.filter(item => !item.checked);

            if (listElementsApproved.length === 0) {
                markAllElement.current.checked = true;
            }
        }
    }

    const changeValueCheckboxSecondaryMarkets = (event, nameMarket) => {
        let statusChecked = event.target.checked;

        let nameRootMarket = "";
        let listMarkets = [];
        for(let i = 0; i < marketsTitles.length; i++){
            let titleMarket = marketsTitles[i];

            if (listSecondaryMarkets[titleMarket].includes(nameMarket) === true){
                nameRootMarket = titleMarket.toLocaleLowerCase().replace(/ /g, '');
                listMarkets = listSecondaryMarkets[titleMarket].map(item => item.toLocaleLowerCase().replace(/ /g, ''));
                break;
            }
        }

        if (statusChecked === false) {

            markAllElement.current.checked = false;
            



            let marketsEnabled = listMarketsElements.current.filter((item) =>{
                if( listMarkets.includes(item.name) === true && item.checked === true){
                    return true
                }
                return false
            })
            if(marketsEnabled.length === 0){
                for(let i = 0; i < listMarketsElements.current.length; i++){
                    let element = listMarketsElements.current[i];
                    let nameElement = element.name;
    
                    if(nameElement === nameRootMarket){
                        listMarketsElements.current[i].checked = false;
                        break;
                    }
                }
            }
        } else {
            for(let i = 0; i < listMarketsElements.current.length; i++){
                let element = listMarketsElements.current[i];
                let nameElement = element.name;
                if(nameElement === nameRootMarket){
                    listMarketsElements.current[i].checked = true;
                    break;
                }
            }
            let listElementsApproved = listMarketsElements.current.filter(item => !item.checked);
            if (listElementsApproved.length === 0) {
                markAllElement.current.checked = true;
            }
        }
    };
    

    const saveAndApplyFilter = () => {

        let listElementsApproved = listMarketsElements.current.filter(item => !item.checked);
        let listNamesMarketsBlecked = listElementsApproved.map(item => item.name)

        setMarketsUnselected(listNamesMarketsBlecked);
        localStorage.setItem("marketsUnselected", JSON.stringify(listNamesMarketsBlecked));

        let dataFilterToUpdate = formatDataFilterToSave(
            { marketsUnselected: listNamesMarketsBlecked, }
        )
        SaveFilterArbitragem(dataFilterToUpdate);
    }

    const checkUncheckAllMarkets = (event) => {
        let statusChecked = event.target.checked;


        if (statusChecked === true) {
            listMarketsElements.current.forEach((ref) => {
                ref.checked = true;
            });
        } else {
            listMarketsElements.current.forEach((ref) => {
                ref.checked = false;
            });
        }
    }

    return (
        <>

            <Modal

                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="filter-modal-market" show={show} onHide={handleClose}

            >
                <Modal.Header className={`modal-header ${statusMode ? "filter-bg-dark filter-border-color filter-text-gray" : ""}`} closeButton>
                    <Modal.Title className="h4">Mercados</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${statusMode ? "filter-bg-dark" : ""}`}>

                    <div className="filter-container-check-market">
                        <Tooltip id="tooltip-filter-market" data-tooltip-place='top' style={{ maxWidth: '100%', zIndex: "9999999" }} />
                        {
                                
                            listMarketsOnfilterMarket.map((item, index) => {
                                let itemLower = item.toLocaleLowerCase().replace(/ /g, '');

                                let isSecondaryMarket = valueToSpace.includes(item) ? true : false;
                                
                                return (
                                    <div
                                        key={index}
                                        className={`form-check text-start ${statusMode ? "filter-text-gray" : ""} ${isSecondaryMarket ? "ms-4" : ""}`}
                                    >
                                        <input
                                            type="checkbox"
                                            id={`market-${itemLower}`}
                                            className="form-check-input"
                                            name={itemLower}
                                            onChange={ (event) => {
                                                    if(isSecondaryMarket === true){
                                                        changeValueCheckboxSecondaryMarkets(event, item);
                                                    }
                                                    else if( marketsTitles.includes(item) === true){
                                                        checkUncheckAllSecondaryMarket(event, item)
                                                    }

                                                    else{
                                                        changeValueCheckbox(event)
                                                    }
                                                }
                                            }
                                            defaultChecked={marketsUnselected.includes(itemLower) === false}

                                            ref={(el) => {
                                                if (el) {
                                                    listMarketsElements.current[index] = el
                                                };
                                            }}
                                        />

                                        <div className="d-flex">
                                            <label

                                                htmlFor={`market-${itemLower}`}
                                                className="form-check-label ps-0 filter-label-modal"

                                            >

                                                {item}

                                            </label>


                                            {

                                                dangersMarket.includes(item) ?
                                                    <img
                                                        data-tooltip-content="Mercado não recomendado"
                                                        data-tooltip-id="tooltip-filter-market"
                                                        class="style-icon-alert-filter ms-1 "
                                                        src={IconExclamation}
                                                    />
                                                    :
                                                    ""
                                            }

                                        </div>

                                    </div>
                                )

                            })
                        }
                    </div>



                </Modal.Body>
                <div
                    className={`form-check border-top ps-0 py-2 m-0 ${statusMode ? "filter-bg-dark filter-border-color filter-text-gray" : ""}`}
                >

                    <div>
                        <input
                            type="checkbox"
                            id="show-all-markets"
                            className="form-check-input ms-3"
                            defaultChecked={marketsUnselected.length === 0 ? true : false}
                            onChange={checkUncheckAllMarkets}
                            ref={(el) => {
                                if (el) {
                                    markAllElement.current = el;
                                }
                            }
                            }
                        />
                    </div>

                    <label
                        title=""
                        htmlFor="show-all-markets"
                        className="form-check-label ms-4"
                    >
                        Mostrar Tudo
                    </label>
                </div>
                <Modal.Footer className={`${statusMode ? "filter-bg-dark filter-border-color filter-text-gray" : ""}`}>

                    <button className='btn btn-primary' onClick={() => { saveAndApplyFilter(); handleClose(); }}>
                        Aplicar e filtrar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}