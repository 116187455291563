import Main from './componentFilterMobile/Main';
import BetHome from './componentFilterMobile/BetHome';
import Sports from './componentFilterMobile/Sports';
import  React, { useState, useContext } from 'react';
import { SaveFilterArbitragem } from './functions/FunctionsFilter';
import MarketsMobile from './sub-componentes/filter/MarketsMobile';
import { Context } from "../App";
import { EventMobile } from './sub-componentes/filter/EventMobile';

const FilterMobile = (props) => {

    const {                
        statusMode,        
        controllerLocalStorage,
        valueMin,
        setValueMin,
        valueMax,
        setValueMax,
        betHome,
        setBetHome,
        quantityBetHomeEnabled,
        setQuantityBetHomeEnabled,
        sports,
        setSports,
        quantitySportsEnabled,
        setQuantitySportsEnabled,
        timeEvent,
        setTimeEvent,
        setlistBehomeEnableds,
        setentradasLigth,
        entradasLigth,
        setentradasPro,
        entradasPro,
        AparecerProf,
    AparecerVivo,
    AparecerLight,
    AparecerPro,
    filterOrder,
    setFilterOrder,
    requiredBetHome,
    setRequiredBetHome,
    } = props;

    const [filterSession, setFilterSession] = useState("index");
    const { marketsUnselected, blacklistWords } = useContext(Context);
    let actualMarketsUnselected = marketsUnselected;
    
    function saveFilterChanged(
        {
        actualBethomeEnable=betHome, 
        actualSportsEnabled=sports,
        actuaTimeEvent=timeEvent,
        actualOrderBet=filterOrder,
        actualMinProfit=valueMin,
        actualMaxProfit=valueMax,
        marketsUnselected=actualMarketsUnselected,
        blacklistWordsSurebet=blacklistWords,
      }
      )
      {
        
    
        let dataFilterToSave = {
          'betHome': actualBethomeEnable,
          'sports': actualSportsEnabled,
          'timeEvent': actuaTimeEvent,
          'filterOrder': actualOrderBet,
          'minProfit': Number(actualMinProfit),
          'maxProfit': Number(actualMaxProfit),
          'marketsUnselected': marketsUnselected,
          "blacklistWordsSurebet": blacklistWordsSurebet
        };
        
        SaveFilterArbitragem(dataFilterToSave);
    }

    const openFilterBethome = () => {
        setFilterSession("bet_home");
    }

    const openFilterSports = () => {
        setFilterSession("sport");
    }

    const openPageIndexFilter = () => {
        setFilterSession("index");
    }

    const openFilterMarkets = () => {
        setFilterSession("market");
    }

    const openFilterEvent = () => {
        setFilterSession("event");
    }

    function saveFilterSports(dataSports){
        controllerLocalStorage['updateLocalStorage']('listSportsEnabled', dataSports);                
        setQuantitySportsEnabled( JSON.parse( localStorage['listSportsEnabled'] ).length )
        setSports(JSON.parse( localStorage['listSportsEnabled'] ));
    }

    function saveFilterBetHome(dataBethome){
        controllerLocalStorage['updateLocalStorage']('listBehomeEnabled', dataBethome);                
        setQuantityBetHomeEnabled( JSON.parse( localStorage['listBehomeEnabled'] ).length )
        setBetHome(JSON.parse( localStorage['listBehomeEnabled'] ));
        setlistBehomeEnableds(dataBethome);
    }
   

    return(
        <>
            <button type="button" className="btn btn-primary buttonFilterMobile font-size-filter-out-desktop-mode" data-bs-toggle="modal" data-bs-target="#filterMobile" onClick={openPageIndexFilter}>
            Filtros
            </button>
            <div className="modal fade" id="filterMobile" tabIndex="-1" aria-labelledby="filterMobileLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content" style={{backgroundColor: "transparent"}}>            
                    <div className="modal-body p-0 d-flex justify-content-center">
                        <div id="filterMobile"
                            className={`containerFilter border border-2 rounded fs-5 ${statusMode ? "borderColor": "bg-light"}`} 
                            
                            style={{height: '550px', backgroundColor: statusMode ? "#0d0d0d" : "", width: "300px"}}>
                            
                            { filterSession === "index" &&
                                <Main
                                openFilterBethome = {openFilterBethome}    
                                openFilterSports = {openFilterSports}     
                                statusMode = {statusMode}      
                                controllerLocalStorage = {controllerLocalStorage}
                                valueMin = {valueMin}
                                setValueMin = {setValueMin}
                                valueMax = {valueMax}
                                setValueMax = {setValueMax}                                
                                quantityBetHomeEnabled = {quantityBetHomeEnabled}                                                                                                
                                quantitySportsEnabled = {quantitySportsEnabled}                                
                                timeEvent = {timeEvent}
                                setTimeEvent = {setTimeEvent}        
                                setSports = {setSports}                                
                                setBetHome = {setBetHome}   
                                setQuantitySportsEnabled = {setQuantitySportsEnabled}                             
                                setQuantityBetHomeEnabled = {setQuantityBetHomeEnabled}
                                saveFilterSports = {saveFilterSports}
                                saveFilterBetHome = {saveFilterBetHome}
                                setlistBehomeEnableds={setlistBehomeEnableds}
                                setentradasLigth={setentradasLigth}
                                entradasLigth={entradasLigth}
                                setentradasPro={setentradasPro}
                                entradasPro={entradasPro}
                                AparecerProf={AparecerProf}
                                AparecerVivo={AparecerVivo}
                                AparecerLight={AparecerLight}
                                AparecerPro={AparecerPro}
                                filterOrder={filterOrder}
                                setFilterOrder={setFilterOrder}
                                saveFilterChanged={saveFilterChanged}
                                requiredBetHome={requiredBetHome}
                                setRequiredBetHome={setRequiredBetHome}
                                openFilterMarkets={openFilterMarkets}
                                openFilterEvent={openFilterEvent}
                                />
                            }

                            {filterSession === "bet_home" && 
                                <BetHome
                                openPageIndexFilter = {openPageIndexFilter}                
                                statusMode = {statusMode}
                                betHome = {betHome}                                
                                setBetHome = {setBetHome}                                
                                saveFilterBetHome = {saveFilterBetHome}
                                setlistBehomeEnableds={setlistBehomeEnableds}
                                saveFilterChanged={saveFilterChanged}
                                requiredBetHome={requiredBetHome}
                                setRequiredBetHome={setRequiredBetHome}
                                />
                            }

                            {filterSession === "sport" && 
                                <Sports 
                                openPageIndexFilter = {openPageIndexFilter}                
                                statusMode = {statusMode}
                                sports = {sports}
                                setSports = {setSports}
                                saveFilterSports = {saveFilterSports}
                                saveFilterChanged={saveFilterChanged}
                                />
                            }

                            {filterSession === "market" &&
                                <MarketsMobile
                                    openPageIndexFilter={openPageIndexFilter}
                                    statusMode={statusMode}
                                    saveFilterChanged={saveFilterChanged}
                                />
                            }

                            {
                                filterSession === "event" &&
                                <EventMobile
                                openPageIndexFilter={openPageIndexFilter}
                                saveFilterChanged={saveFilterChanged}
                                />
                            }

                                    
                            </div>
                        </div>            
                    </div>
                </div>
            </div>

            
        </>
    )
}

export default FilterMobile;
