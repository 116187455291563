import axios from "axios";
import { orderLoop } from "./GlobalVariables";
import { listCloneBetHomeModeLive } from "./GlobalVariables";


const normalizeText = (text) => text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

function replaceLatinLetters(text) {
  const textModified = normalizeText(text);
  return textModified;
}

function urlPathAlongWithParam(url) {
    const urlObject = new URL(url);
    const pathWithParams = urlObject.pathname + urlObject.search;
    return pathWithParams;
}


function urlPathAlongWithParam2(url) {  
   // eslint-disable-next-line 
  const path = url.replace(/^https?:\/\/[^\/]+/, "");  
  return path;
}

function decodePathNameUrl(url){
  const parsedUrl = new URL(url);
  const path = parsedUrl.pathname;
  return path;
}

function decodeIdBolsaDeApostas(url){
  const parsedUrl = new URL(url);
  const path = parsedUrl.pathname;
  return path
}

function clonerBetnacional(baseUrl, url) {
    const urlPathParam = urlPathAlongWithParam(url);
    const newURLBetHome = `${baseUrl}${urlPathParam}`;
    return newURLBetHome;
}


function clonerPixBet(baseUrl, url) {
  const indexUrl = url.indexOf("bt-path");
  const restUrl = (url.slice(indexUrl)).replaceAll("/", "%2F");
  const newURLBetHome = `${baseUrl}${restUrl}`;
  return newURLBetHome;
}

function clonerVaiDeBet(baseUrl, url) {
  const urlPathParam = urlPathAlongWithParam(url);
  const newURLBetHome = `${baseUrl}${urlPathParam}`;
  return newURLBetHome;
}

function clonerSportingBet(baseUrl, url) {
    const urlPathParam = urlPathAlongWithParam(url);
    const newURLBetHome = `${baseUrl}${urlPathParam}`;
    return newURLBetHome;
}

function clonerBolsaDeAposta(baseUrl, url){
  const urlPathParam = decodeIdBolsaDeApostas(url);
  const newURLBetHome = `${baseUrl}${urlPathParam}`;
  return newURLBetHome;
}


function clonerPinnacles(baseUrl, url) {
  const urlPathParam = urlPathAlongWithParam(url);
  

  const newURLBetHome = `${baseUrl}${urlPathParam}`.replace('pt/', 'pt/standard/').replace('en/', 'pt/standard/');
  return newURLBetHome;
}

function decodeUrlVbet_2(baseURLClone, url, nameCloneBetHome){    
  const indexVbetPrematch = url.indexOf("pre-match");

  if (indexVbetPrematch === -1){
    baseURLClone = listCloneBetHomeModeLive['vbet (lat)'][nameCloneBetHome]['baseURL'];

    const partUrlVbet = ( (decodePathNameUrl(url)).replace("/pb/sports/live/event-view/", "") )
    
    let partUrlVbetFragmented  = (partUrlVbet.split("/")).filter(item => isNaN(parseFloat(item)) === false)
    const newURLBetHomeLive = `${baseURLClone}/${partUrlVbet}`;
    if(partUrlVbetFragmented.length > 1){
    
    let leagueId = newURLBetHomeLive.indexOf(partUrlVbetFragmented[0]);
    let linkVbetClone = `${newURLBetHomeLive.slice(0, leagueId)}${partUrlVbetFragmented[0]}/${partUrlVbetFragmented[1]}`;    
    return linkVbetClone;
    }
    return newURLBetHomeLive;
  }

  const indexPartUrl = indexVbetPrematch !== -1 ? indexVbetPrematch : url.indexOf("/live/");
  
  const restUrl = (url.slice(indexPartUrl)).replace("/liga", "").replace("pre-match", "").replace("/live/", "/").replace("event-view/", "");
  
  const restUrlFragmented = restUrl.split("/")

  const qtFragmentUrl = (restUrlFragmented.length) - 1;
  const restUrlFragmented_2 = restUrlFragmented.slice(0, qtFragmentUrl);

  const qtFragmentUrl_2 = restUrlFragmented_2.length - 2;
  const restUrlFragmented_3 = restUrlFragmented_2.filter((item, index) => index !== qtFragmentUrl_2)

  const restUrlrestUrl_2 = restUrlFragmented_3.join("/");
  
  
  const newURLBetHome_2 = `${baseURLClone}${restUrlrestUrl_2}`;
  return newURLBetHome_2;
}

function cloneVbet(baseURL, url, nameCloneBetHome){
  const listExceptionBetHome = [
    "segurobet",
    "7games",
    "seubet",
    "betão",
    "bravobet",
    "h2bet",
    "maximabet"
  ];

  if(listExceptionBetHome.includes(nameCloneBetHome) === true){
    const restUrlVbet = decodeUrlVbet_2(baseURL, url, nameCloneBetHome);
    
    return restUrlVbet;
  }
  

  let pathUrl = ( decodePathNameUrl(url) );

  

  const newURLBetHome = `${baseURL}${pathUrl}`;
  return newURLBetHome;
}

function decodeUrlBetFast(domain){    
  const indexPrematch = domain.indexOf("prematch?match=");
  const restUrl = (domain.slice(indexPrematch));    
  return restUrl;
}

function clonerBetFast(baseUrl, url) {
  const urlPathParam = decodeUrlBetFast(url);
  const newURLBetHome = `${baseUrl}${urlPathParam}`;
  return newURLBetHome;
}


function getEventIDLinkKTO(urlKTO){
  let url = new URL(urlKTO);
  let params = new URLSearchParams(url.search);
  let eventId = params.get('eventId');
  return eventId;
}

function clonerKTO(baseURLClone, url){
  if(url.includes('esportes-ao-vivo') === true){
    let baseUrlbetHomeClone = listCloneBetHomeModeLive['kto']['estrelabet']['baseURL'];
    let eventIdKTO = getEventIDLinkKTO(url);
    let newURLBetHome = `${baseUrlbetHomeClone}/${eventIdKTO}`;
    return newURLBetHome;
  }

  let eventIdKTO = getEventIDLinkKTO(url);
  let newURLBetHome = `${baseURLClone}/${eventIdKTO}`;
  return newURLBetHome;
}


function genericClonerBetHome(baseURLClone, url){
  const partUrl = urlPathAlongWithParam2(url);
  const urlClone = `${baseURLClone}${partUrl}`;
  return urlClone;
}


function clonerStake(baseURLClone, url){
  


  if(baseURLClone.includes("betwarrior")){
  let last_cc_fragmented = url.split("/");
  let id_surebet = last_cc_fragmented[ (last_cc_fragmented.length-1) ];

  return `${baseURLClone}/${id_surebet}`
  }

  

  let link_clone = genericClonerBetHome(baseURLClone, url);
  let link_clone_remade = replaceLatinLetters(  (link_clone.replaceAll("--", "-").replaceAll("--", "---")) );
  
  return link_clone_remade;
}

function clonerEstrelaBet(baseURLClone, url){
  
  const partUrl = urlPathAlongWithParam2(url);
  let link_clone = (`${baseURLClone}${partUrl}`).replace("/pb/esportes", "/pt/sports");
  console.log("link_clone", link_clone);
  return link_clone;
}


function clonerBlaze(baseURLClone, url){
  let urlCloneBlaze = genericClonerBetHome(baseURLClone, url)


  if( urlCloneBlaze.includes("www.fla.bet.br") === true ){
    urlCloneBlaze = urlCloneBlaze.replace("sports?bt-","sportsbook?bt-")
  }

  return urlCloneBlaze;
}


export function managerFunctionCloneBetHome(infoBetHome, baseBethomeClone, nameCloneBetHome, url) {

    const managerClonerBetHome = {
        'sportingbet': function () { return clonerSportingBet(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'betnacional': function () { return clonerBetnacional(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'estrelabet': function () { return clonerEstrelaBet(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'pixbet': function () { return clonerPixBet(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'bolsadeaposta': function () { return clonerBolsaDeAposta(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'pinnacle': function () { return clonerPinnacles(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'vaidebet': function () { return clonerVaiDeBet(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'vbet (lat)':  function () { return cloneVbet(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url, nameCloneBetHome) },
        'betfast': function () { return clonerBetFast(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'kto': function () { return clonerKTO(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'f12bet': function () { return genericClonerBetHome(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'bet7k': function () { return genericClonerBetHome(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'stake': function () { return clonerStake(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },  
        'blaze': function () { return clonerBlaze(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'luvabet': function () { return genericClonerBetHome(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
        'brbet': function () { return genericClonerBetHome(infoBetHome[baseBethomeClone][nameCloneBetHome]['baseURL'], url) },
    }

    let valueReturn = managerClonerBetHome[baseBethomeClone]();

    return valueReturn;
}

export async function statusUserCoupon(email) {

    
    
    const dataToSend = { 'e6245': email };
    const response = await fetch(`https://rd.vps.webdock.cloud:9456/can_use_coupon`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify(dataToSend)
    });

    if (response.ok) {
        const data = await response.json();
        return data;
    } else {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

}


//---FILTER
export function updateClassifiactionSurebet(nameClassification, setStateClassification, nameLocalStorage){
    const nameClassificationLower = nameClassification.toLowerCase()
    setStateClassification( nameClassificationLower );
    localStorage.setItem(nameLocalStorage, JSON.stringify(nameClassificationLower));
}


//---LocalStorage

export function localStorageManagerReturn(nameLocalStorage, defaultValue){
    let itemLocalStorage = localStorage.getItem(nameLocalStorage);


    try {
        return  JSON.parse(itemLocalStorage);
    } catch {
        return defaultValue;
    }
}


export function organizatorSurebetByClassification(statusFilter, dataSurebet) {
    if (statusFilter === 'início') {
  
      let newDataSurebet = {};
      let keysObject = Object.keys(dataSurebet);
      let gcc = {};
      for (let i = 0; i < keysObject.length; i++) {
  
        let keyObject = keysObject[i];
        let valueObject = dataSurebet[keyObject]['main'];
        let listLargeTime = [];
  
        for (let i_orderLoop = 0; i_orderLoop < orderLoop.length; i_orderLoop++) {
          let order = orderLoop[i_orderLoop];
  
          if (order in valueObject) {
  
  
            listLargeTime.push(valueObject[order]['time_milliseconds']);
  
          }
        }
  
        let listLargeTimeOrdenated = listLargeTime.sort();
        listLargeTimeOrdenated.reverse()
  
        if (gcc[listLargeTimeOrdenated[0]] === undefined) {
          gcc[listLargeTimeOrdenated[0]] = [keyObject];
        } else {
          gcc[listLargeTimeOrdenated[0]].push(keyObject);
        }
      }
  
  
      let keysGcc = Object.keys(gcc);
      let keysGccOrdenated = keysGcc.sort();
  
      for (let i = 0; i < keysGccOrdenated.length; i++) {
        let keyGccOrdenated = keysGccOrdenated[i];
  
        for (let i_gccItem = 0; i_gccItem < gcc[keyGccOrdenated].length; i_gccItem++) {
          let item = gcc[keyGccOrdenated][i_gccItem];
          newDataSurebet[item] = dataSurebet[item];
        }
      }
      
      return (newDataSurebet);
    }
    else if (statusFilter === 'recentes') {
  
      let newDataSurebet = {};
      let keysObject = Object.keys(dataSurebet);
      let gcc = {};
      let keysNumber = [];
      for (let i = 0; i < keysObject.length; i++) {
  
        let keyObject = keysObject[i];
        let valueObject = dataSurebet[keyObject]['main'];
        
  
  
  
  
  
        let timeLower = valueObject['time'].toLowerCase();
  
        let g = timeLower.indexOf('h') !== -1
          ? (parseFloat(timeLower.replace('h', '')) * 60)
          : timeLower.indexOf('dia') !== -1 ?
            (parseFloat(timeLower.replace('dias', '').replace('dia', '')) * 1440)
  
            : parseFloat(timeLower.replace('min', ''));
        
  
        if (gcc[g] === undefined) {
          gcc[g] = [keyObject];
          keysNumber.push(g);
        } else {
          gcc[g].push(keyObject);
        }
      }
  
      let keysGccOrdenated = keysNumber.sort((a, b) => a - b);
      
      
      for (let i = 0; i < keysGccOrdenated.length; i++) {
        let keyGccOrdenated = keysGccOrdenated[i].toString();
  
        for (let i_gccItem = 0; i_gccItem < gcc[keyGccOrdenated].length; i_gccItem++) {
          let item = gcc[keyGccOrdenated][i_gccItem];
          newDataSurebet[item] = dataSurebet[item];
        }
      }
  
      return (newDataSurebet);
    }
    else {
      return (dataSurebet);
    }
  }


export function decodeChance(chance_surebet) {
  let valueToReturn = {
    'chance': 0,
    'percent': 0,
  }

  let hasStart = chance_surebet.indexOf('(')

  if (hasStart !== -1 && hasStart !== -1) {
    let chanceFragmented = chance_surebet.split('(');
    let realChance = Number(chanceFragmented[0])
    let percentChance = Number(chanceFragmented[1].replaceAll('(', '').replaceAll(')', '').replaceAll(',', '.').replaceAll('%', ''));

    valueToReturn['chance'] = realChance;
    valueToReturn['percent'] = percentChance;

    return valueToReturn;
  }
  valueToReturn['chance'] = Number(chance_surebet)
  return valueToReturn
}

export async function checkSessiondID(sessionIDs) {
  if (sessionIDs) {
    const concurrentLoginResponse = await axios.post(
      "https://rd.vps.webdock.cloud:9512/api/check-concurrent-session",
      {
        userID: sessionIDs,
      }
    );
    const checkdata = await concurrentLoginResponse.data.concurrentLogin;
    if (!checkdata) {
      
      return false;
    }else{
      return true;
    }
  } else {
    return true;
  }
}

export function createListBetHomeDemarkOnFilter(clonerBetHomeJSON){
  const clonerBetHome = JSON.parse(clonerBetHomeJSON);
  const newListBetHomeDemarkOnFilter = (clonerBetHome['allBetHomeAbleToClone'].map(item => {
    let listBetHome = clonerBetHome[item]['allBetHomeClone'];
    listBetHome.push(item);
    return listBetHome;
  }).concat(["sportingbet", "betboo", "bwin"]));

  return newListBetHomeDemarkOnFilter;
}