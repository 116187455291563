import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import "../assets/css/AuthenticationForm.css";
import axios from "axios";
import { AlertSuccess, AlertDanger } from "./styles/Login";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import validator from "validator";
import { loginUserAfterRegistration } from "./functions/FunctionRegisterLoginForm";

const backgroundlogin = `${process.env.PUBLIC_URL}/images/bg-01.jpg`;
const iconbranca = `${process.env.PUBLIC_URL}/logo192.png`;

function AuthenticationForm(props) {
  const {
    statusMode,
    onLoginSuccess,
    showHeader,
    setShowHeader,
    handleLogout,
    setUserData,
    skipAutoLogin
  } = props;
  const [isLogin, setIsLogin] = useState(true);
  const [isForgtPassWord, setIsForgtPassWord] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  const [logins, setLogins] = useState({
    email: "",
    senha: "",
  });
  const [cadastros, setCadastro] = useState({
    nome: "",
    email: "",
    senha: "",
    csenha: "",
    plano: "Gratuito",
    tipo_de_plano: "inativo",
    status_da_conta: "Gratuito",
    phone: "",
  });

  const valorInput = (e) =>
    setCadastro({ ...cadastros, [e.target.name]: e.target.value });

  const valorInputs = (e) =>
    setLogins({ ...logins, [e.target.name]: e.target.value });

  const handleToggleForm = () => {
    setIsForgtPassWord(false);
    //setIsForgtPassWord(!isForgtPassWord);
    setStatus({ type: "", mensagem: "" });
    setIsLogin(!isLogin);
  };
  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => { };

  const navigate = useNavigate();

  const handleVoltarClick = () => {
    if (window.location.hash) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    navigate("/");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {

      const idResponse = await axios.post(
        "https://rd.vps.webdock.cloud:9512/idlogin",
        {
          email: logins.email, // Substitua pelo email do usuário
        }
      );

      if (!idResponse.data.id) {
        setStatus({
          type: "erro",
          mensagem: "ID do usuário não encontrado",
        });
        return;
      }

      const concurrentLoginResponse = await axios.post(
        "https://rd.vps.webdock.cloud:9512/api/check-concurrent-login",
        {
          userID: idResponse.data.id, // Use o ID retornado com sucesso
        }
      );


      if (concurrentLoginResponse.data.concurrentLogin) {

        setStatus({
          type: "erro",
          mensagem:
            "Usuário já está logado em outro lugar, efetuando o logout!",
        });
        sessionLogout(idResponse.data.id);
        handleLogin(e);
        return;
      } else {



        
        const response = await axios.post(
          "https://rd.vps.webdock.cloud:9512/authenticator",
          {
            ...logins,
          }
        );

        if (response.data.erro) {
          setStatus({
            type: "erro",
            mensagem: response.data.message,
          });
        } else {
          setStatus({
            type: "success",
            mensagem: "Login efetuado! Siga em Frente!",
          });

          setUserData(response.data);
          skipAutoLogin.current = true
          
          onLoginSuccess(true, response.data);
        

          if (
            response.data.plano == "free" ||
            response.data.plano == "Gratuito" ||
            response.data.plano == "" ||
            response.data.plano == null
          ) {
            navigate("/plano");
          } else {
            navigate("/");
          }
        }
      }
    } catch (error) {
      setStatus({
        type: "erro",
        mensagem:
          "Login não encontrado, verifique suas credenciais ou efetue seu cadastro!",
      });
    }
  };
  const sessionLogout = async (idUser) => {
    try {
      // Envia uma solicitação POST para a rota de logout no servidor
      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9512/api/logout",
        {
          // Coloque o ID do usuário que deseja fazer logout
          // Aqui, supomos que você tenha a variável userData que contém o ID do usuário
          userID: idUser,
        }
      );

      // Trate a resposta do servidor, se necessário
      // Por exemplo, você pode verificar a resposta para saber se o logout foi bem-sucedido
      if (response.status === 200) {

        handleLogout();
      } else {
        console.log("");
      }
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
    }
  };

  const handleCadastro = async (e) => {
    e.preventDefault();    
    if (cadastros.senha !== cadastros.csenha) {
      setStatus({
        type: "erro",
        mensagem: "As senhas não coincidem!",
      });
      return;
    }
    var email = cadastros.email;

    if (validator.isEmail(email)) {
      setEmailError("Valid Email :)");
    } else {
      setEmailError("E-mail Invalid!!");
      return;
    }

    try {
      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9512/register",
        {
          ...cadastros,
        }
      );

      if (response.data.erro) {
        setStatus({
          type: "erro",
          mensagem: response.data.message,
        });
      } else {
        saveUserPreferencesLogin(response.data.dados);
        setStatus({
          type: "success",
          mensagem: response.data.message,
        });
        let newLoginCredentials = {
          email: cadastros["email"],
          senha: cadastros["csenha"],
        };
        
        loginUserAfterRegistration(newLoginCredentials,  setUserData, onLoginSuccess, navigate);
      
      }
    } catch (error) {
      
      if( error.response !== undefined &&  error.response.data !== undefined && error.response.data.message !== undefined){
        setStatus({
          type: "erro",
          mensagem: error.response.data.message,
        });
      }else{
      setStatus({
        type: "erro",
        mensagem: "Cadastro não realizado, tente mais tarde!",
      });}
    }
  };
  const saveUserPreferencesLogin = async (dados) => {

    const listPatternValues = {
      login: false,
      profitMin: -999,
      profitMax: 999,
      time: "Qualquer horario",
      listBehomeEnabled: [
        "amuletobet",
        "bet365 (full)",
        "betano (br)",
        "betcris",
        "betfair sb",
        "betsson",
        "betwarrior",
        "betway",
        "bodog (en)",
        "betboo",
        "bwin",
        "sportingbet",
        "kto",
        "pinnacle",
        "pixbet",
        "playpix",
        "vbet (lat)",
      ],
      listSportsEnabled: [
        "artes maciais",
        "badminton",
        "bandy",
        "basquete",
        "basquete 3x3",
        "basquete 4x4",
        "beisebal",
        "boliche",
        "dardos",
        "floorball",
        "futebol",
        "futebol 3x3",
        "futebol 4x4",
        "futebol 5x5",
        "futebol gaélico",
        "futebol americano",
        "futebol australiano",
        "futebol de praia",
        "futebol de salão",
        "golfe",
        "handebol",
        "handebol de praia",
        "hurling",
        "hóquei",
        "hóquei de ar",
        "hóquei de ar 2x2",
        "hóquei em campo",
        "lacrosse",
        "netbol",
        "o que onde quando",
        "pólo aquático",
        "rugby",
        "sinuca",
        "tênis",
        "tênis de mesa",
        "voleibol",
        "voleibol de praia",
        "xadrez",
        "arena of valor",
        "call of duty",
        "counter-strike",
        "dota",
        "king of glory",
        "league of legends",
        "nba2k",
        "overwatch",
        "rainbow",
        "rocket league",
        "starcraft",
        "valorant",
        "warcraft",
      ],
      trash: [],
      qauntityMoney: 1000,
      roundedValue: 1,
      roundedIsActive: false,
      statusMode: false,
    };

    try {
      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9512/saveuserpreference",
        {
          // Coloque seus dados aqui, por exemplo:
          id: dados.insertId,
          userPreferences: {
            modo: false,
            ValueMin: listPatternValues.profitMin,
            ValueMax: listPatternValues.profitMax,
            BetHomes: listPatternValues.listBehomeEnabled, // Remova o JSON.parse
            QtdEnableBet: listPatternValues.listBehomeEnabled.length,
            sports: listPatternValues.listSportsEnabled, // Remova o JSON.parse
            QtdEnableSport: listPatternValues.listSportsEnabled.length,
            setTtempo: listPatternValues.time,
            volume: 0.02,
            listBehomeEnabled: listPatternValues.listBehomeEnabled,
            listSportsEnabled: listPatternValues.listSportsEnabled,
            login: listPatternValues.login,
          },
        }
      );
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
    }
  };
  const handleForgotPassword = () => {

    if (isForgtPassWord) {
      setIsForgtPassWord(false);
    } else {
      setIsForgtPassWord(true);
    }
    //setIsForgtPassWord(!isForgtPassWord);
    //setIsForgtPassWord(true);
    // Handle forgot password logic here
  };
  const handleForgotPasswords = async (e) => {
    e.preventDefault();

    try {
      // Solicite o ID do usuário com base no email

      const response = await axios.post(
        "https://rd.vps.webdock.cloud:9512/resetpassword",
        {
          ...logins,
        }
      );

      if (response.data.erro) {
        setStatus({
          type: "erro",
          mensagem: response.data.message,
        });
      } else {
        setIsForgtPassWord(false);
        setStatus({
          type: "success",
          mensagem: "Recuperação Enviada para email cadastrado!",
        });
        // Define os dados do usuário no estado
      }
    } catch (error) {
      setStatus({
        type: "erro",
        mensagem:
          "Login não encontrado, verifique suas credenciais ou efetue seu cadastro!",
      });
    }
    // Handle forgot password logic here
  };

  useEffect(() => {
    localStorage.removeItem('t4630564433');
  }, [])
  return (
    <div className="loginapp">
      <div className="limiter">
        <div
          className="container-login100"
          style={{ backgroundImage: `url(${backgroundlogin})` }}
        >
          <div className={`${isLogin ? "wrap-login100" : "wrap-login100-ctd"}`}>
            <span className="login100-form-logo" href="/">
              <img
                src={iconbranca}
                alt="Ícone de parceiros"
                className="iconlogin"
              />
            </span>
            <br></br>
            <div>
              {status.type === "erro" ? (
                <AlertDanger className="alerta-erro-login">
                  {status.mensagem}
                </AlertDanger>
              ) : (
                ""
              )}
              {status.type === "success" ? (
                <AlertSuccess className="alerta-sucess-login">
                  {status.mensagem}
                </AlertSuccess>
              ) : (
                ""
              )}

              <span
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                {emailError}
              </span>
            </div>
            <br></br>
            {errorMessage && (
              <div className="error-message txt1">{errorMessage}</div>
            )}
            <span className="login100-form-title p-b-34 p-t-27">
              {isLogin ? "Acesse sua conta" : "Cadastrar"}
            </span>

            {!isLogin && !isForgtPassWord && (
              <div>
                <form
                  className="login100-form validate-form"
                  onSubmit={handleCadastro}
                >
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter name"
                  >
                    <input
                      className={`${isLogin ? "input100" : "input100-cdt "}`}
                      type="text"
                      name="nome"
                      placeholder="Nome"
                      onChange={valorInput}
                      required
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="&#128100;"
                    ></span>
                  </div>
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter email"
                  >
                    <input
                      className={`${isLogin ? "input100" : "input100-cdt "}`}
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      onChange={valorInput}
                      required
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="&#128231;"
                    ></span>
                  </div>
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter Phone"
                  >
                    <InputMask
                      className={`${isLogin ? "input100" : "input100-cdt "}`}
                      mask="(99) 9 9999-9999"
                      type="tel"
                      name="phone"
                      placeholder="Telefone"
                      onChange={valorInput}
                      required
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="&#128241;"
                    ></span>
                  </div>

                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter password"
                  >
                    <input
                      className={`${isLogin ? "input100" : "input100-cdt "}`}
                      type="password"
                      name="senha"
                      placeholder="Senha"
                      onChange={valorInput}
                      required
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="&#128272;"
                    ></span>
                  </div>
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter password"
                  >
                    <input
                      className={`${isLogin ? "input100" : "input100-cdt "}`}
                      type="password"
                      name="csenha"
                      placeholder="Confirmar Senha"
                      onChange={valorInput}
                      required
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="&#128273;"
                    ></span>
                  </div>
                  <div className="footer_card_termoscondicoes">
                    <p>
                      <input type="checkbox" id="termos" required />
                      <a href="/termoscondicoes" className="link_termos">
                        Termos e Condições
                      </a>
                    </p>
                  </div>
                  <div className="container-login100-form-btn">
                    <button className="login100-form-btn" type="submit">
                      Cadastrar
                    </button>
                  </div>
                </form>
                <hr />
                <div className="container-login100-form-btn-footer-cadastrar">
                  <Row>
                    <Col>
                      <a className="txt1" onClick={handleToggleForm}>
                        {isLogin
                          ? "Cadastrar"
                          : "Já possui cadastro? Entre agora."}
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            )}

            {isLogin && !isForgtPassWord && (
              <div>
                <form
                  className="login100-form validate-form"
                  onSubmit={handleLogin}
                >
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter email"
                  >
                    <input
                      className={`${isLogin ? "input100" : "input100-cdt "}`}
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      onChange={valorInputs}
                      required
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="&#128231;"
                    ></span>
                  </div>

                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter password"
                  >
                    <input
                      className={`${isLogin ? "input100" : "input100-cdt "}`}
                      type="password"
                      name="senha"
                      placeholder="Senha"
                      onChange={valorInputs}
                      required
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="&#128272;"
                    ></span>
                  </div>
                  <div className="contact100-form-checkbox">
                    <input
                      className="input-checkbox100"
                      id="ckb1"
                      type="checkbox"
                      name="remember-me"
                    />
                  </div>
                  <div className="container-login100-form-btn">
                    <button className="login100-form-btn" type="submit">
                      Entrar
                    </button>
                  </div>
                  <hr />
                </form>
                <div className="container-login100-form-btn-footer">
                  <Row className={`${isLogin ? "rowlogin" : "rowlogin"}`}>
                    <Col>
                      <a className="txt1" onClick={handleToggleForm}>
                        <p>
                          {isLogin
                            ? "Cadastrar"
                            : "Já possui cadastro? Entre agora."}
                        </p>
                      </a>
                    </Col>
                    <Col>
                      <a className="txt1" onClick={handleForgotPassword}>
                        {isLogin ? "Esqueceu sua senha?" : ""}
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
            {isForgtPassWord && (
              <div>
                <form
                  className="login100-form validate-form"
                  onSubmit={handleForgotPasswords}
                >
                  {/* Code for forgot password section */}
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter email"
                  >
                    <input
                      className={`${isLogin ? "input100" : "input100-cdt "}`}
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      onChange={valorInputs}
                      required
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="&#128231;"
                    ></span>
                  </div>
                  <div className="container-login100-form-btn">
                    <button className="login100-form-btn" type="submit">
                      Recuperar
                    </button>
                  </div>
                  <hr />
                </form>
                <div className="container-login100-form-btn-footer">
                  <Row className={`${isLogin ? "rowlogin" : "rowlogin"}`}>
                    <Col>
                      <a className="txt1" onClick={handleToggleForm}>
                        <p>
                          {isLogin
                            ? "Cadastrar"
                            : "Já possui cadastro? Entre agora."}
                        </p>
                      </a>
                    </Col>
                    <Col>
                      <a className="txt1" onClick={handleForgotPassword}>
                        {isLogin ? "Logar" : ""}
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
          <div className="container-login100-form-vlt">
            <button className="login100-form-btn" onClick={handleVoltarClick}>
              VOLTAR
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthenticationForm;
